import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useEffect } from "react";
import Select from "react-select";
const CustomSelect = ({ id, component, loadOptions, name, menuIsOpen, placeholder, touchedObj, label, options, errors, setTouched, touched, setFieldValue, values }) => {

  
    return (
        <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
            <label className="text-secondary s-regular-small" htmlFor={id}>{label}</label>
            <div>
                <Field
                    name={id}
                >
                    {
                        ({ field }) => (
                            <Select
                                options={options}
                                menuIsOpen={menuIsOpen}
                                noOptionsMessage={()=>null}
                                value={options.find((el) =>values?.[id] === el?.value)}
                                components={{ IndicatorSeparator: () => null, ...component }}
                                placeholder={<div className="text-secondary s-regular-normal">{placeholder}</div>}
                                styles={{
                                    control: (provided, state) => {
                                        return {
                                            ...provided, height: '50px',
                                            borderColor: state.isFocused ? "#E3E3E3" : (errors?.[name] && touched?.[name]) ? "#FDA29B" : "#E3E3E3",
                                            outline: "white",

                                            boxShadow: "none",
                                            "&:hover": {},
                                            borderRadius: '10px',
                                        }
                                    }
                                }
                                }
                                onInputChange={loadOptions}
                                onBlur={() => { setTouched({ ...touchedObj }) }}
                                onChange={(value) => {

                                    setFieldValue(id, value.value)
                                }}
                                isSearchable
                            />
                        )
                    }
                </Field>
            </div>
            <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={id} component="div" />

        </div>
    )


}


export default CustomSelect;