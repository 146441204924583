import axios from "axios";

export const login = (values) => {
    
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/login`,
            data: {
                email: values?.email,
                password: values?.password
            }
        }).then((response) => {
            resolve(response?.data);
        }).catch((error) => {
            reject(error?.response)
        })

    })
}

