import axios from "axios"


export const fetchAllEmployeeTypes=(values, page, size)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/employee-types`,
            params:{
                page:page,
                pageSize:size,
                keyword:values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const fetchSingleEmployeeType=(id)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/employee-types/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const createEmployeeType = (values) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/employee-types`,
            data: {
                employee_type: values.name 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const updateEmployeeType = (values, id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BASE_URL}/employee-types/${id}`,
            data: {
                employee_type: values.name 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const deleteEmployeeType = (id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BASE_URL}/employee-types/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}