import axios from "axios"

export const employeeEdit=(values,id)=>{
 
    return new Promise((resolve,reject)=>{
        axios({
            method:"PUT",
            url:`${process.env.REACT_APP_BASE_URL}/users/${id}`,
            data:{
                employment_information_flag : "true",
                image: values?.image,
                first_name: values?.name,
                last_name: values?.last_name,
                employee_id: values?.id,  //job id
                job_title: values?.job_title,
                department_id: values?.department,
                official_email: values?.employee_email,
                employee_type_id:values?.employee_type,
                profile_status:values?.profile_status, //active or inactive
                // date_of_join: values?.date_of_hire?.split('-')?.reverse()?.join('-'),
                date_of_join: values?.date_of_hire,
                

                //personal-info
                personel_information_flag :"true",
                phone: values?.mobile,
                personel_email: values?.email,
                emergency_contact: values?.emergency_contact,
                gender:values?.gender,
                blood_group: values?.blood_group,
                // dob:values?.date_of_birth?.split('-')?.reverse()?.join('-'),
                pan:values?.pan,
                dob:values?.date_of_birth,
                // dob:values?.date_of_birth,
                //address info
                address_flag : (values.location ? "true" : "false"),
                current_address: values?.location,
                current_country: values?.country,
                current_state: values?.city,
                current_pincode: String(values?.post_code),
                permanent_address: values?.perm_location,
                permanent_country: values?.perm_country,
                permanent_state: values?.perm_city,
                permanent_pincode: String(values?.perm_post_code),
                // employee_type_id: "",


                // profile_privacy: "",

                //bank details
                bank_details_flag:((values?.bankDetails?.length>0 && values.bankDetails[0]?.bank) ? "true":"false"),
                account_details: values?.bankDetails?.map(e => ({
                    bank_name: e?.bank,
                    account_no: e?.account,
                    ifsc: e?.ifsc,
                    branch_name: e?.branch,
                    account_holder: e?.account_holder,

                })),
                
                //salary details
                salary_details_flag:(values?.total? "true":"false"),
                salary: String(values?.total),
                skills_flag:"true",
                skills: values?.skills.map(e=>(e.id)),
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })
    }

    )

}