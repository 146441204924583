import Header from "../../containers/header";
import rightArrow from '../../assets/icons/rightArrow.svg';
import { Link } from "react-router-dom";
import search from '../../assets/icons/search.svg';
import left from '../../assets/icons/left.svg'
import add from '../../assets/icons/add.svg'
import { useCallback, useContext, useEffect, useState } from "react";
import List from "../../containers/list";
import { useNavigate } from "react-router-dom";
import { PaginationContext } from "../../context/paginationContext";
import { GlobalContext } from "../../context/globalContext";
import { getAllEmployees } from "../../utility/services/getAllEmployees";
import ClipLoader from "react-spinners/ClipLoader";
import { fetchDepartments } from "../../utility/services/fetchDepartment";
import Search from "../../components/search";
const EmployeeList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const {
        employeeStart,

        setEmployeeStart, employeeEnd, setEmployeeEnd, employeeCurrentPage, setEmployeeCurrentPage
    } = useContext(PaginationContext)

    const { openAllEdits, setOpenAllEdits } = useContext(GlobalContext)
    const [pageCount, setPageCount] = useState("");

    const [filterIndex, setFilterIndex] = useState(0);
    const [listDetails, setListDetails] = useState({
        total: '',
        permanent: '',
        interns: '',
        freshers: '',
        genderRatio: ''
    })

    
    const [employeeData, setEmployeeData] = useState([]
    )
    const [filters, setFilters] = useState([])
    const titles = ["Name", "Job title", 'Department', 'Date of hire']
    const btns = ['View', "Edit"]
    const routes = { view: '/employee', edit: '/employee/edit' }


    const fetchAllDepartments = async () => {
        setLoading(true)
        try {
            const response = await fetchDepartments();
      
            setFilters([{ _id: 1, department: "All" }, ...response]);
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const filterEmployee = async () => {
        let response = [];
        setLoading(true)
      
        try {
            if (filterIndex === 0) {
                getEmployees();
                return;
            }
            response = await getAllEmployees(employeeCurrentPage, 2, filters[filterIndex]._id)
         
             
            
            const employeeArr = response?.datas?.map(e => (
                {
                    id: e._id,
                    img: e.personel_details?.image,
                    cardTitle: e.personel_details.first_name,
                    cardId: e.official_details.employee_id,
                    data1: e.official_details.job_title,
                    data2: e.official_details.department.department,
                    data3: e.official_details.date_of_join
                }
            ))
            setLoading(false);

            setEmployeeData([...employeeArr])
            setPageCount(Math.ceil(response.totalPages / 5));
            // setPageCount(response.totalPages/2)



        } catch (error) {
            setLoading(false);
        }
    }
  

    const getEmployees = async (page) => {
        setLoading(true);
        try {
            const employeesData = await getAllEmployees(page,5);
            const employeeArr = employeesData?.datas.map((e, i) => {
              
                return (
                    {
                        id: e._id,
                        img: e.personel_details?.image,
                        cardTitle: e.personel_details.first_name,
                        cardId: e.official_details.employee_id,
                        data1: e.official_details.job_title,
                        data2: e.official_details.department.department,
                        data3: e.official_details.date_of_join
                    }
                )
            })

            setEmployeeData([...employeeArr])
            setPageCount(employeesData.totalPages)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }



    const getSearchList = async () => {
        setLoading(true);
        let response=[]
        try {
            if(searchKey){
                response = await getAllEmployees(null, null, null, searchKey);
                
                const arr=response.datas.map(e=>(
                    {
                        id: e._id,
                        img: e.personel_details?.image,
                        cardTitle: e.personel_details.first_name,
                        cardId: e.official_details.employee_id,
                        data1: e.official_details.job_title,
                        data2: e.official_details.department.department,
                        data3: e.official_details.date_of_join
                    }
                ))
                 setEmployeeData(arr);
                 setLoading(false);
            }else{
                response=await getAllEmployees(employeeCurrentPage, 5, null);
                const arr=response.datas.map(e=>(
                    {
                        id: e._id,
                        img: e.personel_details?.image,
                        cardTitle: e.personel_details.first_name,
                        cardId: e.official_details.employee_id,
                        data1: e.official_details.job_title,
                        data2: e.official_details.department.department,
                        data3: e.official_details.date_of_join
                    }
                ))
                 setEmployeeData(arr);
                 setLoading(false);
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }


    const getEmployeeList= async (page,size,department_id,key)=>{
        setLoading(true);
        try {
            const response = await getAllEmployees(page,size,department_id,key);
            const empList=response?.datas && response?.datas?.map((e,i)=>{
                return ({
                    id: e?._id,
                    img: e?.personel_details?.image,
                    cardTitle: e?.personel_details?.first_name+" "+e?.personel_details?.last_name,
                    cardId: e?.official_details?.employee_id,
                    data1: e?.official_details?.job_title,
                    data2: e?.official_details?.department?.department,
                    data3: e?.official_details?.date_of_join
                })
            })
            setEmployeeData([...empList])
            setPageCount(response.totalPages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(!searchKey){
            getEmployeeList(employeeCurrentPage,5,filters[filterIndex],null)
            return;
        }
        getEmployeeList(employeeCurrentPage,5,filters[filterIndex],searchKey);
    }, [searchKey])

    useEffect(() => { 
        getEmployeeList(employeeCurrentPage,5,filters[filterIndex],null);
      }, [employeeCurrentPage])
      
    

    useEffect(() => {
        setEmployeeCurrentPage(1)
        getEmployeeList(employeeCurrentPage,5,filters[filterIndex],null);
    }, [filterIndex])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        fetchAllDepartments();
        getEmployeeList(1,5,{_id:1,department:null},null);

    }, [])


    return (
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-10">
                    {/* <Link to='Home' className="s-regular decoration-none text-primary cursor-pointer">Home</Link> */}
                    {/* <img src={rightArrow} alt="" /> */}
                    <span className="s-regular cursor-pointer text-primary">Employee</span>
                </div>
                {/* <div className="d-flex rounded bg-white">
                    <input placeholder="Search"
                        onChange={(e) => {
                            setSearchKey(e.target.value)
                        }}
                        className="p-10-16 rounded bg-pirmary outline-0 border-0" type="text" />
                    <img className="bg-white rounded p-10-16" src={search} alt="search" />
                </div> */}
                <Search setData={setSearchKey}/>
            </div>
            <div className="d-flex col-10 justify-content-between align-items-center mb-24 mt-40">

                <div className="d-flex col-12  col-lg-6 gap-24 align-items-center">
                    {/* <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" /> */}
                    <span className="extra-bold">Employee list</span>
                    <button
                        onClick={() => {
                            navigate('/employee/new')
                        }}
                        type="submit" className="btn-primary h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                        <img src={add} alt="" />
                        <span className="d-none d-sm-block">New employee</span>
                    </button>
                </div>


                <div className="d-flex col-6 d-none d-lg-flex justify-content-between">
                    {/* <div>
                        <span className="s-regular-small text-primary ">Total</span>
                        <span className="s-regular text-primary">{listDetails.total}</span>
                    </div>
                    <div>
                        <span className="s-regular-small text-primary">Permanent</span>
                        <span className="s-regular text-primary">{listDetails.permanent}</span>
                    </div>
                    <div>
                        <span className="s-regular-small text-primary">Interns</span>
                        <span className="s-regular text-primary">{listDetails.interns}</span>
                    </div>
                    <div>
                        <span className="s-regular-small text-primary">Freshers</span>
                        <span className="s-regular text-primary">{listDetails.freshers}</span>
                    </div>
                    <div className="col-3">
                        <span className="s-regular-small text-primary">Gender ratio</span>
                        <span className="s-regular text-primary">{listDetails.freshers}</span>
                    </div> */}
                </div>
            </div>
         {/* {   <div className="col-10 d-none d-md-flex  d-lg-none justify-content-between px-2">
                <div>
                    <span className="s-regular-small text-primary ">Total</span>
                    <span className="s-regular text-primary">{listDetails.total}</span>
                </div>
                <div>
                    <span className="s-regular-small text-primary">Permanent</span>
                    <span className="s-regular text-primary">{listDetails.permanent}</span>
                </div>
                <div>
                    <span className="s-regular-small text-primary">Interns</span>
                    <span className="s-regular text-primary">{listDetails.interns}</span>
                </div>
                <div>
                    <span className="s-regular-small text-primary">Freshers</span>
                    <span className="s-regular text-primary">{listDetails.freshers}</span>
                </div>
                <div className="col-3">
                    <span className="s-regular-small text-primary">Gender ratio</span>
                    <span className="s-regular text-primary">{listDetails.freshers}</span>
                </div>
            </div>} */}
            <div className="col-10 d-flex justify-content-between d-flex d-md-none px-2">
                <div className="col-6 ">
                    <div>
                        <span className=" s-regular-small text-primary ">Total</span>
                        <span className="s-regular text-primary">{listDetails.total}</span>
                    </div>
                    <div>
                        <span className="s-regular-small text-primary">Permanent</span>
                        <span className="s-regular text-primary">{listDetails.permanent}</span>
                    </div>

                </div>
                <div className="col-6 d-flex flex-column align-items-end">
                    <div className="col-8 col-sm-5">
                        <span className="s-regular-small text-primary">Freshers</span>
                        <span className="s-regular text-primary">{listDetails.freshers}</span>
                    </div>
                    <div className="col-8 col-sm-5">
                        <span className="s-regular-small text-primary">Gender ratio</span>
                        <span className="s-regular text-primary">{listDetails.freshers}</span>
                    </div>
                </div>

            </div>
            <div className="col-10">


                <List titles={titles}
                    id="employee"
                    searchKey={searchKey}
                    pageCount={pageCount}
                    loading={loading}
                    end={employeeEnd}
                    setEnd={setEmployeeEnd}
                    start={employeeStart}
                    routes={routes}
                    setStart={setEmployeeStart}
                    currentPage={employeeCurrentPage}
                    setCurrentPage={setEmployeeCurrentPage}
                    cardData={employeeData} btns={btns} filters={filters} filterIndex={filterIndex} setFilterIndex={setFilterIndex} />

            </div>


        </div>
    )
}
export default EmployeeList;