import React, { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import rightArrow from '../../assets/icons/rightArrow.svg';
import close from '../../assets/icons/close.svg'
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import save from '../../assets/icons/save.svg'
import left from '../../assets/icons/left.svg';
import { useEffect } from 'react';
import { fetchSalaryTemplate } from '../../utility/services/fetchSalaryTemplate';
import { fetchSingleSalaryTemplate, updateSalaryTemplate, createSalaryTemplate } from '../../utility/services/salaryTemplatesServices';
import trash from '../../assets/icons/trash.svg';
import veusax from '../../assets/icons/veusax.svg';
import * as Yup from 'yup';
import SalaryTemplateConfirmModal from './confirmSalaryTemplate';
import { useSnackbar } from "notistack";

const CreateEditSalaryTemplate = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const initialFields = [
        { label: "", percentage: ''}
      ];
    const [formKey, setFormKey] = useState(0)
    const [loadingData, setLoadingData] = useState(false);
    const [salaryTemplates, setSalaryTemplates] = useState([]);
    const [initialValues, setInitialValues] = useState();
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [percentageError, setPercentageError] = useState();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const fetchSalaryTemplate = async () => {
        setLoadingData(true);
        try {
            const response = await fetchSingleSalaryTemplate(id);
            setLoadingData(false);
            setSalaryTemplates(response && response[0]?.salary_template);
            setInitialValues(response && response[0]?.salary_template);
        }
        catch (error) {
            setLoadingData(false);
        }
    }

    const handleFieldChange = (index, field, value) => {
        let total = 0;
        let updatedFields = [];
        if (initialValues) updatedFields = [...initialValues];
        updatedFields[index][field] = value;
        setInitialValues(updatedFields);
        initialValues.forEach((field) => {
            total += parseInt(field.percentage, 10) || 0;
        });
        if (total === 100) {
            setPercentageError(null);
        }
        else if (total > 100) {
            setPercentageError("Total percentage exceeds 100, please make appropriate changes");
        }
        else {
            setPercentageError("Total percentage is less than 100, please make appropriate changes");
        }
        setTotalPercentage(total);
    };

    const validationSchema = Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required('Label is required'),
            percentage: Yup.number()
                .typeError('Percentage must be a number')
                .positive('Percentage must be a positive number')
                .required('Percentage is required'),
        })
    );

    const submitHandler = async (values, { setSubmitting }) => {
        console.log(values)
        totalCalculation(values);
        setSubmitting(false);
        // setTimeout(() => {
            if (percentageError) {
                setOpenConfirmModal(false);
            }
            else{
                setOpenConfirmModal(true);
            }
        // }, [500])

    }

    const saveHandler = async (sendData, id) => {
        setLoadingSubmit(true);
        if (id) {
            try {
                const response = await updateSalaryTemplate(sendData, id);
                setLoadingSubmit(false);
                enqueueSnackbar("Salary template updated successfully", { variant: 'success' });
                setOpenConfirmModal(false);
                navigate(`/templates/list`);
            }
            catch (error) {
                setLoadingSubmit(false);
                enqueueSnackbar(error.data.message, { variant: 'error' });
                setOpenConfirmModal(false);
            }
        }
        else{
            try {
                const response = await createSalaryTemplate(sendData);
                setLoadingSubmit(false);
                enqueueSnackbar("Salary template added successfully", { variant: 'success' });
                setOpenConfirmModal(false);
                navigate(`/templates/list`);
            }
            catch (error) {
                setLoadingSubmit(false);
                enqueueSnackbar(error.data.message, { variant: 'error' });
                setOpenConfirmModal(false);
            }
        }
    }

    const closeConfirmHandler = () => {
        setOpenConfirmModal(false);
    };

    const totalCalculation = (data) => {
        let total = 0;
        data && data.forEach((field) => {
            total += parseInt(field.percentage, 10) || 0;
        });
        if (total === 100) {
            setPercentageError(null);
        }
        else if (total > 100) {
            setPercentageError("Total percentage exceeds 100, please make appropriate changes");
        }
        else {
            setPercentageError("Total percentage is less than 100, please make appropriate changes");
        }
    }

    useEffect(() => {
        setInitialValues();
        id && fetchSalaryTemplate();
    }, [id]);

    useEffect(() => {
        setInitialValues(initialFields);
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            key={formKey}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, validateForm, handleChange, touched, values, setTouched, setFieldValue, errors }) => {
                return (
                    <Form>
                        <div className="d-flex flex-column align-items-center">
                            <div className="col-10  m-24 d-flex justify-content-between flex-wrap">
                                <div className="d-flex align-items-center gap-2">
                                    {/* <Link to='Home' className=" s-regular decoration-none text-primary cursor-pointer">Home</Link> */}
                                    {/* <img src={rightArrow} alt="right" /> */}
                                    <Link to="/templates/list" className="s-regular decoration-none cursor-pointer text-primary">Salary template</Link>
                                    <img src={rightArrow} alt="right" />
                                    <span className="s-regular cursor-pointer text-primary">{id ? "Edit" : "Create"}</span>
                                </div>


                            </div>
                            <div className="d-flex col-10 mt-40  justify-content-between align-items-center">
                                <div className="col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                                    <div className="d-flex gap-10 col-12 col-sm-6">
                                        <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" />
                                        <span className="extra-bold">{id ? 'Edit' : 'Add'} salary template {loadingData && <ClipLoader size={18} />}</span>

                                    </div>
                                    <div className="d-flex gap-24 col-12 col-sm -6  justify-content-end">
                                        <button onClick={() => {
                                            navigate('/templates/list')

                                        }} type="button" className="d-flex gap-10 h-44 btn btn-outline-main rounded-10 w-120 gap-10 p-10-16 ">
                                            <img src={close} alt="" />
                                            <span>Cancel</span>
                                        </button>


                                        <button type="submit" onClick={
                                            () => {

                                                setTouched({});

                                            }
                                        }

                                            className="d-flex align-items-center justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">

                                            {
                                                <>
                                                    <img src={save} alt="" />
                                                    <span>Save</span>
                                                </>
                                            }
                                        </button>




                                    </div>

                                </div>

                            </div>
                            <div className="col-10 h-90px rounded-20 bg-white d-flex flex-column m-24 p-24 ">

                                <div className="d-flex flex-column gap-24">

                                    <div className="d-flex flex-column gap-24 border-secondary p-24">
                                        {/* <Formik initialValues={initialEmployeeInfo}  validationSchema={validationSchemaEmployeeInfo}> */}
                                        <h5 className="accordion-header"> Salary template</h5>
                                        <FieldArray name='initialValues'>
                                            {({ push, remove }) => (
                                                <div className="col-12 d-flex flex-wrap">
                                                    {values?.map((field, index) => (
                                                        <div className="m-24 col-12 d-flex gap-24 " key={field.id}>
                                                            <div className='col-5  d-flex flex-column gap-6'>
                                                                <label className='s-regular-small text-secondary' htmlFor={`initialValue[${index}].label`}>
                                                                    Eg. Bonus,House rent etc
                                                                </label>
                                                                <Field
                                                                    name={`initialValues[${index}].label`}
                                                                    className={`${touched?.[index]?.label && errors?.[index]?.label ? 'border-danger' : 'input-border-primary'} col-3 rounded height-50px form-control shadow-none`}
                                                                    value={initialValues && initialValues[index]?.label}
                                                                    onChange={(e) =>
                                                                        handleFieldChange(index, `label`, e.target.value)
                                                                    }
                                                                />
                                                                {touched?.[index]?.label && errors?.[index]?.label &&
                                                                    <span className="ml-3 s-regular-xsmall error-msg s-regular-small">{errors?.[index]?.label}</span>}
                                                            </div>

                                                            <div className=' col-5 d-flex justify-content-end flex-column d-flex flex-column gap-6 '>
                                                                <label className='s-regular-small text-secondary' htmlFor={`initialValue[${index}].label`}>
                                                                    Percentage
                                                                </label>
                                                                <Field
                                                                    name={`initialValues[${index}].percentage`}
                                                                    className={`${touched?.[index]?.percentage && errors?.[index]?.percentage ? 'border-danger' : 'input-border-primary'} col-3 rounded height-50px form-control shadow-none`}
                                                                    value={initialValues && initialValues[index]?.percentage}
                                                                    onChange={(e) =>
                                                                        handleFieldChange(index, 'percentage', e.target.value)
                                                                    }
                                                                />
                                                                {touched?.[index]?.percentage && errors?.[index]?.percentage &&
                                                                    <span className="ml-3 s-regular-xsmall error-msg s-regular-small">{errors?.[index]?.percentage}</span>}

                                                            </div>
                                                            {console.log(touched)}
                                                            <div className='d-flex align-items-center'>
                                                                <img src={trash} alt="trash" className='m-24 cursor-pointer' onClick={() => {
                                                                    values?.splice(index, 1);
                                                                    setFieldValue(values)
                                                                    setInitialValues(values)
                                                                    totalCalculation(values);
                                                                }} />
                                                            </div>
                                                        </div>))
                                                    }
                                                    <div className='m-24 d-flex justify-content-start col-12'>
                                                        {percentageError && <span className="ml-3 s-regular-xsmall error-msg s-regular-small">{percentageError}</span>}
                                                    </div>
                                                    <div className='m-24 d-flex justify-content-start col-12'>
                                                        <button type='button' onClick={() => {

                                                            values?.push({ label: "", percentage: "" })
                                                            setFieldValue(values)
                                                            setInitialValues(values)
                                                        }


                                                        } className="cursor-pointer btn-secondary rounded-10 d-flex gap-10 p-10-16 border-none">
                                                            <img src={veusax} alt="" />
                                                            <span className="text-add">Add field</span>
                                                        </button>
                                                    </div>
                                                </div>)}
                                        </FieldArray>
                                    </div>
                                </div>
                            </div>
                            {openConfirmModal &&
                                <SalaryTemplateConfirmModal
                                    setOpenConfirm={setOpenConfirmModal}
                                    show={openConfirmModal}
                                    closeConfirmHandler={closeConfirmHandler}
                                    requestId={id && id}
                                    sendValue={initialValues}
                                    saveFunction={saveHandler}
                                    isSubmitting={loadingSubmit}
                                />
                            }
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )

}

export default CreateEditSalaryTemplate;