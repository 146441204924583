import rightArrow from '../../assets/icons/rightArrow.svg';
import { Link } from "react-router-dom";
import search from '../../assets/icons/search.svg';
import left from '../../assets/icons/left.svg'
import add from '../../assets/icons/add.svg'
import { useCallback, useContext, useEffect, useState } from "react";
import List from "../../containers/list";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader"
import save from '../../assets/icons/save.svg'
import close from '../../assets/icons/close.svg'
import payroll from '../../assets/icons/payroll.svg'
import calendar from '../../assets/icons/calendar.svg'
import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
// import { Tooltip } from 'react-chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import ModalComponent from '../../containers/salaryedit';
import { CategoryScale, Chart, LineElement, LinearScale, PointElement, Tooltip } from "chart.js";
import { useSnackbar } from "notistack";
import { PaginationContext } from "../../context/paginationContext";
import { getSalaryList } from '../../utility/services/fetchSalary';
import { getAllEmployees } from '../../utility/services/getAllEmployees';
import { handlePay } from '../../utility/services/payRoll';
import { GlobalContext } from '../../context/globalContext'
import { getSalaryDatas } from '../../utility/services/salaryListing';

const SalaryList = () => {
    Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

    const [yearlyLoader, setYearlyLoader] = useState(false);
    Chart.register(
        LineElement, CategoryScale, LinearScale, PointElement
    )
    const [paymentArr, setPaymentArr] = useState([]);
    const navigate = useNavigate();
    const [emptyError, setEmptyError] = useState("")
    const [loading, setLoading] = useState(false);
    const btns = ["Edit"]
    const { enqueueSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(false);
    const [editId, setEditId] = useState('')
    const routes = { editFun: (id) => { setEditId(id); setOpenModal(true) } }
    const heading = "Employee salary"
    const titles = ["Name", "Salary", "Status", "Payment date"]
    const [selectedYear, setSelectedYear] = useState(new Date());
    const { salaryData, setSalaryData } = useContext(GlobalContext);
    const [graphData, setGraphData] = useState([])
    const dateRef = useRef(null);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const [graphDataonHover, setGraphDataOnHover] = useState();
    const currentMonthIndex = currentDate.getMonth();

    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();
    const [payRollArr, setPayRollArr] = useState([]);
    const [year, setYear] = useState(currentYear);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [cardId, setCardId] = useState("");
    const [employeeList, setEmployeeList] = useState([]);
    const [loadingPayRoll, setLoadingPayRoll] = useState(false);
    const [monthlyPaid, setMonthlyPaid] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const {
        salaryStart, setSalaryStart,
        salaryEnd, setSalaryEnd,
        salaryCurrentPage, setSalaryCurrentPage,
    } = useContext(PaginationContext)
    const [month, setMonth] = useState(currentMonthIndex + 1);
    const pastThreeMonths = [
        months[(currentMonthIndex - 3 + 12) % 12],
        months[(currentMonthIndex - 2 + 12) % 12],
        months[(currentMonthIndex - 1 + 12) % 12],
        months[currentMonthIndex]
    ];
    const monthsIndices = [(currentMonthIndex - 3 + 12) % 12, (currentMonthIndex - 2 + 12) % 12, (currentMonthIndex - 1 + 12) % 12, currentMonthIndex];


    const yearArr = pastThreeMonths.map(
        (el, i) => {
            if (currentMonthIndex < 3) {
                return currentYear - 1
            } else {
                return currentYear;
            }
        }
    )


    const data = {
        labels: pastThreeMonths,
        datasets: [{
            label: 'Salary',
            data: graphData?.map(e => (e.totalPaidAmount)),
            borderColor: '#D3F36B',
            fill: true,
            backgroundColor: (context) => {
                var value = context.dataset?.data[context.dataIndex];
                if (graphData[context.dataIndex]?.total > value) {


                    return '#FFB800';
                } else {
                    return '#00D322';
                }
            },
            pointBorderColor: (context) => {
                var value = context.dataset?.data[context.dataIndex];
                if (graphData[context.dataIndex]?.total > value) {

                    return '#FFB800';
                } else {
                    return '#00D322';
                }
            },

            pointRadius: 5
        }]
    };
    const closeHandler = () => {
        setOpenModal(false)
    }
    const [currentPage, setCurrentPage] = useState();
    const [pageSize, setPageSize] = useState(4);
    const [monthlySalary, setMonthlySalary] = useState();
    const [pageCount, setPageCount] = useState("")
    const [anualSalary, setAnualSalary] = useState("");
    const options = {
        maintainAspectRatio: true,
        // responsive: true,
        plugins: {
            Tooltip: {
                enable: true,
            }
        },




        scales: {
            x: {
                border: { display: false },
                grid: {
                    display: false,

                },
                type: 'category',
                labels: pastThreeMonths,
            },
            y: {
                border: { dash: [8], display: false },

                grid: {
                    drawBorder: false,
                    tickBorderDash: [5, 5],




                },

                beginAtZero: false,
                ticks: {
                    callback: function (value, index, values, data) {


                        return (value / 50000) + 'k';
                    },
                    stepSize: 50000
                }

            }
        }
    }
    const handlePayroll = async () => { // for pay
        if (payRollArr.length === 0) {
            setEmptyError("Please select atleast one employee");
            return;
        }
        setLoadingPayRoll(true);
        try {
            const resp = await handlePay(payRollArr);
            getSalary();
            // getEmployeesId();
            setLoadingPayRoll(false);
            enqueueSnackbar("Payment successfull", { variant: "success" })
            setPayRollArr([])
            setPaymentArr([])
        } catch (error) {
            enqueueSnackbar(error.data.message, { variant: "error" })
            setLoadingPayRoll(false);
        }
    }



    const getMonthlySalaryData = async () => { // for graph
        try {
            const response = await getSalaryList(yearArr[0], monthsIndices[0] + 1, yearArr[3], monthsIndices[3] + 1, null);
            let salaryArr = response.datas;
            const data1 = salaryArr.filter((e, i) => (
                e.salary_month == monthsIndices[0] + 1
            ))
            const data2 = salaryArr.filter((e, i) => (
                e.salary_month == monthsIndices[1] + 1
            ))
            const data3 = salaryArr.filter((e, i) => (
                e.salary_month == monthsIndices[2] + 1
            ))
            const data4 = salaryArr.filter((e, i) => (
                e.salary_month == monthsIndices[3] + 1
            ))

            const getDataforGraph = (data) => {

                const totalSalary = data?.reduce((sum, e) => (sum + e.salary), 0);

                const totalAddition = data.reduce((sum, e) => (
                    sum + e?.addition?.reduce((total, el) => (total + el?.amount), 0)
                ), 0)
                const totalDeduction = data.reduce((sum, e) => (
                    sum + e?.deduction?.reduce((total, el) => (total + el?.amount), 0)
                ), 0)
                const totalPaidAmount = data.reduce((sum, e) => (
                    sum + e.payment?.reduce((total, elem) => (total + elem.paid_amount), 0)
                ), 0)
                const total = totalSalary + totalAddition - totalDeduction;
                return { total: total, totalPaidAmount: totalPaidAmount }
            }

            setGraphData([getDataforGraph(data1), getDataforGraph(data2), getDataforGraph(data3), getDataforGraph(data4)])
        } catch (error) {
        }
    }





    const getSalary = async () => {
        setLoading(true);
        
        let reqMonth = month - 1;
        let reqYear = year
        if (currentYear === year && currentMonthIndex === month && currentDay <= 20) {
            if (month === 1) {
                reqYear = year - 1;
            }
            reqMonth = (month - 2 + 12) % 12;

        }
        try {
            // const response = await getSalaryList(reqYear, reqMonth + 1, reqYear, reqMonth + 1);
            const response = await getSalaryDatas(reqMonth+1, reqYear, 5, salaryCurrentPage);
            setPaymentHistory(response[0].data);
            const salaryObjs = response[0].data;
            const result = salaryObjs.map((e, i) => {
                if (e?.payment&& e?.payment?.length>0) {
                    const paidSalary = e?.payment?.reduce((sum, curr) => (sum + curr.paid_amount), 0);
                    const addition = e?.addition?.reduce((sum, e) => sum + e.amount, 0);
                    const deduction = e?.deduction?.reduce((sum, e) => sum + e.amount, 0);
                    const total = e?.salary + addition - deduction;
                    const paidDates = e?.payment.map(e => new Date(e?.paid_date))
                    const payingSalary = total - paidSalary;
                    const latestDate = Math.max(...paidDates)

                    const paidLatestDate = new Date(Math.max(...paidDates));
                    const dd = dayjs(paidLatestDate).format('DD');
                    const mm = dayjs(paidLatestDate).format('MM');
                    const yy = dayjs(paidLatestDate).format('YYYY');
                   
                    return {
                        id: e?._id,
                        totalSalary: e?.salary,
                        month: reqMonth,
                        year: reqYear,
                        date_of_join: e?.user?.official_details?.date_of_join,
                        img: e?.user.personel_details?.image,
                        cardTitle: e?.user.personel_details?.first_name + " " + e?.personel_details?.last_name,
                        cardId: e?.user?.employee_code,
                        paidSalary: paidSalary,
                        amountTobePaid: payingSalary,
                        data1: e.salary,
                        data2: (total === paidSalary ? "Paid" : paidSalary < total ? "Partial" :
                            "Unpaid"),
                        // data3: months[new Date(latestDate).getMonth()] + " " + new Date(latestDate).getDay() + "," + new Date(latestDate).getFullYear()
                        data3: dd + "-" + months[Number(mm) - 1] + "-" + yy
                    }
                } else {
                    let salary = e?.salary;
                    const joiningYear = e?.official_details?.date_of_join?.split('-')[0]
                    const joiningMonth = e?.official_details?.date_of_join?.split('-')[1]
                    let status = ""
                    if (salary) {
                        status = "Unpaid";
                        // if (year >= joiningYear) {
                        //     if (month >= joiningMonth) {
                        //         status = "Unpaid";
                        //     } else {
                        //         status = "";
                        //     }
                        // } else {
                        //     status = "";
                        // }
                    }

                    return {
                        id: e?._id,
                        month: reqMonth,
                        totalSalary: e?.salary,
                        year: reqYear,
                        paidSalary: 0,
                        date_of_join: e?.official_details.date_of_join,
                        img: e?.personel_details?.image,
                        cardTitle: e?.personel_details?.first_name + " " + e?.personel_details?.last_name,
                        cardId: e?.official_details?.employee_id,
                        data1: salary,
                        data2: status,
                        amountTobePaid: salary,
                        // data2: "Paid",
                        data3: "",
                    }
                }
            })

            setSalaryData(result)
            setLoading(false);
            const pages=Math.ceil((response[0].metadata)[0].totalItems/5);
            setPageCount(pages)


        } catch (error) {
            setLoading(false);
        }

    }
    const fetchMonthlySalary = async () => { // get sum of montyly salary
        setLoading(true);
        try {

            const response = await getSalaryList(currentYear, currentMonthIndex + 1, currentYear, currentMonthIndex + 1);
            setMonthlySalary(response.datas.reduce((sum, current) => Number(current?.salary) + sum, 0))
            setMonthlyPaid(response.datas.reduce((sum, current) => {
                const sumOfIndividualPaid = current.payment.reduce((total, curr) => (Number(total) + Number(curr.paid_amount)), 0)
                return sum + sumOfIndividualPaid;
            }, 0));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const getEmployeesId = async () => { // for compare both employees and salary history
        setLoading(true)
        try {
            const employeesData = await getAllEmployees(salaryCurrentPage, 5, { _id: 1, department: "all" }, null);
            setPageCount(employeesData.totalPages)
            setEmployeeList(employeesData?.datas);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }
    const getYearlySalary = async () => { //get sum of salary history in a year
        const reqYear = String(selectedYear).split(' ')[3]
        // setLoading(true)
        setYearlyLoader(true);
        try {
            const resp = await getSalaryList(reqYear, null, reqYear, null, null)
            const totalYearlySalary = resp?.datas?.reduce((sum, current) => {
                const amount = current?.payment?.reduce((total, current) => (total + current?.paid_amount), 0)
                return (sum + amount);
            }
                , 0)
            setYearlyLoader(false);
            setAnualSalary(totalYearlySalary);
            // setLoading(false);
        } catch (error) {
            // setLoading(false);
            setYearlyLoader(false);

        }

    }


    useEffect(() => {
        getYearlySalary();
    }, [selectedYear])


    useEffect(() => {
        setAllChecked(false);
        setPayRollArr([]);
        setPaymentArr([]);
        getSalary()
        getEmployeesId();

    }, [month, year, salaryCurrentPage])

    useEffect(() => {
        fetchMonthlySalary();
    }, [])

    useEffect(() => {
        getMonthlySalaryData();
    }, [])

    // useEffect(() => {

    //     let matchIndex = -1;
    //     const result = employeeList?.map((e, j) => {

    //         const match = paymentHistory?.find((el, i) => {

    //             if (el.user === e._id) {

    //                 matchIndex = i;

    //             } else {
    //                 matchIndex = -1
    //             }
    //             return el.user === e._id
    //         });

    //         let reqMonth = month;
    //         let reqYear = year;
    //         if (currentYear === year && currentMonthIndex === month && currentDay <= 20) {
    //             if (month == 1) {
    //                 reqMonth = 12;
    //                 reqYear = year - 1;
    //             } else {
    //                 reqMonth = month - 1;
    //                 reqYear = year;
    //             }
    //         }


    //         if (matchIndex > -1) {

    //             const paidSalary = paymentHistory[matchIndex].payment.reduce((sum, curr) => (sum + curr.paid_amount), 0);
    //             const addition = paymentHistory[matchIndex].addition.reduce((sum, e) => sum + e.amount, 0);
    //             const deduction = paymentHistory[matchIndex].deduction.reduce((sum, e) => sum + e.amount, 0);
    //             const total = paymentHistory[matchIndex].salary + addition - deduction;
    //             const paidDates = paymentHistory[matchIndex].payment.map(e => new Date(e.paid_date))
    //             const payingSalary = total - paidSalary;
    //             const latestDate = Math.max(...paidDates)

    //             const paidLatestDate = new Date(Math.max(...paidDates));
    //             const dd = dayjs(paidLatestDate).format('DD');
    //             const mm = dayjs(paidLatestDate).format('MM');
    //             const yy = dayjs(paidLatestDate).format('YYYY');


    //             return {
    //                 id: employeeList[j]?._id,
    //                 totalSalary: paymentHistory[matchIndex]?.salary,
    //                 month: reqMonth,
    //                 year: reqYear,
    //                 date_of_join: employeeList[j].official_details.date_of_join,
    //                 img: employeeList[j]?.personel_details?.image,
    //                 cardTitle: employeeList[j]?.personel_details?.first_name + employeeList[j]?.personel_details?.last_name,
    //                 cardId: employeeList[j]?.official_details?.employee_id,
    //                 paidSalary: paidSalary,
    //                 amountTobePaid: payingSalary,
    //                 data1: paymentHistory[matchIndex].salary,
    //                 data2: (total === paidSalary ? "Paid" : paidSalary < total ? "Partial" :
    //                     "Unpaid"),
    //                 // data3: months[new Date(latestDate).getMonth()] + " " + new Date(latestDate).getDay() + "," + new Date(latestDate).getFullYear()
    //                 data3: dd + "-" + months[Number(mm) - 1] + "-" + yy
    //             }
    //         } else {
    //             let salary = employeeList[j]?.salary;

    //             // let salary=undefined;
    //             //    const arrayOrNot=Array.isArray(salaryArr)
    //             //    if(arrayOrNot && arrayOrNot?.length>0){
    //             //         salary=salaryArr.reduce((sum, current) => (Number(sum) + Number(current.value)), 0);
    //             //    }
    //             // employeeList[j]?.salary?.reduce((sum, current) => (Number(sum) + Number(current.value)), 0)
    //             const joiningYear = employeeList[j].official_details.date_of_join.split('-')[0]
    //             const joiningMonth = employeeList[j].official_details.date_of_join.split('-')[1]
    //             let status = ""
    //             if (salary) {
    //                 if (year >= joiningYear) {
    //                     if (month >= joiningMonth) {
    //                         status = "Unpaid";
    //                     } else {
    //                         status = "";
    //                     }
    //                 } else {
    //                     status = "";
    //                 }
    //             }






    //             return {
    //                 id: employeeList[j]?._id,
    //                 month: reqMonth,
    //                 totalSalary: employeeList[j]?.salary,
    //                 year: reqYear,
    //                 paidSalary: 0,
    //                 date_of_join: employeeList[j]?.official_details.date_of_join,
    //                 img: employeeList[j]?.personel_details?.image,
    //                 cardTitle: employeeList[j]?.personel_details?.first_name + employeeList[j]?.personel_details?.last_name,
    //                 cardId: employeeList[j]?.official_details?.employee_id,
    //                 data1: salary,
    //                 data2: status,
    //                 amountTobePaid: salary,
    //                 // data2: "Paid",
    //                 data3: "",
    //             }
    //         }
    //     })


    //     setSalaryData(result)


    // }, [employeeList, paymentHistory])


    // useEffect(()=>{

    // },[salaryCurrentPage])


   



    return (
        <div className=" d-flex flex-column align-items-center">
            {openModal &&
                <ModalComponent
                    title="Salary edit"
                    obj={salaryData.find(el => (el.id === editId))}
                    setCardData={setSalaryData}
                    setOpenModal={setOpenModal}
                    show={openModal}
                    closeHandler={closeHandler}
                />
            }
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-10">
                    {/* <Link to='/Home' className="s-regular decoration-none text-primary cursor-pointer">Home</Link> */}
                    {/* <img src={rightArrow} alt="" /> */}
                    <span className="s-regular cursor-pointer text-primary">Salary</span>

                </div>
                {/* <div className="d-flex rounded bg-white">
                    <input placeholder="Search" className="p-10-16 rounded bg-pirmary outline-0 border-0" type="text" />
                    <img className="bg-white rounded p-10-16" src={search} alt="search" />
                </div> */}

            </div>
            <div className="d-flex col-10 mt-40 mb-24 justify-content-between align-items-center">
                <div className="col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div className="d-flex gap-24 col-12 col-sm-6">
                        {/* <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" /> */}
                        <span className="extra-bold">Salary</span>

                    </div>
                    <div className="d-flex gap-24 col-12 col-sm -6 justify-content-end">

                        <button

                            onClick={handlePayroll}
                            type="submit" className=" d-flex justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 gap-10 p-10-16 align-items-center">
                            {loadingPayRoll ? <ClipLoader /> :
                                <> <img src={payroll} alt="" />
                                    <span>Run payroll</span>
                                </>
                            }

                        </button>

                    </div>

                </div>


            </div>
            {emptyError && <div className="col-10 text-danger d-flex justify-content-end mb-24">{emptyError}</div>}
            <div className="d-flex flex-column flex-xl-row col-10">
                <div className="col-12 col-xl-9 pr-xl-24">
                    <List
                        arr={paymentArr}
                        allChecked={allChecked}
                        setAllChecked={setAllChecked}
                        paginationId="available"
                        pageCount={pageCount}
                        setYear={setYear}
                        emptyError={emptyError}
                        setEmptyError={setEmptyError}
                        end={salaryEnd}
                        setEnd={setSalaryEnd}
                        start={salaryStart}
                        setStart={setSalaryStart}
                        currentPage={salaryCurrentPage}
                        setCurrentPage={setSalaryCurrentPage}
                        setMonth={setMonth}
                        setArr={setPaymentArr}
                        btns={btns}
                        type="checkbox"
                        id="salary"
                        titles={titles}
                        heading={heading}
                        link={"salary"}
                        routes={routes}
                        cardData={salaryData}
                        loading={loading}
                        setCardData={setSalaryData}
                        payRollArr={payRollArr}
                        setPayRollArr={setPayRollArr}
                    />
                </div>
                <div className='mt-24 mt-xl-0 col-12 col-xl-3 flex-column d-flex flex-md-row flex-xl-column gap-24'>
                    <div className="col-6 col-lg-4 col-xl-12 d-flex flex-column gap-24 bg-white rounded-20 p-24 ">
                        <div>
                            <Line data={data}
                                height={10}
                                width={10}
                                options={options} />

                        </div>

                        <div className='d-flex flex-column gap-6'>
                            <div className='d-flex  flex-column'>
                                <span className='text-secondary s-regular-small'>Total monthly salary</span>
                                <span className='extra-bold'>
                                    {monthlySalary?.toLocaleString('en-IN')}
                                </span>
                            </div>
                            <div className='d-flex  flex-column'>
                                <span className='text-secondary s-regular-small'>Total monthly paid amount</span>
                                <span className='extra-bold'>
                                    {monthlyPaid?.toLocaleString('en-IN')}
                                </span>
                            </div>



                        </div>
                    </div>
                    <div className="col-5 col-lg-5 col-xl-12  d-flex flex-column gap-24 bg-white rounded-20 p-24 ">
                        <div className=' d-flex flex-column flex-md-row gap-24 justify-content-between align-items-xl-center'>
                            <span className='s-regular-semi-bold'>
                                Yearly salary
                            </span>

                            <button
                                onClick={() => {

                                    dateRef?.current?.setOpen(true);
                                }}
                                className=' btn-secondary rounded-10 d-flex justify-content-between align-items-center text-white gap-10 border-none p-10-16'>

                                <img src={calendar} alt="calendar" />
                                <div>
                                    <DatePicker
                                        selected={selectedYear}
                                        customInput={<input style={{ display: 'none' }} />}
                                        ref={dateRef}
                                        onChange={(date) => {
                                            setSelectedYear(date)
                                        }}
                                        popperPlacement="top-end"
                                        dateFormat="yyyy"
                                        showYearPicker
                                        showYearDropdown
                                        placeholderText="YYYY"
                                    />
                                </div>
                                <span className='text-white'>{selectedYear?.getFullYear()}</span>
                            </button>
                        </div>
                        <div>
                            <div className='d-flex  gap-6'>
                                <div>
                                    <span className='d-flex flex-column gap-24 text-secondary s-regular-small'>
                                        Total salary paid
                                    </span>
                                    <span className='extra-bold'>
                                        {yearlyLoader ? <ClipLoader /> : <>{anualSalary.toLocaleString('en-IN')}</>}
                                    </span>
                                </div>



                            </div>

                        </div>
                        <div>
                            <span className='d-flex flex-column text-secondary s-regular-small'>
                                Total projection
                            </span>
                            <span className='extra-bold'>
                                {!isNaN(monthlySalary) && (Number(monthlySalary) * 12).toLocaleString('en-IN')}

                            </span>
                        </div>
                    </div>
                </div>

            </div>



        </div>

    )
}

export default SalaryList;