import { createContext, useContext, useState } from "react";
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
    const [profileData, setProfileData] = useState({});
    const [showHeader, setShowHeader] = useState(true);
    const [openAllEdits,setOpenAllEdits]=useState(false);
    const [salaryEdits,setSalaryEdits]=useState([]);
    const [salaryData,setSalaryData]=useState([]);
    const [organizationId,setOrganizationId]=useState("");
    const [userName,setUserName]=useState('')

    return (
        <GlobalContext.Provider value={
        {
            salaryData,setSalaryData,
            userName,setUserName,
            salaryEdits,setSalaryEdits,
            openAllEdits,setOpenAllEdits, 
            profileData, setProfileData, 
            showHeader, setShowHeader,
            organizationId, setOrganizationId
        }
        }>
            {children}
        </GlobalContext.Provider>
    )


}

export default GlobalContextProvider;

