import axios from "axios";
export const getSalaryDatas = (salary_month,salary_year,pageSize,page) => {
    return new Promise((resolve, reject) => {

        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/listing`,
            params: {
                salary_month:salary_month,
                salary_year:salary_year,
                pageSize:pageSize,
                page:page
            },

        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}