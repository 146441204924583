import axios from "axios"


export const fetchAllExternalServices=(values, page, size)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/external-services`,
            params:{
                page:page,
                pageSize:size,
                keyword:values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const enableExternalService=(id)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'PATCH',
            url: `${process.env.REACT_APP_BASE_URL}/external-services/organization/enable`,
            data: {
                service_id: id 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const disableExternalService=(id)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'PATCH',
            url: `${process.env.REACT_APP_BASE_URL}/external-services/organization/disable`,
            data: {
                service_id: id 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}