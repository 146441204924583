import axios from "axios"


export const fetchSkills=(values, page, size)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/skills`,
            params:{
                page:page,
                pageSize:size,
                keyword:values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const fetchSingleSkills=(id)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/skills/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}