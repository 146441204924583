import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from 'yup';
import rightArrow from '../../assets/icons/rightArrow.svg';
import left from '../../assets/icons/left.svg';
import gallery from '../../assets/icons/gallery.svg'
import close from '../../assets/icons/close.svg'
import save from '../../assets/icons/save.svg'
import trash from '../../assets/icons/trash.svg'
import closeCircle from '../../assets/icons/closecircle.svg'
import ClipLoader from "react-spinners/ClipLoader";
import { createNewEmployee } from "../../utility/services/createNewEmployee";
import Select from "react-select"
import add from '../../assets/icons/add.svg'
import profilepic from '../../assets/icons/profilePic.svg';
import veusax from '../../assets/icons/veusax.svg';
import currency from '../../assets/icons/currency.svg';
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import calendar from '../../assets/icons/calendar2.svg';
import { fetchDepartments } from "../../utility/services/fetchDepartment";
import CustomField from "../../components/customField";
import CustomSelect from "../../components/customSelect";
import CustomRadio from "../../components/customRadio";
import { submitEmployee } from "../../utility/services/submitEmployee";
import { fetchEmployeeTypes } from "../../utility/services/fetchEmployeeTypes";
import { useSnackbar } from "notistack";
import { fetchSkills } from "../../utility/services/fetchSkills";
import { fetchSalaryTemplate } from "../../utility/services/fetchSalaryTemplate";

const NewEmployee = () => {
    const navigate = useNavigate();
    const [departments, setDepartments] = useState([])
    // const  [err,setErr]=useState({
    //     emp:false,pers:false,bank:false,add:false,
    // })
    const [openPersonal, setOpenPersonal] = useState("");
    const [userTypeOptions, setUserOptions] = useState();
    const [showEmpSection, setEmpSection] = useState(false);
    const [showPersonalSection, setShowPesonalSection] = useState(false);
    const [showAddressInfo, setshowAddressInfo] = useState(false);
    const [showBankDetails,setShowBankDetails]=useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [salaryTemplate, setSalaryTemplate] = useState([]);
    const [showBankDetatils, setShowBankDetail] = useState(false);
    const [err, setErr] = useState(true);
    const genders = [{ label: "Male", value: 'Male' }, { label: "Female", value: 'Female' }, { label: "Other", value: "other" }]
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [skills, setSkills] = useState([])
    const [selectedDateOfHire, setSelectedDateOfHire] = useState("");
   

    const decryptData = () => {
        const userTypeEnc = localStorage.getItem('usertype');
        const bytes = CryptoJS.AES.decrypt(userTypeEnc, 'admin#123');
        const data = (bytes.toString(CryptoJS.enc.Utf8));
        return JSON.parse(data) === "admin"

    };
    const dateOfHire = useRef(null);
    const [intialValues, setInitialValues] = useState({
        image: "",
        empInfo: {
            employment_information_flag: false,

            name: "",
            employee_type: "",
            last_name: "",
            id: "",
            job_title: "",
            department: "",
            employee_email: "",
            date_of_hire: "",
            profile_status: "active"
        },

        personalInfo: {
            personel_information_flag: false,
            mobile: "",
            email: "",
            gender: "",
            emergency_contact: "",
            blood_group: "",
            date_of_birth: "",
            pan: ""
        },
        addressInfo: {
            address_flag: false,
            country: "",
            city: "",
            location: "",
            post_code: "",
            perm_country: "",
            perm_city: "",
            perm_location: "",
            perm_post_code: "",
        },
        bank_details_flag: false,
        bankDetails: [
            {
                bank: "",
                account: "",
                ifsc: "",
                account_holder: "",
                branch: ""
            },
        ],
        salary: {
            salary_details_flag: false,
            total: ""
        },

        skill: {},
        skills: []

    })



    // console.log(Yup.string().when([],{}),"yup");


    const validationSchema = Yup.object().shape({
        // id: Yup.number().typeError("Job ID should be a number").required("Job ID is required"),
        empInfo: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            id: Yup.string().required("Employee ID is required"),
            last_name: Yup.string().required("Last name is required"),
            employee_type: Yup.string().required("Employee type is required"),
            job_title: Yup.string().required("Job title is required"),
            department: Yup.string().required("Department is required"),
            date_of_hire: Yup.date().typeError("Invalid date of hrie").required("Date of hire is required"),
            employee_email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Email address is required"),
        }),
        personalInfo: Yup.object().shape({
            mobile: Yup.string()
                .matches(/^\+[1-9]\d{1,14}$/, 'Invalid phone number').min(12, "Mobile  is too short").max(16, "Mobile is too long")
                .required('Phone number is required'),
            email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Email address is required"),
            gender: Yup.string().required('Gender is required'),
            emergency_contact: Yup.string()
                .matches(/^\+[1-9]\d{1,14}$/, 'Invalid phone number').min(12, "Mobile  is too short").max(16, "Mobile is too long")
                .required('Phone number is required'),
            blood_group: Yup.string().required('Blood group is required').matches(/^(A|B|AB|O)[+-]$/, 'Invalid blood group'),
            date_of_birth: Yup.date().typeError("Invalid date of birth").required("Date of birth is required"),
            pan: Yup.string().matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/, "Invalid PAN number")
                .required('PAN is required'),
        }),


        addressInfo: Yup.object().shape({

            country: Yup.string().test(
                'conditional-required',
                'Country is required',
                function (value) {
                    const { location, city, post_code, perm_city, perm_post_code, perm_country, perm_location } = this.parent;
                    if (location || city || perm_city || perm_post_code || perm_location || perm_country || post_code) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ),


            city: Yup.string().test(
                'conditional-required',
                'City is required',
                function (value) {
                    const { location, country, post_code, perm_city, perm_post_code, perm_country, perm_location } = this.parent;
                    if (location || country || post_code || perm_city || perm_post_code || perm_location || perm_country) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ),


            location: Yup.string().test(
                'conditional-required',
                'Location is required',
                function (value) {
                    const { city, post_code, country, perm_city, perm_post_code, perm_country, perm_location } = this.parent;
                    if ( post_code || city || country || perm_city || perm_post_code || perm_location || perm_country) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ),
            post_code: Yup.string().test(
                'conditional-required',
                'Post code is required',
                function (value) {
                    const { city, location, country, perm_city, perm_post_code, perm_country, perm_location } = this.parent;
                    if (location || city || country || perm_city || perm_post_code || perm_location || perm_country) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ).matches(/^[0-9]{6,6}$/, 'Invalid post code number'),
            perm_country: Yup.string().test(
                'conditional-required',
                'Permanent country is required',
                function (value) {
                    const { location, city, post_code, country, perm_city, perm_post_code, perm_location } = this.parent;
                    if (location || post_code || city || country || perm_city || perm_post_code || perm_location || post_code) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ),
            perm_city: Yup.string().test(
                'conditional-required',
                'Permanent city is required',
                function (value) {
                    const { city, post_code, country, location, perm_post_code, perm_country, perm_location } = this.parent;
                    if (post_code || city || country || location || perm_post_code || perm_location || perm_country ) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ),
            perm_location: Yup.string().test(
                'conditional-required',
                'Permanent address is required',
                function (value) {
                    const { location, city, post_code, country, perm_city, perm_post_code, perm_country } = this.parent;
                    if (location || post_code || city || country || perm_city || perm_post_code || perm_country ) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ),
            perm_post_code: Yup.string().test(
                'conditional-required',
                'Permanent post code is required',
                function (value) {
                    const { location, city, post_code, country, perm_city, perm_country, perm_location } = this.parent;
                    if (location || post_code || city || country || perm_city || perm_location || perm_country ) {
                        return Yup.string().required().isValidSync(value);
                    }
                    return true;
                }
            ).matches(/^[0-9]{6,6}$/, 'Invalid post code number'),
        }),

        salary: Yup.object().shape({
            total: Yup.number().typeError("Total should be number")
        }),


        bankDetails: Yup.array().of(
            Yup.object().shape({
                bank: Yup.string().test(
                    'conditional-required',
                    'Bank is required',
                    function (value) {
                        const { account_holder, account, ifsc, branch } = this.parent;
                        if (account || account_holder || ifsc || branch) {
                            return Yup.string().required().isValidSync(value);
                        }
                        return true;
                    }
                ),
                account_holder: Yup.string().test(
                    'conditional-required',
                    'Account holder is required',
                    function (value) {
                        const { bank, account, ifsc, branch } = this.parent;
                        if (account || bank || ifsc || branch) {
                            return Yup.string().required().isValidSync(value);
                        }
                        return true;
                    }
                ),
                // account: Yup.number().typeError("Account number must be a number").positive("Account number must be positive").required("Account number is required"),
                account: Yup.string().test(
                    'conditional-required',
                    'Account number is required',
                    function (value) {
                        const { bank, account_holder, ifsc, branch } = this.parent;
                        if (account_holder || bank || ifsc || branch) {
                            return Yup.string().required().isValidSync(value);
                        }
                        return true;
                    }
                ).matches(/^[0-9]{9,18}$/, 'Invalid account number'),
                ifsc: Yup.string().test(
                    'conditional-required',
                    'IFSC is required',
                    function (value) {
                        const { bank, account_holder, account, branch } = this.parent;
                        if (account_holder || bank || account || branch) {
                            return Yup.string().required().isValidSync(value);
                        }
                        return true;
                    }
                ).matches(/^[A-Z]{4}[0][A-Z0-9]{6}$/, 'Invalid IFSC code'),
                branch: Yup.string().test(
                    'conditional-required',
                    'Branch is required',
                    function (value) {
                        const { bank, account_holder, account, branch } = this.parent;
                        if (account_holder || bank || account || branch) {
                            return Yup.string().required().isValidSync(value);
                        }
                        return true;
                    }
                )
            })
        ),


    })



    const submitHandler = async (values, { setSubmitting, setTouched, setErrors }) => {


        setSubmitting(true);


        try {
            const response = await createNewEmployee(values);
            setSubmitting(false)
            enqueueSnackbar("User created successfully", { variant: 'success' })
            navigate('/employees')
        } catch (error) {
            setSubmitting(false)
            enqueueSnackbar(error.data.message, { variant: 'error' })

            let obj = {}
            let touchObj = {};
            if (error?.data?.errors?.official_email || error?.data?.errors?.employee_id) {
                setEmpSection(true);
                obj = {
                    ...obj,
                    empInfo: {
                        employee_email: error?.data?.errors?.official_email,
                        id: error?.data?.errors?.employee_id,
                    }

                }
                touchObj = {
                    ...touchObj, empInfo: {
                        employee_email: error?.data?.errors?.official_email ? true : false,
                        id: error?.data?.errors?.employee_id ? true : false,
                    }
                }
            }
            // if (error?.data?.errors?.employee_id) {
            //     console.log("odfghbf")
            //     setEmpSection(true);
            //     obj = {
            //         ...obj,
            //         empInfo: {
            //             id: error?.data?.errors?.employee_id,
            //         }

            //     }
            //     touchObj = {
            //         ...touchObj, empInfo: {
            //             id: true,
            //         }
            //     }
            // }
            if (error.data.errors.personel_email || error.data.errors.personel_email_invalid) {
                setShowPesonalSection(true);

                obj = {
                    ...obj,
                    personalInfo: {
                        email: error.data.errors.personel_email || error.data.errors.personel_email_invalid,


                    }

                }
                touchObj = {
                    ...touchObj, personalInfo: {
                        email: true,
                    }
                }
            }
            setErrors(obj);
            // setTouched(touchObj);
        }
    }


    let profileImageRef = "";

    const encodeFileBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleProfileImage = async (e, setFieldValue) => {
        let item;


        if (e?.target?.files[0]?.type?.split("/")[0] === "image") {
            item = e.target.files[0];
            const base64 = await encodeFileBase64(item);
            setFieldValue("image", base64);
        }
    }


    const fetchOptions = async () => {

        try {
            const deps = await fetchDepartments();
            const emps = await fetchEmployeeTypes();
            const depOptions = deps?.datas?.map((e) => (
                {
                    label: e.department,
                    value: e._id
                }
            ))
            const empOptions = emps?.datas?.map((e) => (
                {
                    label: e.employee_type,
                    value: e._id
                })
            )
            setDepartments(depOptions)
            setEmployeeTypes(empOptions);
        } catch (error) {
        }
    }


    // const fetchEmpoyeeTypeOptions = async () => {
    //     try {
    //         const response = await fetchEmployeeTypes();
    //         const options = response.map((e) => (
    //             { label: e.employee_type, value: e._id })
    //         )
    //         setEmployeeTypes(options);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }



    const fetchSkillsOptions = async (values) => {
        try {
            const response = await fetchSkills(values);

            const opts = response?.datas?.map(e => ({ value: { value: e._id, label: e.skill }, label: e.skill }));
            setSkills(opts);

        } catch (error) {
            // console.log(error);
        }

    }

    const loadOptions = (values) => {
        fetchSkillsOptions(values)
    }


    const deleteBankDetails = (arr, i) => {
        arr.splice(i, 1)
    }
    const [formKey, setFormKey] = useState(0)



    const fetchSalaryTemplateData = async () => {

        try {
            const response = await fetchSalaryTemplate();
            setSalaryTemplate(response)
        } catch (error) {
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        fetchSkillsOptions();
        fetchSalaryTemplateData();
        fetchOptions();
    }, [])
   
    return (
        <Formik
            key={formKey}
            initialValues={intialValues}
            validationSchema={validationSchema}

            onSubmit={submitHandler}
        >
            {({ isSubmitting, validateForm, handleChange, touched, values, setTouched, setFieldValue, errors, setErrors }) => {
                const removeSkill = (id) => { //remove skill
                    const removedItemArray = values.skills.filter(el => (
                        el.id !== id
                    ))
                    setFieldValue("skills", removedItemArray);
                }





                const addASkill = () => { //Add new Skill
                    if (Object?.keys(values?.skill)?.length == 0) {
                        return;
                    }
                    const object = values?.skills?.find(el => (
                        el.id === values?.skill?.value
                    ))


                    if (object && Object?.keys(object)?.length > 0) {

                        return;
                    } else {
                        const newSkillArr = [...values.skills, { id: values.skill?.value, value: values.skill?.label }]
                        setFieldValue('skills', newSkillArr);
                    }
                }
                setEmpSection((touched?.empInfo && (Object?.keys(touched?.empInfo)?.length > 0 && errors?.empInfo && Object?.keys(errors?.empInfo)?.length > 0)));
                setShowPesonalSection((touched?.personalInfo && (Object?.keys(touched?.personalInfo)?.length > 0 && errors?.personalInfo)));
                setshowAddressInfo((touched?.addressInfo && (Object?.keys(touched?.addressInfo)?.length > 0 && errors?.addressInfo && Object?.keys(errors?.addressInfo)?.length > 0)))
                setShowBankDetail((touched?.bankDetails && ((touched?.bankDetails)?.length > 0 && errors?.bankDetails && (errors?.bankDetails)?.length > 0)))
                // setShowBankDetail((touched?.bankDetails && ()))


                return (
                    <Form>
                        <div className="d-flex flex-column align-items-center">
                            <div className="col-10  m-24 d-flex justify-content-between flex-wrap">
                                <div className="d-flex align-items-center gap-2">
                                    {/* <Link to='Home' className=" s-regular decoration-none text-primary cursor-pointer">Home</Link> */}
                                    {/* <img src={rightArrow} alt="right" /> */}
                                    <Link to="/employees" className="s-regular decoration-none cursor-pointer text-primary">Employee</Link>
                                    <img src={rightArrow} alt="right" />
                                    <span className="s-regular cursor-pointer text-primary">Create</span>
                                </div>


                            </div>
                            {/* <div className="" 
                            onClick={()=>{ 
                                enqueueSnackbar('This is a snackbar message', { variant: 'success' })}}>
                                show snack
                            </div> */}
                            <div className="d-flex col-10 mt-40  justify-content-between align-items-center">
                                <div className="col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                                    <div className="d-flex gap-10 col-12 col-sm-6">
                                        <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" />
                                        <span className="extra-bold">Add new profile</span>

                                    </div>
                                    <div className="d-flex gap-24 col-12 col-sm -6  justify-content-end">
                                        <button onClick={() => {
                                            navigate('/employees')

                                        }} type="button" className="d-flex gap-10 h-44 btn btn-outline-main rounded-10 w-120 gap-10 p-10-16 ">
                                            <img src={close} alt="" />
                                            <span>Cancel</span>
                                        </button>


                                        <button type="submit" onClick={
                                            () => {

                                                setTouched({});

                                            }
                                        }

                                            className="d-flex align-items-center justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">

                                            {isSubmitting ? <ClipLoader /> : <> <img src={save} alt="" />
                                                <span>Save</span>
                                            </>
                                            }
                                        </button>




                                    </div>

                                </div>

                            </div>
                            <div className="col-10 rounded-20 bg-white d-flex flex-column m-24 p-24 ">
                                <div className="accordion d-flex flex-column gap-24" id="accordionCreateEmployee">
                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">
                                        {/* <Formik initialValues={initialEmployeeInfo}  validationSchema={validationSchemaEmployeeInfo}> */}
                                        <h2 onClick={() => { setTouched({ ...touched }) }} className="accordion-header" id="headingZero">
                                            <button className={`accordion-button  border-0 semi-bold collapsed `}

                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                                Employee information
                                            </button>
                                        </h2>

                                        <div id="collapseZero" className={`${showEmpSection ? 'show ' : ' '} accordion-collapse show`} aria-labelledby="headingZero" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body d-flex flex-wrap gap-24">
                                                <div className=' col-12 gap-24 d-flex align-items-center'>

                                                    <div className=" position-relative">
                                                        {values?.image && <img src={closeCircle} className="cursor-pointer position-absolute left-auto right-0" alt="remove" onClick={() => {
                                                            profileImageRef.value = null;
                                                            setFieldValue('image', "");
                                                        }} />}
                                                        <img className='w-100px h-100px rounded-50' src={values?.image ? values.image : profilepic} alt="profiles" />
                                                    </div>

                                                    <div className='d-flex flex-column gap-6'>
                                                        <div onClick={() => profileImageRef.click()} className=" w-50px w-169 border-success rounded-10 p-10 d-flex gap-10 cursor-pointer">

                                                            <img src={gallery} alt="gallery" />
                                                            <span className="d-none  d-md-block">Upload photo</span>

                                                        </div>
                                                        <input type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            hidden={true}
                                                            ref={refParam => profileImageRef = refParam}
                                                            onChange={(e) => {
                                                                handleProfileImage(e, setFieldValue);
                                                            }}

                                                        />
                                                        <div className="text-secondary s-regular-small">Atleast 256x256 PNG or JPG file</div>
                                                    </div>
                                                </div>

                                                <CustomField
                                                    id="empInfo.name"
                                                    name="name"
                                                    placeholder="Add name"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    label="First name"
                                                    touched={touched?.empInfo}
                                                    errors={errors.empInfo}
                                                />
                                                <CustomField
                                                    id="empInfo.last_name"
                                                    name="last_name"
                                                    placeholder="Add last name"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    label="Last name"
                                                    touched={touched?.empInfo}
                                                    errors={errors.empInfo}
                                                />
                                                <CustomField
                                                    id="empInfo.id"
                                                    label="Employee ID"
                                                    name="id"
                                                    placeholder="Add employee id"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.empInfo}
                                                    errors={errors.empInfo}
                                                />
                                                <CustomField
                                                    id="empInfo.job_title"
                                                    label="Job title"
                                                    name="job_title"
                                                    placeholder="Add job title"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.empInfo}
                                                    errors={errors.empInfo}
                                                />

                                                <CustomSelect
                                                    id="empInfo.department"
                                                    values={values.empInfo}
                                                    name="department"
                                                    options={departments}
                                                    placeholder="Select department"
                                                    touchedObj={{ ...touched, empInfo: { department: true } }}
                                                    label="Department"
                                                    touched={touched.empInfo}
                                                    errors={errors.empInfo}
                                                    setTouched={setTouched}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    setFieldValue={setFieldValue}
                                                />

                                                <CustomField
                                                    id="empInfo.employee_email"
                                                    label="Employee email"
                                                    placeholder="Add employee email"
                                                    name="employee_email"
                                                    touched={touched.empInfo} errors={errors.empInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    type="date"
                                                    id="empInfo.date_of_hire"
                                                    name="date_of_hire"
                                                    label="Date of hire"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.empInfo}
                                                    fieldClass={!values?.empInfo?.date_of_hire ? "custom-input-date" : ""}

                                                    errors={errors.empInfo}
                                                />
                                                <CustomSelect
                                                    id="empInfo.employee_type"
                                                    values={values.empInfo}
                                                    name="employee_type"
                                                    options={employeeTypes}
                                                    label="Employee type"
                                                    touched={touched.empInfo}
                                                    placeholder='Select employee type'
                                                    touchedObj={{ ...touched, empInfo: { employee_type: true } }}
                                                    errors={errors.empInfo}
                                                    setTouched={setTouched}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    setFieldValue={setFieldValue}

                                                />
                                                <CustomRadio
                                                    id="empInfo.profile_status"
                                                    label="Profile status"
                                                    name="profile_status"
                                                    setFieldValue={setFieldValue}
                                                    labelAndValues={[{ value: "active", label: "Active" }, { value: "inactive", label: "Inactive" }]}
                                                    values={values?.empInfo}
                                                />


                                            </div>
                                        </div>
                                        {/* </Formik> */}

                                    </div>


                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button semi-bold border-0 semi-bold " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Personal information
                                            </button>
                                        </h2>

                                        <div id="collapseOne" className={`${showPersonalSection ? "show " : " "} accordion-collapse collapse show`} aria-labelledby="headingOne" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body d-flex flex-wrap gap-24">

                                                <CustomField
                                                    id="personalInfo.email"
                                                    label="Email"
                                                    placeholder="Add personal email"
                                                    name="email"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />
                                                <CustomField
                                                    id="personalInfo.mobile"
                                                    label="Mobile"
                                                    placeholder="+911231231234"
                                                    name="mobile"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    id="personalInfo.emergency_contact"
                                                    placeholder="+911231231234"
                                                    label="Emergency contact"
                                                    name="emergency_contact"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />
                                                <CustomSelect
                                                    id="personalInfo.gender"
                                                    values={values.personalInfo}
                                                    name="gender"
                                                    options={genders}
                                                    label="Gender"
                                                    touched={touched?.personalInfo}
                                                    placeholder="Select gender"
                                                    touchedObj={{ ...touched, personalInfo: { ...touched.personalInfo, gender: true } }}
                                                    errors={errors.personalInfo}
                                                    setTouched={setTouched}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    setFieldValue={setFieldValue}

                                                />
                                                <CustomField
                                                    id="personalInfo.blood_group"
                                                    label="Blood group"
                                                    placeholder="Add blood group"
                                                    name="blood_group"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />
                                                <CustomField
                                                    label="Date of birth"
                                                    id="personalInfo.date_of_birth"
                                                    ref={dateOfHire}
                                                    // onKeyDown={() => { return false }}
                                                    name="date_of_birth"
                                                    fieldClass={`${values.personalInfo.date_of_birth ? "" : " custom-input-date"} `}
                                                    // placeholder="Add blood group"
                                                    type="date"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.personalInfo}
                                                    errors={errors.personalInfo}
                                                    max={new Date().toISOString().split('T')[0]}
                                                />

                                                {/* <div className="d-flex col-12 col-md-5 col-lg-3 gap-6 flex-column">
                                                    <label className="text-secondary s-regular-small">Date of birth</label>
                                                    <div onClick={()=>{console.log(dateOfHire.current.setOpen(true));}} className={`${(touched?.personalInfo?.date_of_birth && errors?.personalInfo?.date_of_birth) ? 'border-danger':'input-border-primary '} height-50px form-control shadow-none rounded-10 cursor-pointer d-flex justify-content-between`}>
                                                        <DatePicker
                                                            id="personalInfo.date_of_birth"
                                                            ref={dateOfHire}
                                                            name="personalInfo.date_of_birth"
                                                            selected={values.personalInfo.date_of_hire}
                                                            onChange={(date) => {
                                                                setSelectedDateOfHire(date)
                                                                console.log(date, "date");
                                                                setFieldValue("personalInfo.date_of_birth", date)
                                                                // setFieldValue()
                                                            }}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="border-none outline-0 cursor-pointer d-none"
                                                            shouldCloseOnSelect={true}

                                                        />
                                                        <div className="d-flex justify-content-start align-items-center col-10 ">{values?.personalInfo?.date_of_birth ? 'yyyy-mm-dd': values?.personalInfo.date_of_birth}</div>
                                                        <img src={calendar} alt="" />
                                                    </div>
                                                    <ErrorMessage name="personelInfo.date_of_birth" component="div" />

                                                </div> */}
                                                
                                                {/* <div className="d-flex col-12 col-md-5 col-lg-3 gap-6 flex-column">
                                                    <label className="text-secondary s-regular-small">Date of birth</label>
                                                    <DatePicker
                                                        showIcon
                                                        id="personalInfo.date_of_birth"
                                                        name="personalInfo.date_of_birth"
                                                        selected={values.personalInfo.date_of_birth}
                                                        onChange={(date) => {
                                                            setSelectedDateOfHire(date)
                                                            console.log(date, "date");
                                                            setFieldValue("personalInfo.date_of_birth", date)
                                                            // setFieldValue()
                                                        }}
                                                        dateFormat="yyyy-MM-dd"
                                                        placeholderText="yyyy-mm-dd"
                                                        className={`height-50px form-control shadow-none rounded-10 cursor-pointer d-flex justify-content-between`}
                                                        shouldCloseOnSelect={true}
                                                        customInput={
                                                            <>
                                                            <input />
                                                            </>
                                                        }
                                                    />


                                                    <ErrorMessage name="personelInfo.date_of_birth" component="div" />

                                                </div> */}

                                                <CustomField
                                                    id="personalInfo.pan"
                                                    label="PAN"
                                                    placeholder="Add PAN"
                                                    name="pan"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">

                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button semi-bold border-0 semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Address
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className={`accordion-collapse collapse ${showAddressInfo && "show "} show`} aria-labelledby="headingTwo" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body">
                                                <div className="m-24 col-12">
                                                    <span className=" s-regular-semi-bold">Current address</span>
                                                    <div className="d-flex gap-md-24  mt-0  flex-wrap">


                                                        <CustomField
                                                            id="addressInfo.location"
                                                            as="textarea"
                                                            placeholder="Add address"
                                                            label="Address"
                                                            fieldClass="height-200"
                                                            name="location"
                                                            rows="6"
                                                            touched={touched.addressInfo}
                                                            errors={errors?.addressInfo}
                                                            className=" col-lg-3 col-md-5 col-12 m-24"
                                                        />


                                                        <div className=" col-12 col-md-5 col-lg-3  justify-content-between m-24">

                                                            <CustomField
                                                                label="Country"
                                                                id="addressInfo.country"
                                                                placeholder="Add country"
                                                                name="country"
                                                                touched={touched.addressInfo}
                                                                errors={errors.addressInfo}
                                                            />
                                                            <CustomField
                                                                label="City/State"
                                                                placeholder="Add state"
                                                                id="addressInfo.city"
                                                                name="city"
                                                                touched={touched.addressInfo}
                                                                className="m-24"
                                                                errors={errors.addressInfo}
                                                            />
                                                        </div>

                                                        <CustomField
                                                            label="Post code"
                                                            id="addressInfo.post_code"
                                                            name="post_code"
                                                            placeholder="Add post code"
                                                            touched={touched.addressInfo}
                                                            className="col-lg-3 col-md-5 col-12 m-24"
                                                            errors={errors.addressInfo}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="m-24 col-12 ">
                                                    <div className="col-12 d-flex align-items-md-center flex-md-row flex-column gap-24">
                                                        <span className=" s-regular-semi-bold">Permanent address</span>
                                                        <div className="d-flex gap-10">

                                                            <input
                                                                onChange={(e) => {
                                                                    if (!e.target.checked) {
                                                                        setFieldValue("addressInfo.perm_location", "")
                                                                        setFieldValue("addressInfo.perm_city", "")
                                                                        setFieldValue("addressInfo.perm_country", "")
                                                                        setFieldValue("addressInfo.perm_post_code", "")
                                                                        return;
                                                                    }
                                                                    setFieldValue("addressInfo.perm_location", values.addressInfo.location)
                                                                    setFieldValue("addressInfo.perm_city", values.addressInfo.city)
                                                                    setFieldValue("addressInfo.perm_country", values.addressInfo.country)
                                                                    setFieldValue("addressInfo.perm_post_code", values.addressInfo.post_code)
                                                                    setTouched({});
                                                                }}
                                                                className="border-primary checkbox rounded-6 cursor-pointer" type="checkbox" />
                                                            <span className="text-secondary">Same as the current address</span>
                                                        </div>
                                                    </div>
                                                    <div className=" d-flex gap-md-24 mt-0 d-flex flex-column flex-md-row gap-6 flex-wrap">


                                                        <CustomField
                                                            id="addressInfo.perm_location"
                                                            as="textarea"
                                                            label="Address"
                                                            fieldClass="height-200"
                                                            placeholder="Add address"
                                                            name="perm_location"
                                                            rows="6"
                                                            touched={touched.addressInfo}
                                                            errors={errors?.addressInfo}
                                                            className=" col-lg-3 col-md-5 col-12 m-24"
                                                        />
                                                        <div className="d-flex flex-column col-12 col-md-5 col-lg-3 justify-content-between m-24">

                                                            <CustomField
                                                                label="Country"
                                                                id="addressInfo.perm_country"
                                                                name="perm_country"
                                                                placeholder="Add country"
                                                                touched={touched.addressInfo}
                                                                errors={errors.addressInfo}
                                                            />
                                                            <CustomField
                                                                label="City/State"
                                                                id="addressInfo.perm_city"
                                                                name="perm_city"
                                                                placeholder="Add state"
                                                                touched={touched.addressInfo}
                                                                className="m-24"
                                                                errors={errors.addressInfo}
                                                            />
                                                        </div>
                                                        <CustomField
                                                            label="Post code"
                                                            id="addressInfo.perm_post_code"
                                                            name="perm_post_code"
                                                            placeholder="Add post code"
                                                            touched={touched.addressInfo}
                                                            className="col-lg-3 col-md-5 col-12 m-24"
                                                            errors={errors.addressInfo}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button semi-bold border-0 semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Bank details
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className={`accordion-collapse collapse ${showBankDetatils && "show "} show`} aria-labelledby="headingThree" data-bs-parent="#accordionCreateEmployee">
                                            <div className="m-24 col-12 d-flex align-items-center mb-24 justify-content-between">
                                                <span className="s-bold-main">Add account details</span>
                                                <button
                                                    onClick={(e) => {

                                                        values.bankDetails?.push({
                                                            bank: "",
                                                            account: "",
                                                            ifsc: "",
                                                            account_holder: "",
                                                            branch: ""
                                                        })


                                                        setFieldValue("bankDetails", values.bankDetails)
                                                    }
                                                    }
                                                    type="button"

                                                    className="cursor-pointer border-none btn-secondary rounded-10 d-flex gap-10 p-10-16 d-flex gap-6">
                                                    <img src={veusax} alt="" />
                                                    <span className="text-add">Add account</span>
                                                </button>
                                            </div>
                                            <div className="accordion-body d-flex flex-wrap gap-24">
                                                <FieldArray name="bankDetails">
                                                    <>
                                                        {
                                                            values.bankDetails.length > 0 ?
                                                                values.bankDetails.map((e, i) => (
                                                                    <div className={`col-12 d-flex gap-24 flex-wrap align-items-center border-bottom-secondary pb-4`}>


                                                                        <CustomField
                                                                            id={`bankDetails[${i}].bank`}
                                                                            name="bank"
                                                                            label="Bank"
                                                                            placeholder="Add bank name"
                                                                            touched={touched.bankDetails?.[i]}
                                                                            className="col-lg-3 col-md-5 col-12"
                                                                            errors={errors.bankDetails?.[i]}
                                                                        />
                                                                        <CustomField
                                                                            id={`bankDetails[${i}].account`}
                                                                            name="account"
                                                                            placeholder="Add account number"
                                                                            label="Account number"
                                                                            touched={touched.bankDetails?.[i]}
                                                                            className="col-lg-3 col-md-5 col-12"
                                                                            errors={errors.bankDetails?.[i]}
                                                                        />

                                                                        <CustomField
                                                                            id={`bankDetails[${i}].account_holder`}
                                                                            name="account_holder"
                                                                            label="Account holder"
                                                                            placeholder="Add account holder"
                                                                            touched={touched.bankDetails?.[i]}
                                                                            className="col-lg-3 col-md-5 col-12"
                                                                            errors={errors.bankDetails?.[i]}
                                                                        />

                                                                        <div className="m-24 col-md-1 col-lg-2 justify-content-end d-sm-none d-md-flex ">
                                                                            <img className="cursor-pointer" onClick={() => {
                                                                                deleteBankDetails(values.bankDetails, i)
                                                                                setFieldValue('bankDetails', values.bankDetails);
                                                                            }
                                                                            } src={trash} alt="" />
                                                                        </div>
                                                                        <CustomField
                                                                            id={`bankDetails[${i}].ifsc`}
                                                                            name="ifsc"
                                                                            label="IFSC"
                                                                            placeholder="Add ifsc"
                                                                            touched={touched.bankDetails?.[i]}
                                                                            className="col-lg-3 col-md-5 col-12"
                                                                            errors={errors.bankDetails?.[i]}
                                                                        />
                                                                        <CustomField
                                                                            id={`bankDetails[${i}].branch`}
                                                                            name="branch"
                                                                            placeholder="Add Branch name"
                                                                            label="Branch name"
                                                                            touched={touched.bankDetails?.[i]}
                                                                            className="col-lg-3 col-md-5 col-12"
                                                                            errors={errors.bankDetails?.[i]}
                                                                        />
                                                                        <div className="m-24 d-sm-none">
                                                                            <img className="cursor-pointer" onClick={() => {
                                                                                deleteBankDetails(values.bankDetails, i)
                                                                                setFieldValue('bankDetails', values.bankDetails);
                                                                            }
                                                                            } src={trash} alt="" />
                                                                        </div>


                                                                    </div>
                                                                )) :
                                                                <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="bankDetails" component="div" />


                                                        }

                                                    </>
                                                </FieldArray>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-24 accordion-item border-secondary p-24">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button semi-bold border-0 semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                Salary details
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionCreateEmployee">
                                            {/* <FieldArray name="salary"> */}
                                            <div className="accordion-body col-12">


                                            
                                                <CustomField
                                                    label="Total"
                                                    id="salary.total"
                                                    placeholder="total salary"
                                                    name="total"
                                                    touched={touched.salary}
                                                    className="col-lg-3 col-md-5 col-12 m-24"
                                                    errors={errors.salary}
                                                />

                                            </div>
                                            {
                                                <div className="d-flex flex-wrap">
                                                    {
                                                        salaryTemplate?.[0]?.salary_template.map(e => {
                                                            return <div className='col-lg-3 m-24  col-6 d-flex flex-column'>
                                                                <span className='s-regular-small text-secondary'>
                                                                    {e.label}
                                                                </span>
                                                                <span className="d-flex">
                                                                    <img className="w-25px h-25px" src={currency} alt="" srcset="" />
                                                                    <div>{(Number(values.salary.total) * (Number(e.percentage) / 100)).toLocaleString('en-IN')}</div>

                                                                </span>

                                                            </div>

                                                        })
                                                    }
                                                </div>

                                            }
                                            {/* {console.log(salaryTemplate[0].salary_template,"temp")} */}
                                            {/* </FieldArray> */}
                                            {/* <div className="m-24 col-12 d-flex justify-content-end">
                                                <button onClick={() => { values.salary?.push({ label: "", amount: "" }) }} className="cursor-pointer btn-secondary rounded-10 d-flex gap-10 p-10-16 border-none">
                                                    <img src={veusax} alt="" />
                                                    <span className="text-add">Add</span>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button  semi-bold border-0 semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                Skills
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body d-flex flex-wrap  col-12 gap-10">
                                                <div className="col-12    gap-24 d-flex align-items-end ">
                                                    {/* <div className="col-8 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                        <label className="text-secondary s-regular-small" htmlFor="skill">Skills and capacity</label>
                                                        <Field
                                                            name="skill"
                                                            onChange={(e)=>{
                                                                handleChange(e) 
                                                                fetchSkillsOptions(e.target.value)
                                                                console.log(e.target.value)}}
                                                            className={`${touched.skill && errors.skill ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10 `}

                                                        />
                                                        <ul className="sc">
                                                            {
                                                                skills.map(e=>{
                                                                   return <li>{e.label}</li>
                                                                })
                                                            }
                                                        </ul>
                                                       

                                                    </div> */}

                                                    <CustomSelect
                                                        id="skill"
                                                        name="skill"
                                                        loadOptions={loadOptions}
                                                        values={values}
                                                        label="Skill"
                                                        component={{ DropdownIndicator: () => null }}
                                                        // menuIsOpen={skills?.length>0 ? true : false}
                                                        placeholder="Add skill"
                                                        options={skills}
                                                        touchedObj={{ ...touched, skill: true }}
                                                        touched={touched}
                                                        errors={errors}
                                                        setTouched={setTouched}
                                                        className="col-12 col-md-5 col-lg-3"
                                                        setFieldValue={setFieldValue}
                                                    />
                                                    <button onClick={addASkill} type="button" className="btn-primary height-50px h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                                                        <img src={add} alt="" />
                                                        <span className="d-none d-md-block">Add</span>
                                                    </button>
                                                </div>

                                                <div className="d-flex flex-wrap m-24 col-12 gap-10">
                                                    {
                                                        values.skills.map(el => (
                                                            <div className="border-secondary col-3 rounded-10 p-10 d-flex justify-content-between gap-10">
                                                                <span>{el.value}</span>
                                                                <img className="cursor-pointer"
                                                                    onClick={() => {
                                                                        removeSkill(el.id)
                                                                    }}
                                                                    src={trash} alt="" />
                                                            </div>
                                                        ))


                                                    }
                                                </div>
                                                <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="skills" component="div" />



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </Form>
                )
            }
            }
        </Formik>
    )
}
export default NewEmployee;