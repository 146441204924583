// import {Search} from '../../assets/icons/search.svg';
import { React, useContext, useState, useCallback } from 'react';
import Search from '../../components/search';
import { PaginationContext } from "../../context/paginationContext";
import { GlobalContext } from "../../context/globalContext";
import List from '../../containers/list';
import { useNavigate } from 'react-router-dom';
import add from '../../assets/icons/add.svg'
import { useEffect } from 'react';
import edit from '../../assets/icons/edit.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import PaginationComponent from '../../components/pagination';


import { fetchAllOrganizations } from '../../utility/services/organizationServices';
const Organisation = () => {
    const [searchKey, setSearchKey] = useState("");
    const [pageCount, setPageCount] = useState("");
    const [loading, setLoading] = useState(false);
    const [organizations, setOrganizations] = useState();
    const [orgData, setOrgData] = useState(
        [
            {
                id: 1,
                data1: "hai", data2: 34,
                cardTitle: "Hellogy", cardId: 44
            }]
    );
    const navigate = useNavigate();
    const btns = ["Edit"];
    const titles = ["Name", "Industry", "Website"]
    const routes = { view: '/organization', edit: '/organization/edit' }

    const {
        organizationCurrPage,
        setOrganisationCurrPage,
        organizationStart,
        setOrganisationStart,
        organizationEnd,
        setOrganisationEnd } = useContext(PaginationContext);

    const fetchOrganizations = async (values) => {
        if (values) setOrganisationCurrPage(values);
        setLoading(true);
        try {
            let response = await fetchAllOrganizations(searchKey, organizationCurrPage, 5);
            setOrganizations(response?.datas);
            setPageCount(response.totalPages)
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }

    }

    const onPageChange = useCallback((page) => {

        setOrganisationCurrPage(page)

    }, [])

    useEffect(() => {
        fetchOrganizations(1);
    }, []);

    useEffect(() => {
        fetchOrganizations();
    }, [searchKey, organizationCurrPage]);
    return (
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-10">
                    <span className="s-regular cursor-pointer text-primary">Organisation</span>
                </div>

                <Search setData={setSearchKey} />

            </div>

            <div className="d-flex col-10 justify-content-between align-items-center mb-24 mt-40 bg-">
                <div className="d-flex col-12  col-lg-6 gap-24 align-items-center">
                    <span className="extra-bold">Organisations</span>
                    <button
                            onClick={() => {
                                navigate('/organization/new')
                            }}
                            type="submit" className="btn-primary h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                            <img src={add} alt="" />
                            <span className="d-none d-sm-block">New Organisation</span>
                        </button>
                </div>
            </div>


            <div className="col-10 row bg-white rounded-20 p-24 d-flex justify-content-between d-flex">
                {loading ? 
                    <div className='col-12 d-flex justify-content-center height-50px align-items-center'>
                        <ClipLoader />
                    </div>
                :   <div className='col-12'>
                        <div className='d-flex col-12 border-bottom-success p-12-0'>
                            <div className="col-5 s-regular-small text-secondary ">
                                Name
                            </div>
                            <div className="col-3 s-regular-small text-secondary ">
                                Website
                            </div>
                            <div className="col-3 s-regular-small text-secondary ">
                                Industry
                            </div>
                        </div>
                        {organizations && organizations.map((e, index) => (
                                <div className='col-12 d-flex p-12-0 border-bottom-success' key={index}>
                                    <div className="col-5 s-regular-semi">
                                        {e?.name}
                                    </div>
                                    <div className="col-3 s-regular-semi">
                                        {e?.website}
                                    </div>
                                    <div className="col-3 s-regular-semi">
                                        {e?.industry}
                                    </div>
                                    <div className="d-flex justify-content-end col-1 gap-4">
                                        <div 
                                        onClick={() => {
                                            navigate(`/organization/edit/${e?._id}`)
                                            }} className="d-flex gap-2 align-items-center cursor-pointer" title="edit"
                                        >

                                            <img src={edit} alt="edit" />
                                            <span className='text-danger s-regular  d-none d-lg-block '>Edit</span >

                                        </div>
                                
                                    </div>
                                </div>
                                
                        ))}
                        {organizations?.length===0 &&
                            <div className={"d-flex justify-content-center text-secondary mt-3"}>No datas available</div>
                        }

                    </div>
                }
                 {organizationCurrPage && 
                    <PaginationComponent
                        id={"available"}
                        pageCount={pageCount}
                        start={organizationStart}
                        end={organizationEnd}
                        setStart={setOrganisationStart}
                        setEnd={setOrganisationEnd}
                        currentPage={organizationCurrPage}
                        setCurrentPage={setOrganisationCurrPage}
                        onPageChange={onPageChange}
                    />
                }
            </div>
        </div>
    )
}

export default Organisation;