import axios from "axios"


export const fetchDepartments=()=>{
    return new Promise((resolve, reject) => {
        
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/departments`,
            
           
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}