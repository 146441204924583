import axios from "axios"

export const fetchAllUserDocumentTypes=()=>{
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/documents/users/types`,
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const uploadUserDocument=(data)=>{
    return new Promise((resolve, reject) => {
        axios({
            method: 'PATCH',
            url: `${process.env.REACT_APP_BASE_URL}/documents/users/upload`,
            data: data
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const removeUserDocument=(data)=>{
    return new Promise((resolve, reject) => {
        axios({
            method: 'PATCH',
            url: `${process.env.REACT_APP_BASE_URL}/documents/users/remove`,
            data: data
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const fetchAllSystemDocumentTypes=()=>{
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/documents/system/types`,
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const generateSystemDocument=(id)=>{
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/documents/system/generate/${id}`,
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}
