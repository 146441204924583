import logo from './logo.svg';
import './App.css';
import { Routes, Route, Navigate, useNavigate, json } from "react-router-dom";
import Login from './pages/authentication/login';
import AxiosInterceptor from './utility/axiosInterceptor';
import ProtectedRoutes from './utility/protectedRoute';
import EmployeeList from './pages/employee/employeelist';
import { GlobalContext } from './context/globalContext';
import { useContext, useEffect, useState } from 'react';
import Header from './containers/header';
import EmployeeProfile from './pages/employee/employeeprofile';
import NewEmployee from './pages/employee/newemployee';
import SalaryList from './pages/salary/salarylist';
import SalaryDetail from './pages/salary/salarydetail';
import Footer from './containers/footer';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Alert from 'react-bootstrap/Alert';
import Page404 from './pages/error_page/page404';
import ZohoSigninRedirect from './pages/authentication/zohoSigninRedirect';
import SkillList from './pages/skills/skillList';
import SalaryTemplateList from './pages/salary_template/salaryTemplateList';
import DepartmentList from './pages/department/departmentList';
import EmployeeTypeList from './pages/employment_type/employmentTypeList';
import ExternalServicesList from './pages/external-services/externalServicesList';
import Admin from './pages/admin/organisationList';
import Organisation from './pages/admin/organisationList';
import CryptoJS from "crypto-js";
import Welcome from './pages/dashbord.jsx/welcome';
// import AdminRoute from './utility/services/organisation/adminRoute';
import OrgRoute from './utility/services/organisation/orgRoute';
import CreateOrganisation from './pages/admin/createOrganisation';
import CreateEditSalaryTemplate from './pages/salary_template/createEditSalaryTemplates';
function App() {
  AxiosInterceptor();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { showHeader, setShowHeader } = useContext(GlobalContext);
  const [showFooter, setShowFooter] = useState(true);
  const [userType, setUserType] = useState();
  const [navigatePage, setNavigatePage] = useState("");


  const decryptData = async () => {
    const userTypeEnc = localStorage.getItem('usertype');
    if (!userTypeEnc) {
      return;
    }
    const bytes = CryptoJS.AES.decrypt(userTypeEnc, 'admin#123');
    const data = (bytes.toString(CryptoJS.enc.Utf8));
    if (data) {
      setUserType(JSON.parse(data))
    } else {
      setUserType("")
    }

  };

  useEffect(() => {

    decryptData()
    if (!token) {
      setUserType("");
    }
    setNavigatePage("/");
    if (token && userType) {
      if (token && userType === 'admin') {
        setNavigatePage("/organisation")
      }
      else if (token && userType === 'organization') {
        setNavigatePage("/employees")
      }
      else {
        setNavigatePage("/welcome")
      }
    }

  })

  const snackbarOptions = {
    anchorOrigin: {
      vertical: 'top', // 'top', 'bottom'
      horizontal: 'right', // 'left', 'center', 'right'
    },
  }



  return (
    <SnackbarProvider maxSnack={3} {...snackbarOptions}>
      <div className="App">

        {showHeader && <Header />}
        <Routes>
          {/* <Route exact path="/login" element={<Login />} /> */}
          <Route exact path="/login/zoho" element={<ZohoSigninRedirect />} />
          <Route exact path="/login" element={
            <Login />
          } />
          <Route path="/" element={
            !token ? <Navigate to="/login" />
              : <Navigate to={navigatePage} />
          }
          />
          {/* 
          <Route path="/" element={
            (token && userType === 'organization') &&  <Navigate to="/employees" />
          }
          /> */}

          <Route path="/" element={
            token && <Navigate to="/organisation" />
          }
          />

          {/* <Route path="/" element={
            token && !userType &&  <Navigate to="/welcome" />
          }
          /> */}

          <Route exact path="/employees"
            element={

              <OrgRoute>
                <EmployeeList />
              </OrgRoute>

            } />




          <Route exact path='/employee/:id'
            element={
              <OrgRoute>
                <EmployeeProfile />
              </OrgRoute>
            }
          />


          <Route exact path='/employee/edit/:id'
            element={
              <OrgRoute>
                <EmployeeProfile />
              </OrgRoute>
            }
          />

          <Route exact path='/employee/new'
            element={
              <OrgRoute>
                <NewEmployee />
              </OrgRoute>
            }
          />
          <Route exact path='/salary/list'
            element={
              <OrgRoute>
                <SalaryList />
              </OrgRoute>
            }
          />

          <Route exact path='/salary/:id'
            element={
              <OrgRoute>
                <SalaryDetail />
              </OrgRoute>
            }
          />
          <Route exact path='/skills/list'
            element={
              <OrgRoute>
                <SkillList />
              </OrgRoute>
            }
          />
          <Route exact path='/departments/list'
            element={
              <OrgRoute>
                <DepartmentList />
              </OrgRoute>
            }
          />
          <Route exact path='/employment-types/list'
            element={
              <OrgRoute>
                <EmployeeTypeList />
              </OrgRoute>
            }
          />
          <Route exact path='/external-services/list'
            element={
              <OrgRoute>
                <ExternalServicesList />
              </OrgRoute>
            }
          />

          <Route exact path='/templates/list'
            element={
              <OrgRoute>
                <SalaryTemplateList />
              </OrgRoute>
            }
          />

          <Route exact path='/templates/edit/:id'
            element={
              <OrgRoute>
                <CreateEditSalaryTemplate />
              </OrgRoute>
            }
          />

          <Route exact path='/templates/create'
            element={
              <OrgRoute>
                <CreateEditSalaryTemplate />
              </OrgRoute>
            }
          />

          <Route exact path='/organisation'
            element={
              // <ProtectedRoutes>
              <Organisation />
              // </ProtectedRoutes>
            }
          />

          <Route exact path='/organization/new'
            element={
              <CreateOrganisation />
            }
          />

          <Route exact path='/organization/edit/:id'
            element={
              <CreateOrganisation />
            }
          />

          <Route exact path='/welcome'
            element={
              <ProtectedRoutes>
                <Welcome />
              </ProtectedRoutes>
            }
          />
          <Route exact path='*'
            element={
              !token ?
                <Navigate to="/login" />
                :
                <Page404 setShowHeader={setShowHeader} setShowFooter={setShowFooter} />
            }
          />

        </Routes>
        {showFooter && <Footer />}
      </div>
    </SnackbarProvider>
  );
}

export default App;
