import { Modal } from 'react-bootstrap'

const ModalPopup = ({ show, size,className, onHide,bodyClassName2, centered, body1, bodyClassName1, body2 }) => {

    return (
        <Modal show={show} size={size} onHide={onHide}  centered={centered}>
          <div className={className}>
          <Modal.Body className={bodyClassName1}>
                {body1}
            </Modal.Body>
            <Modal.Body className={bodyClassName2}>
                {body2}
            </Modal.Body> 
          </div>
            
        </Modal>
    )
}

export default ModalPopup;