import { Formik, Form, Field, ErrorMessage } from "formik"
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import loginIcon from '../../assets/icons/logo.svg'
import { Link, Navigate } from "react-router-dom";
import { login } from "../../utility/services/service";
import ClipLoader from "react-spinners/ClipLoader"
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";
import axios from 'axios';
import queryString from 'query-string';

import CryptoJS from "crypto-js";
const Login = () => {
    const [loading, setLoading] = useState(true);
 
    const { showHeader, setShowHeader } = useContext(GlobalContext)
    const [credentialError, setCredentialError] = useState();
    const [isChanging,setIsChanging]=useState(false);
    const navigate = useNavigate();
    const encryptData= (text)=>{
        if(!text){
            return;
        }
        const adminData= CryptoJS?.AES?.encrypt(
            JSON?.stringify(text),
            "admin#123"
          ).toString();
        
        localStorage.setItem('usertype',adminData);
    }

    const initialValues = {
        email: "",
        password: "",
        rememberme: false
    }
    useEffect(() => {
        setShowHeader(false);
        return () => {
            setShowHeader(true);
        }
    }, [])

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Email address is required"),
        password: Yup.string().required("Password is required")
    })
    const submitHandler = async (values, { setSubmitting }) => {
        setLoading(true);

        try {
            // localStorage.setItem("token","");
            localStorage.removeItem("token")
            const loginData = await login(values);
            
            setLoading(false);
            setSubmitting(false);
            encryptData(loginData.user_type)
            // encryptData("user")
            localStorage.setItem('token', loginData.data);
            
                navigate('/',{replace:true})
            

            

        } catch (error) {
           
            setCredentialError(error.data.message)
            setLoading(false);
            setSubmitting(false);
        }   

    }

    const handleSignIn = () => {
        const clientId = '1000.MM57XVHMDZ3RHAM97PW2ROGPT1OPDM';
        const redirectUri = 'http://localhost:3000/login/zoho';
    
        const queryParams = queryString.stringify({
          client_id: clientId,
          redirect_uri: redirectUri,
          response_type: 'code',
          scope: 'ZohoMail.accounts.READ' // Specify the desired scope
        });
    
        const authUrl = `https://accounts.zoho.com/oauth/v2/auth?${queryParams}`;
        window.location.href = authUrl;
      };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
        >
            {({ isSubmitting,handleChange,errors,touched }) => (
                <Form>
                    <div className=" h-100 d-flex justify-content-center">

                        <div className=" col-md-3 col-lg-6  d-none d-md-flex justify-content-center">
                            <div className="d-flex h-100 flex-column justify-content-between align-items-center">
                                <div className="w-1px login-gradient-tb h-35">

                                </div>
                                <div className="d-flex">

                                    <img src={loginIcon} alt="" />

                                </div>
                                <div className="w-1px login-gradient-bt h-35">

                                </div>

                            </div>
                            <div className="d-flex align-items-center px-1">
                                <span className="s-regular-large text-primary">HRM</span>
                            </div>
                        </div>
                        <div className=" col-11 col-md-7 col-lg-6 d-flex justify-content-md-start justify-content-center align-items-center">
                            <div className="p-40  d-flex bg-white rounded-20  flex-column justify-content-between gap-4 col-xl-7 col-md-9 col-11">

                                <div className="d-flex flex-column">
                                    <span className="extra-bold text-primary text-primary">Sign in to your account</span>
                                    {/* <div className="d-flex gap-10 text-dark"> <span className="s-regular text-secondary">Or</span> <Link to='/' className="text-dark cursor-pointer text-primary decoration-none border-bottom-primary  s-regular"> Create a new account</Link></div> */}
                                </div>

                                <div className="d-flex flex-column gap-6">
                                    <label className="text-secondary s-regular-small" htmlFor="email">Email address</label>
                                    <Field
                                        name="email"
                                        onChange={(e)=>{
                                            handleChange(e);
                                            setCredentialError("");
                                        }}
                                        className={`rounded height-50px input-border-primary ${(errors['email'] && touched['email']) ? 'border-danger ':" " } rounded-10 form-control shadow-none`}

                                    />
                                    <ErrorMessage className="error-msg s-regular-small" name="email" component="div" />
                                </div>
                                <div className="d-flex flex-column gap-6">
                                    <label className="text-secondary s-regular-small" htmlFor="password">Password</label>
                                    <Field
                                        name="password"
                                        type="password"
                                        onChange={(e)=>{
                                            handleChange(e);
                                            setCredentialError("");
                                        }}
                                        className={`rounded height-50px input-border-primary ${(errors['password'] && touched['password']) ? 'border-danger ':" " } rounded-10 form-control shadow-none`}

                                    />
                                    <ErrorMessage className="error-msg s-regular-small" name="password" component="div" />


                                </div>
                                {credentialError && <p className="text-danger d-flex justify-content-center">{credentialError}</p>}
                                <div className="d-flex justify-content-between">
                                    {/* <div className="col-6 col-xl-5 d-flex gap-1">
                                        <Field
                                            name="rememberme"
                                            type="checkbox"
                                        />
                                        <span className="s-regular text-primary">Remember me</span>
                                    </div> */}

                                    {/* <Link to='/' className="decoration-none text-primary">Forgot your password?</Link > */}
                                </div>
                                <button type="submit" disabled={isSubmitting} className=" btn-primary border-0 rounded justify-content-center d-flex align-items-center height-42px s-regular">{
                                    isSubmitting ? <ClipLoader /> : 'Sign in'
                                }</button>

                                {/* <div className="text-center">OR</div>
                                <Link onClick={handleSignIn} className="decoration-none text-primary text-center">Sign In with Zoho</Link> */}
                            </div>

                        </div>
                    </div>

                </Form>
            )


            }

        </Formik>
    )
}

export default Login;