import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from 'yup';
import rightArrow from '../../assets/icons/rightArrow.svg';
import left from '../../assets/icons/left.svg';
import edit from '../../assets/icons/editdark.svg'
import search from '../../assets/icons/search.svg'
import attendance from '../../assets/icons/attendance.svg'
import payment from '../../assets/icons/payment.svg'
import gallery from '../../assets/icons/gallery.svg'
import upload from '../../assets/icons/document_upload.svg'
import download from '../../assets/icons/document_download.svg'
import close from '../../assets/icons/close.svg'
import save from '../../assets/icons/save.svg'
import closeCircle from '../../assets/icons/closecircle.svg'
import trash from '../../assets/icons/trash.svg'
import currency from '../../assets/icons/currency.svg'
import DataContainer from "../../containers/datacontainer";
import ClipLoader from "react-spinners/ClipLoader"
import Select from "react-select"
import add from '../../assets/icons/add.svg'
import veusax from '../../assets/icons/veusax.svg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import profilepic from '../../assets/icons/profilePic.svg';
import { useContext, useEffect, useState } from "react";
import { PaginationContext } from "../../context/paginationContext";
import { fetchSingleEmployeeData } from "../../utility/services/fetchSingleData";
import { fetchDepartments } from "../../utility/services/fetchDepartment";
import { employeeEdit } from "../../utility/services/editEmployee";
import CustomField from "../../components/customField";
import CustomSelect from "../../components/customSelect";
import { fetchEmployeeTypes } from "../../utility/services/fetchEmployeeTypes";
import { useSnackbar } from "notistack";
import { fetchSkills } from "../../utility/services/fetchSkills";
import { fetchSalaryTemplate } from "../../utility/services/fetchSalaryTemplate";
import CustomRadio from "../../components/customRadio";
import { fetchAllSystemDocumentTypes, fetchAllUserDocumentTypes, generateSystemDocument, removeUserDocument, uploadUserDocument } from "../../utility/services/documentsServices";
import DeleteConfirmModal from '../../components/deleteConfirmModal';

const EmployeeProfile = () => {
    let profileImageRef;
    let documentRef;
    const location = useLocation();
    const [salaryTemplate, setSalaryTemplate] = useState([])
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [employeeInfoClicked, setEmployeeInfoClicked] = useState(location.pathname === `/employee/edit/${id}`);
    const [loading, setLoading] = useState();
    const [personalinfoClicked, setPersonalinfoClicked] = useState(location.pathname === `/employee/edit/${id}`);
    const genders = [{ label: "Male", value: 'Male' }, { label: "Female", value: 'Female' }, { label: "Other", value: "other" }]
    const [addressInfoClicked, setAddressInfoClicked] = useState(location.pathname === `/employee/edit/${id}`);
    const [bankDetailsClicked, setBankDetailsClicked] = useState(location.pathname === `/employee/edit/${id}`);
    const [salaryDetailsClicked, setSalaryDetailsClicked] = useState(location.pathname === `/employee/edit/${id}`);
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [addBankDetatilsClicked, setAddBankDetailsClicked] = useState(location.pathname === `/employees/edit/${id}`);
    const [skills, setSkills] = useState([])
    // const [docs, setDocs] = useState([]);
    const [fileUploaded, setUploadedFile] = useState([]);
    const [filesArray, setFilesArray] = useState([]);
    const {
        employeeStart, setEmployeeStart, employeeEnd, setEmployeeEnd, employeeCurrentPage, setEmployeeCurrentPage
    } = useContext(PaginationContext)
    const navigate = useNavigate();
    const [SkillsClicked, setSkillsClicked] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [fetchedImg, setFetchedImg] = useState("");
    const [allData, setAllData] = useState({});

    const [docTypes, setDocTypes] = useState([]);
    const [docTypeId, setDocTypeId] = useState(null);
    const [userDocuments, setUserDocuments] = useState([]);
    const [uploadDoc, setUploadDoc] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [currentDocument, setCurrentDocument] = useState();
    const [loadingDelete, setLoadingDelete] = useState(false);

    const [systemDocTypes, setSystemDocTypes] = useState([]);
    const [sysDocTypeId, setSysDocTypeId] = useState(null);

    const handleProfileImage = async (e, setFieldValue) => {
        let item;
        setFetchedImg("");

        if (e?.target?.files[0]?.type?.split("/")[0] === "image") {
            item = e.target.files[0];
            const base64 = await encodeFileBase64(item);
            setFieldValue("image", base64);
        }
    }
    const encodeFileBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const [initialEmployeeInfo, setInitialEmployeeinfo] = useState({
        image: "",
        name: "",
        last_name: "",
        id: "",
        job_title: "",
        department: "",
        department_name: "",
        employee_type: "",
        employee_type_name: "",
        employee_email: "",
        date_of_hire: "",
        profile_status: "",
    })

    const validationSchemaEmployeeInfo = Yup.object().shape({
        // id: Yup.number().typeError("Job ID should be a number").required("Job ID is required"),
        id: Yup.string().required("Employee ID is required"),
        name: Yup.string().required("Name is required"),
        last_name: Yup.string().required("Name is required"),
        job_title: Yup.string().required("Job title is required"),
        department: Yup.string().required("Department is required"),
        employee_type: Yup.string().required("Employee type is required"),
        employee_email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Email address is required"),
        date_of_hire: Yup.date().typeError("Invalid date").required("Date of birth is required")
    })

    const dateString = '05-26-2023';
    const timestamp = Date.parse(dateString);
    const date = new Date(timestamp);

    const [initialPersonalInfo, setInitialPersonalInfo] = useState({
        email: "",
        mobile: "",
        gender: "",
        emergency_contact: "",
        blood_group: "",
        date_of_birth: "",
        pan: "",
    })
    const validationSchemaPersonalInfo = Yup.object().shape({
        email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Email address is required"),
        mobile: Yup.string().matches(/^\+[1-9]\d{1,14}$/, "Invalid mobile number").required("Mobile is required"),
        emergency_contact: Yup.string().matches(/^\+[1-9]\d{1,14}$/, "Invalid Emergency contact").required("Emergency contact is required"),
        gender: Yup.string().required("Select Gender"),
        blood_group: Yup.string().required("Blood group is required").matches(/^(A|B|AB|O)[+-]$/, 'Invalid blood group'),
        date_of_birth: Yup.date().typeError("Invalid date").required("Date of birth is required"),
        pan: Yup.string().matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/, "Invalid PAN number")
            .required('PAN is required'),
    })


    const handleSubmitInfo = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);


        let obj = {
            ...allData, ...values
        };
        if (allData.email === values?.email) {
            obj = { ...obj, email: undefined }
        }
        if (fetchedImg === values.image) {
            obj = { ...obj, image: undefined }
        }
        if (!values.image) {
            obj = { ...obj, image: undefined }
        }
        if (!values?.email) {
            obj = { ...obj, email: undefined }
        }

        if (allData.employee_email === values?.employee_email) {
            obj = { ...obj, employee_email: undefined }
        }

        if (!values?.employee_email) {
            obj = { ...obj, employee_email: undefined }
        }

        if (allData.id === values?.id) {
            obj = { ...obj, id: undefined }
        }

        if (!values?.id) {
            obj = { ...obj, id: undefined }
        }

        const object = { ...obj }

        try {
            const response = await employeeEdit(object, id);
            fetchEmployeeData();
            setSubmitting(false);
            enqueueSnackbar("Edited successfully", { variant: "success" });
            // console.log("Submitting");
            if (Object.keys(values).indexOf("name") > -1) {
                setEmployeeInfoClicked(false)
            }
            if (Object.keys(values).indexOf("mobile") > -1) {
                setPersonalinfoClicked(false);
            }
            if (Object.keys(values).indexOf("mobile") > -1) {
                setPersonalinfoClicked(false);
            }
            if (Object.keys(values).indexOf("country") > -1) {
                setAddressInfoClicked(false);
            }
            if (Object.keys(values).indexOf("bankDetails") > -1) {
                setBankDetailsClicked(false);
            }
            if (Object.keys(values).indexOf("total") > -1) {
                setSalaryDetailsClicked(false);
            }
            if (Object.keys(values).indexOf("skils") > -1) {
                setSalaryDetailsClicked(false);
            }
            setSkillsClicked(false)

        } catch (error) {
            setSubmitting(false);
            // console.log(error, "error");
            let errObj = {}
            if (error?.data?.errors?.official_email) {
                errObj = { ...errObj, "employee_email": error.data.errors.official_email }
            }
            if (error?.data?.errors?.employee_id) {
                errObj = { ...errObj, "id": error.data.errors.employee_id }
            }
            if (error?.data?.errors?.personel_email) {
                errObj = { ...errObj, "email": error.data.errors.personel_email }
            }
            setErrors(errObj)
            enqueueSnackbar("Something went wrong", { variant: "error" })

        }
    }

    const addBankDetails = async (values, { setSubmitting }) => {
       

        let obj = {
            ...allData, bankDetails: [...allData.bankDetails, {
                bank: values.bank,
                account: values.account,
                ifsc: values.ifsc,
                account_holder: values.account_holder,
                branch: values.branch
            }]
        }
        obj = { ...obj, email: undefined, employee_email: undefined, image: undefined, id: undefined }
       

        try {
            const response = await employeeEdit(obj, id);
            fetchEmployeeData();
            setSubmitting(false);
            setAddBankDetailsClicked(false);
            enqueueSnackbar("Edited successfully", { variant: "success" });
        } catch (error) {
            setSubmitting(false);
            enqueueSnackbar("Something went wrong", { variant: "error" })
        }


    }

    const [initialAddressInfo, setInitialAddressInfo] = useState({
        country: "",
        city: "",
        location: "",
        post_code: "",
        perm_country: "",
        perm_city: "",
        perm_location: "",
        perm_post_code: ""
    })

    const validationSchemaAddressInfo = Yup.object().shape({
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        location: Yup.string().required("Location is required"),
        post_code: Yup.string()
            .required('PIN code is required')
            .matches(/^\d{6}$/, 'PIN code must be 6 digits'),
        perm_country: Yup.string().required("Country is required"),
        perm_city: Yup.string().required("City is required"),
        perm_location: Yup.string().required("Location is required"),
        perm_post_code: Yup.string()
            .required('PIN code is required')
            .matches(/^\d{6}$/, 'PIN code must be 6 digits'),
    })

    const [initialBankdetailsInfo, setInitialBankdetailsInfo] = useState(
        {
            bankDetails: []
        })
    const initialBankdetails =
    {
        bank: "",
        account: "",
        ifsc: "",
        account_holder: "",
        branch: ""
    }


    const fetchEmployeeTypeOptions = async () => {
        try {
            const response = await fetchEmployeeTypes();
            const options = response?.datas?.map((e) => (
                { label: e.employee_type, value: e._id })
            )
            setEmployeeTypes(options);
        } catch (error) {
        }
    }
    const handleImageError = (e) => {
        e.target.src = profilepic;
    }

    const fetchDepartmentOptions = async () => {

        try {
            const response = await fetchDepartments();
            const options = response?.datas?.map((e) => (
                {
                    label: e.department,
                    value: e._id
                }
            ))
            setDepartments(options)
        } catch (error) {
            // console.warn(error);
        }
    }

    const fetchEmployeeData = async () => {
        setLoading(true);
        try {
            const response = await fetchSingleEmployeeData(id);
            setUserDocuments(response?.documents);
            setFetchedImg(response?.personel_details?.image);
            setInitialEmployeeinfo({
                image: response?.personel_details?.image,
                name: response?.personel_details?.first_name,
                last_name: response?.personel_details?.last_name,
                id: response?.official_details?.employee_id,
                job_title: response?.official_details?.job_title,
                department: response?.official_details?.department?._id,
                employee_type: response?.official_details?.employee_type?._id,
                employee_type_name: response?.official_details?.employee_type?.employee_type,
                department_name: response?.official_details?.department?.department,
                date_of_hire: response?.official_details?.date_of_join,
                employee_email: response?.official_details?.official_email,
                profile_status: response?.official_details?.profile_status,

            })

            setInitialPersonalInfo({
                email: response.personel_details.personel_email,
                mobile: response.personel_details.phone,
                gender: response.personel_details.gender,
                emergency_contact: response.personel_details.emergency_contact,
                blood_group: response.personel_details.blood_group,
                date_of_birth: response.personel_details?.dob,
                pan: response.personel_details?.pan
            }

            )


            setInitialBankdetailsInfo({
                bankDetails: response?.account_details?.map(e => ({
                    bank: e?.bank_name,
                    account: e?.account_no,
                    account_holder: e?.account_holder,
                    ifsc: e?.ifsc,
                    branch: e?.branch_name,
                }))
            })




            setInitialAddressInfo({
                country: response?.contact_details?.current_address?.country,
                city: response?.contact_details?.current_address?.state,
                location: response?.contact_details?.current_address?.address,
                post_code: response?.contact_details?.current_address?.pincode,
                perm_country: response?.contact_details?.permanent_address?.country,
                perm_city: response?.contact_details?.permanent_address?.state,
                perm_location: response?.contact_details?.permanent_address?.address,
                perm_post_code: response?.contact_details?.permanent_address?.pincode
            })


            setSalaryDetails({ total: response?.salary })

            setInitialSkills({
                skills: response?.skills.map((e) => (
                    {
                        id: e._id,
                        value: e.skill
                    }
                ))
            }
            )


            setAllData({
                image: response?.personel_details?.image,
                name: response?.personel_details?.first_name,
                last_name: response?.personel_details?.last_name,
                id: response?.official_details?.employee_id,
                job_title: response?.official_details?.job_title,
                department: response?.official_details?.department?._id,
                employee_type: response?.official_details?.employee_type?._id,
                employee_type_name: response?.official_details?.employee_type?.employee_type,
                department_name: response?.official_details?.department?.department,
                date_of_hire: response?.official_details?.date_of_join,
                employee_email: response?.official_details?.official_email,
                profile_status: response?.official_details?.profile_status,
                email: response.personel_details.personel_email,
                mobile: response.personel_details.phone,
                gender: response.personel_details.gender,
                emergency_contact: response.personel_details.emergency_contact,
                blood_group: response.personel_details.blood_group,
                date_of_birth: response.personel_details?.dob,
                pan: response.personel_details?.pan,

                bankDetails: response?.account_details?.map(e => ({
                    bank: e?.bank_name,
                    account: e?.account_no,
                    account_holder: e?.account_holder,
                    ifsc: e?.ifsc,
                    branch: e?.branch_name,
                })),
                country: response?.contact_details?.current_address?.country,
                city: response?.contact_details?.current_address?.state,
                location: response?.contact_details?.current_address?.address,
                post_code: response?.contact_details?.current_address?.pincode,
                perm_country: response?.contact_details?.permanent_address?.country,
                perm_city: response?.contact_details?.permanent_address?.state,
                perm_location: response?.contact_details?.permanent_address?.address,
                perm_post_code: response?.contact_details?.permanent_address?.pincode,
                total: response.salary,
                skills: response.skills.map(e => ({ id: e._id, value: e.skill }))
            })

            setLoading(false);
        } catch (error) {

            setLoading(false)
        }
    }



    const validationSchemaBankDetailsInfo = Yup.object().shape({
        bankDetails: Yup.array().of(
            Yup.object().shape({
                bank: Yup.string().required("Bank name is required"),
                account: Yup.number().typeError("Account number must be a number").positive("Account number must be positive").required("Account number is required"),
                account_holder: Yup.string().required("Account holder is required"),
                ifsc: Yup.string()
                    .required('IFSC code is required')
                    .matches(/^[A-Z]{4}[0][A-Z0-9]{6}$/, 'Invalid IFSC code'),
                branch: Yup.string().required("Branch is required"),
            })
        )

    })
    const validationSchmeBankDetails = Yup.object().shape({
        bank: Yup.string().required("Bank name is required"),
        account: Yup.string().required("Account number is required").matches(/^[0-9]{9,18}$/, 'Invalid account number'),
        ifsc: Yup.string()
            .required('IFSC code is required')
            .matches(/^[A-Z]{4}[0][A-Z0-9]{6}$/, 'Invalid IFSC code'),
        branch: Yup.string().required("Branch is required"),
        account_holder: Yup.string().required("Account holder is required"),

    })
    // const [initialSalaryDetails, setSalaryDetails] = useState({
    //     salary: [{ label: "", amount: "" }, { label: "", amount: "" }, { label: "", amount: "" }]
    // })
    const [initialSalaryDetails, setSalaryDetails] = useState({
        total: ""
    })
    const validationSchemaSalaryDetails = Yup.object().shape({
        // salary: Yup.array().of(
        //     Yup.object().shape({
        //         amount: Yup.number().typeError("Amount should be a number").positive("Amount should be positive number")
        //     })
        // )
        total: Yup.number().typeError("Total should be number")

    })

    const [initialSkills, setInitialSkills] = useState({
        skill: "",
        skills: []

    })


    const validationSchemaSkills = Yup.object().shape({
        skills: Yup.array().min(1)
    })

    const fetchSkillsOptions = async (values) => {
        try {
            const response = await fetchSkills(values);
            const opts = response?.datas?.map(e => ({ value: { value: e._id, label: e.skill }, label: e.skill }));
            setSkills(opts);

        } catch (error) {
        }

    }


    const loadOptions = (values) => {
        fetchSkillsOptions(values)
    }

    const fetchUserDocOptions = async () => {
        try {
            const response = await fetchAllUserDocumentTypes();
            const options = response?.datas?.map((e) => (
                { label: e.document_type, value: e._id })
            )
            setDocTypes(options);
        } catch (error) {
        }

    }

    const fetchSystemDocOptions = async () => {
        try {
            const response = await fetchAllSystemDocumentTypes();
            const options = response?.datas?.map((e) => (
                { label: e.document_type, value: e._id })
            )
            setSystemDocTypes(options);
        } catch (error) {
        }

    }



    const fetchSalaryTemplateData = async () => {

        try {
            const response = await fetchSalaryTemplate();

            setSalaryTemplate(response)
        } catch (error) {
            console.warn(error);
        }
    }

    const loadingSkeleton = ({ count, className }) => {
        let loadingArr = [];
        for (let i = 0; i < count; i++) {
            loadingArr.push(
                <div className={className}>
                    <div className="col-2">
                        <Skeleton count={1} style={{ height: "12px", marginBottom: "1px", borderRadius: "10px" }} />
                    </div>
                    <div className=".col-12">
                        <Skeleton count={1} style={{ height: "2.5rem", marginBottom: "0.8rem", borderRadius: "10px" }} />
                    </div>
                </div>
            );
        }
        return loadingArr;


    }
    const replaceImg = (e) => {
        e.target.src = profilepic;
    }

    const LoadingDataSkeleton = ({ count, className }) => {
        let loading = [];
        for (let i = 0; i < count; i++) {
            loading.push(
                <div className={className}>
                    <Skeleton
                        count={1} style={{ height: "12px", width: "42px", borderRadius: "10px" }}
                    />
                    <Skeleton
                        count={1} style={{ height: "20px", width: "50%", borderRadius: "10px" }}
                    />

                </div>
            )
        }
        return loading;
    }

    const handleDoc = async (e) => {
        let item;
        let data = {};

        item = e.target.files[0];
        const base64 = await encodeFileBase64(item);
        data["document_type_id"] = docTypeId?.value;
        data["document"] = base64;
        data["target_user_id"] = id && id;
        setUploadDoc(true);
        try {
            const response = await uploadUserDocument(data);
            setUploadDoc(false);
            setDocTypeId(null)
            fetchEmployeeData();

        }
        catch (error) {
            setUploadDoc(false);
            setDocTypeId(null)
            enqueueSnackbar(error?.data?.message, { variant: "error" })
        }

    }
    const removeDocument = async (file) => {
        let data = {};
        data["target_user_id"] = id && id;
        data["target_document_id"] = file?._id
        setUploadDoc(true);
        try {
            const response = await removeUserDocument(data);
            setUploadDoc(false);
            setDocTypeId(null);
            setCurrentDocument(null);
            setOpenConfirmModal(false);
            fetchEmployeeData();
        }
        catch (error) {
            setUploadDoc(false);
            setCurrentDocument(null);
            setOpenConfirmModal(false);
            setDocTypeId(null)
            enqueueSnackbar(error?.data?.message, { variant: "error" })
        }
    }

    const openDeleteModal = (object) => {
        setCurrentDocument(object);
        setOpenConfirmModal(true);
    }

    const closeConfirmHandler = () => {
        setOpenConfirmModal(false);
        setCurrentDocument(null);
    }


    const downloadDoc = (file) => {
        const anchor = document.createElement('a');
        anchor.href = process.env.REACT_APP_BASE_URL + '/' + file?.document;
        anchor.target = '_blank'
        anchor.download = file?.document_type?.document_type;
        document.body.appendChild(anchor);
        setTimeout(1000)
        anchor.click();
        document.body.removeChild(anchor);
    }
    const downloadDocx = () => {

    }

    const onChangeDocTypeHandler = (docTypeId) => {
        setDocTypeId(docTypeId);
    }


    const onChangeSystemDocTypeHandler = (docTypeId) => {
        setSysDocTypeId(docTypeId);
    }

    const downloadSystemGenDocuments = async () => {
        try {
            const response = await generateSystemDocument(sysDocTypeId?.value);
            const anchor = document.createElement('a');
            anchor.href = process.env.REACT_APP_BASE_URL + response;
            anchor.target = '_blank'
            anchor.download = sysDocTypeId?.label;
            document.body.appendChild(anchor);
            setTimeout(1000)
            anchor.click();
            document.body.removeChild(anchor);
        }
        catch (error) {

        }
    }

    const openCalendar = (e) => {

        e.target.click();
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        fetchEmployeeData();
        fetchDepartmentOptions();
        fetchEmployeeTypeOptions();
        fetchSkillsOptions()
        fetchUserDocOptions()
        fetchSystemDocOptions()
        fetchSalaryTemplateData();


    }, []);

    return (
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24 d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-2">
                    {/* <span to='Home' className="s-regular decoration-none text-primary cursor-pointer">Home</span> */}
                    {/* <img src={rightArrow} alt="right" /> */}
                    <span onClick={() => {
                        navigate('/employees')
                        setEmployeeCurrentPage(1);
                        setEmployeeEnd();
                        setEmployeeStart(1);

                    }} className="decoration-none s-regular cursor-pointer text-primary">Employee</span>
                    <img src={rightArrow} alt="right" />
                    <span>{initialEmployeeInfo.name + " " + initialEmployeeInfo.last_name}</span>
                </div>


            </div>
            <div className="d-flex mt-40 col-10  justify-content-between align-items-center">
                <div className="col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div className="d-flex gap-10 col-12 col-sm-6">
                        <img className="cursor-pointer"
                            onClick={() => { navigate(-1) }}
                            src={left} alt="left-arrow" />

                        <span className="extra-bold">Employee profile</span>

                    </div>
                    {/* <div className="d-flex gap-24 col-12 col-sm -6 justify-content-end">
                        <button type="submit" className="btn-primary w-44 h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                            <img src={attendance} alt="" />
                            <span className="d-none d-md-block">Mark attendance</span>
                        </button>
                        <button type="submit" className="btn-secondary w-195 h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                            <img src={payment} alt="" />
                            <span className="d-none d-md-block text-white">Payment</span>
                        </button>
                    </div> */}

                </div>

            </div>
            <div className="col-10 m-24 rounded-20 bg-white d-flex flex-column p-24 gap-24 ">


                {!employeeInfoClicked ? <div className="d-flex flex-column rounded-10 p-24 border-secondary">

                    <div className='d-flex cursor-pointer justify-content-between cursor-pointer align-items-center'>
                        <span className='semi-bold'>Employee information</span>
                        <div onClick={() => setEmployeeInfoClicked(true)} className='d-flex justify-content-between align-items-center gap-2'>
                            <img src={edit} alt="" />
                            <span className='decoration-none text-primary '>Edit</span>
                        </div>


                    </div>

                    <div className="d-flex  flex-wrap align-items-center">
                        <div className='m-24 col-12 gap-24 col-lg-4 d-flex align-items-center'>
                            {
                                loading ?
                                    <>
                                        <Skeleton
                                            count={1} style={{ height: "100px", width: "100px", borderRadius: "50%" }}
                                        />
                                        <div>
                                            <Skeleton
                                                count={1} style={{ height: "40px", width: "100px" }}
                                            />
                                            <Skeleton
                                                count={1} style={{ height: "22px", width: "52px" }}
                                            />
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="position-relative">
                                            {!loading && <div className=" left-auto bg-white d-flex justify-content-center align-items-center right-0 top-auto bottom-0 position-absolute  w-24 h-24 rounded-50">
                                                <div className={`${initialEmployeeInfo.profile_status === "active" ? "bg-active" : "bg-inactive"}  w-13 h-13 rounded-50`}>

                                                </div>
                                            </div>}
                                            <img className='w-100px rounded-50 h-100px' src={initialEmployeeInfo?.image ? process.env.REACT_APP_BASE_URL + "/" + initialEmployeeInfo?.image : profilepic} onError={replaceImg} alt="profiles" />

                                        </div>

                                        <div className='d-flex flex-column p-10'>
                                            <span className='s-regular-semi-bold'>{initialEmployeeInfo?.name + " " + initialEmployeeInfo?.last_name}</span>
                                            <span className='text-secondary s-regular-medium'>ID: {initialEmployeeInfo?.id}</span>
                                        </div>
                                    </>
                            }
                        </div>
                        {loading ?
                            <>

                                <LoadingDataSkeleton count={5} className="col-lg-3 m-24  col-6 d-flex flex-column" />
                            </>



                            : <>

                                <div className="col-lg-3 m-24  col-6 d-flex flex-column">
                                    <span className='s-regular-small text-secondary'>
                                        Job title
                                    </span>
                                    <span>
                                        {initialEmployeeInfo?.job_title}
                                    </span>
                                </div>
                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                    <span className='s-regular-small text-secondary'>
                                        Department
                                    </span>
                                    <span>
                                        {initialEmployeeInfo?.department_name}
                                    </span>

                                </div>


                                <div className={`col-lg-2 m-24  col-6 d-flex flex-column`}>
                                    <span className='s-regular-small text-secondary'>
                                        Date of hire
                                    </span>
                                    <span>
                                        {initialEmployeeInfo?.date_of_hire.split('-').reverse().join('-')}
                                    </span>
                                </div>
                                <div className={`col-lg-2 m-24  col-6 d-flex flex-column`}>
                                    <span className='s-regular-small text-secondary'>
                                        Employee type
                                    </span>
                                    <span>
                                        {initialEmployeeInfo?.employee_type_name}
                                    </span>
                                </div>
                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                    <span className='s-regular-small text-secondary'>
                                        Employee email
                                    </span>
                                    <span>
                                        {initialEmployeeInfo?.employee_email}
                                    </span>
                                </div>

                            </>

                        }



                    </div>


                </div> :
                    <Formik enableReinitialize={true} initialValues={initialEmployeeInfo} validationSchema={validationSchemaEmployeeInfo}
                        onSubmit={handleSubmitInfo}
                    >
                        {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => (
                            <Form>


                                {/* console.log('uploads/users/168750466234395.png'===); */}
                                <div className="d-flex flex-column rounded-10 p-24 border-secondary gap-24 ">

                                    <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                        <span className='semi-bold'>Employee information</span>

                                        <div className="d-flex gap-24">
                                            <button onClick={() => { setEmployeeInfoClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                <img src={close} alt="" />
                                                <span>Cancel</span>
                                            </button>
                                            {
                                                loading ? <div className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">

                                                </div> : <button type="submit"

                                                    className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                    {isSubmitting ? <ClipLoader /> : <><img src={save} alt="" />
                                                        <span>Save</span>
                                                    </>
                                                    }
                                                </button>
                                            }

                                        </div>




                                    </div>

                                    <div className="d-flex   flex-wrap align-items-center gap-24">
                                        <div className=' col-12 gap-24 d-flex align-items-center'>
                                            <div className=" position-relative">
                                                {values?.image && <img src={closeCircle} className="cursor-pointer position-absolute left-auto right-0" alt="remove" onClick={() => {
                                                    profileImageRef.value = null;
                                                    setFieldValue('image', "")
                                                    setFetchedImg("")
                                                }} />}
                                                <img className='w-100px h-100px rounded-50' src={fetchedImg ? process.env.REACT_APP_BASE_URL + "/" + values.image : values.image ? values.image : profilepic}
                                                    onError={handleImageError}
                                                    alt="profiles" />
                                            </div>

                                            {
                                                loading ?
                                                    <div className="col-1">
                                                        <Skeleton count={1} style={{ height: "2.5rem", marginBottom: "0.8rem", borderRadius: "10px" }} />
                                                    </div> :

                                                    <div className='d-flex flex-column gap-6'>
                                                        <div onClick={() => profileImageRef.click()} className=" w-50px w-169 border-success rounded-10 p-10 d-flex gap-10 cursor-pointer">

                                                            <img src={gallery} alt="gallery" />
                                                            <span className="d-none  d-md-block">Upload photo</span>

                                                        </div>
                                                        <input type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            hidden={true}
                                                            ref={refParam => profileImageRef = refParam}
                                                            onChange={(e) => {
                                                                handleProfileImage(e, setFieldValue);
                                                            }}

                                                        />
                                                        <div className="text-secondary s-regular-small">Atleast 256x256 PNG or JPG file</div>
                                                    </div>

                                            }


                                        </div>
                                        {
                                            loading ? (
                                                <>
                                                    {loadingSkeleton({ count: 3, className: "col-12 col-md-5 col-lg-3" })}
                                                    {loadingSkeleton({ count: 3, className: "col-12 col-md-5 col-lg-3" })}
                                                    {loadingSkeleton({ count: 3, className: "col-12 col-md-5 col-lg-3" })}
                                                </>
                                            ) : <>
                                                <CustomField
                                                    id="name"
                                                    name="name"
                                                    placeholder="First name"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    label="First name"
                                                    touched={touched}
                                                    errors={errors}
                                                />

                                                <CustomField
                                                    id="last_name"
                                                    name="last_name"
                                                    placeholder="Last name"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    label="Last name"
                                                    touched={touched}
                                                    errors={errors}
                                                />

                                                <CustomField
                                                    id="id"
                                                    name="id"
                                                    label="Employee ID"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    placeholder="Add employee id"
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                                <CustomField
                                                    id="job_title"
                                                    name="job_title"
                                                    placeholder="Add Job title"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    label="Job title"
                                                    touched={touched}
                                                    errors={errors}
                                                />



                                                <CustomSelect
                                                    id="department"
                                                    values={values}
                                                    name="department"
                                                    options={departments}
                                                    placeholder="Select department"
                                                    touchedObj={touched}
                                                    label="Department"
                                                    touched={touched}
                                                    errors={errors}
                                                    setTouched={setTouched}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    setFieldValue={setFieldValue}
                                                />

                                                <CustomSelect
                                                    id="employee_type"
                                                    values={values}
                                                    name="employee_type"
                                                    options={employeeTypes}
                                                    placeholder="Select employee"
                                                    touchedObj={touched}
                                                    label="Employee type"
                                                    touched={touched}
                                                    errors={errors}
                                                    setTouched={setTouched}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    setFieldValue={setFieldValue}

                                                />

                                                <CustomField
                                                    id="employee_email"
                                                    name="employee_email"
                                                    label="Employee email"
                                                    placeholder="Add employee email"
                                                    errors={errors}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched}


                                                />
                                                <CustomField
                                                    id="date_of_hire"
                                                    name="date_of_hire"
                                                    type="date"
                                                    label="Date of hire"
                                                    onKeyDown={() => { return false }}
                                                    errors={errors}
                                                    fieldClass={values.date_of_hire ? "custom-input-field" : " "}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched}
                                                />
                                                <CustomRadio
                                                    id="profile_status"
                                                    label="profile_status"
                                                    name="profile_status"
                                                    setFieldValue={setFieldValue}
                                                    labelAndValues={[{ value: "active", label: "Active" }, { value: "inactive", label: "Inactive" }]}
                                                    values={values}

                                                />


                                            </>

                                        }



                                    </div>



                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {!personalinfoClicked ?
                    <div className="d-flex flex-column rounded-10 p-24 border-secondary gap-24">
                        <div className='d-flex cursor-pointer justify-content-between align-items-center'>
                            <span className='semi-bold'>Personal information</span>
                            <div onClick={() => { setPersonalinfoClicked(true) }} className='d-flex justify-content-between align-items-center gap-2'>
                                <img src={edit} alt="" />
                                <span className='decoration-none text-primary cursor-pointer'>Edit</span>
                            </div>


                        </div>



                        <div className="d-flex  flex-wrap align-items-center">
                            {loading ?
                                <LoadingDataSkeleton

                                    count={7}
                                    className="col-lg-3  col-12 col-sm-6 d-flex flex-column mt-24"
                                /> :

                                <>
                                    <div className={`col-lg-4  col-12 col-sm-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Email
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.email}
                                        </span>
                                    </div>


                                    <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Mobile number
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.mobile}
                                        </span>
                                    </div>
                                    <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Emergency contact
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.emergency_contact}
                                        </span>
                                    </div>
                                    <div className={`col-lg-2 m-24  col-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Gender
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.gender}
                                        </span>
                                    </div>

                                    <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Blood group
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.blood_group}
                                        </span>
                                    </div>
                                    <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Date of birth
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.date_of_birth?.split('-')?.reverse().join('-')}
                                        </span>
                                    </div>
                                    <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                        <span className='s-regular-small text-secondary'>
                                            Pan
                                        </span>
                                        <span>
                                            {initialPersonalInfo?.pan}
                                        </span>
                                    </div>
                                </>

                            }

                        </div>
                    </div> : <Formik enableReinitialize={true} initialValues={initialPersonalInfo} validationSchema={validationSchemaPersonalInfo}
                        onSubmit={handleSubmitInfo}
                    >
                        {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => (
                            <Form>

                                <div className="d-flex flex-column gap-24 rounded-10 p-24 border-secondary">

                                    <div className='d-flex justify-content-between flex-wrap  align-items-center'>
                                        <span className='semi-bold'>Personal information</span>

                                        <div className="d-flex gap-24">
                                            <button onClick={() => { setPersonalinfoClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                <img src={close} alt="" />
                                                <span>Cancel</span>
                                            </button>
                                            {
                                                loading ? <div className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">

                                                </div> : <button type="submit" className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                    {isSubmitting ? <ClipLoader /> :
                                                        <>
                                                            <img src={save} alt="" />
                                                            <span>Save</span>
                                                        </>}
                                                </button>
                                            }


                                        </div>




                                    </div>

                                    <div className="d-flex   flex-wrap align-items-center gap-24">
                                        {loading ? <>
                                            {loadingSkeleton({ count: 3, className: "col-12 col-md-5 col-lg-3" })}
                                            {loadingSkeleton({ count: 3, className: "col-12 col-md-5 col-lg-3" })}
                                            {loadingSkeleton({ count: 3, className: "col-12 col-md-5 col-lg-3" })}
                                        </> : <>
                                            <CustomField
                                                id="email"
                                                name="email"
                                                placeholder="Add employee email"
                                                className="col-12 col-md-5 col-lg-3"
                                                label="Employee email"
                                                touched={touched}
                                                errors={errors}
                                            />
                                            <CustomField
                                                id="mobile"
                                                name="mobile"
                                                placeholder="+911231231234"
                                                className="col-12 col-md-5 col-lg-3"
                                                label="Mobile"
                                                touched={touched}
                                                errors={errors}
                                            />

                                            <CustomField
                                                id="emergency_contact"
                                                name="emergency_contact"
                                                label="Emergency contact"
                                                placeholder="+911231231234"
                                                touched={touched}
                                                errors={errors}
                                                className="col-12 col-md-5 col-lg-3"
                                            />
                                            <CustomSelect
                                                id="gender"
                                                values={values}
                                                name="gender"
                                                options={genders}
                                                placeholder="Select gender"
                                                label="Gender"
                                                touched={touched}
                                                errors={errors}
                                                setTouched={setTouched}
                                                className="col-12 col-md-5 col-lg-3"
                                                setFieldValue={setFieldValue}
                                            />


                                            <CustomField
                                                id="blood_group"
                                                name="blodd_group"
                                                label="Blood group"
                                                placeholder="Blood group"
                                                touched={touched}
                                                errors={errors}
                                                className="col-12 col-md-5 col-lg-3"
                                            />




                                            <CustomField
                                                id="date_of_birth"
                                                label="Date of birth"
                                                type="date"
                                                name="date_of_birth"
                                                onKeyDown={() => { return false }}
                                                onClick={openCalendar}
                                                values={values}
                                                fieldClass={!values.date_of_birth ? "custom-input-date" : ""}
                                                touched={touched}
                                                errors={errors}
                                                className="gap-6 col-12 col-md-5 col-lg-3"
                                            />
                                            <CustomField
                                                id="pan"
                                                label="PAN"
                                                placeholder="Add PAN"
                                                name="pan"
                                                values={values}
                                                touched={touched}
                                                errors={errors}
                                                className="gap-6 col-12 col-md-5 col-lg-3"
                                            />


                                        </>
                                        }



                                    </div>


                                </div>
                            </Form>
                        )}
                    </Formik>}


                <div className="h-90px p-24 rounded-10 bg-main">
                    <ul class="col-12 nav nav-tabs d-flex justify-content-between border-0" id="myTab" role="tablist">
                        <li className="nav-item col-2 " role="presentation">
                            <button className="nav-link border-none col-12  active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Address</button>
                        </li>
                        <li className="nav-item col-2 " role="presentation">
                            <button className="nav-link border-none col-12" id="bankdeatils-tab" data-bs-toggle="tab" data-bs-target="#bankdetails" type="button" role="tab" aria-controls="bankdetails" aria-selected="false">Bank details</button>
                        </li>
                        <li className="nav-item col-3" role="presentation">
                            <button className="nav-link col-12" id="salary-tab" data-bs-toggle="tab" data-bs-target="#salary" type="button" role="tab" aria-controls="salary" aria-selected="false">Salary details</button>
                        </li>
                        <li className="nav-item col-3" role="presentation">
                            <button className="nav-link col-12" id="skills-tab" data-bs-toggle="tab" data-bs-target="#skills" type="button" role="tab" aria-controls="skills" aria-selected="false">Skill and capacity</button>
                        </li>
                        <li className="nav-item col-2" role="presentation">
                            <button className="nav-link col-12" id="docs-tab" data-bs-toggle="tab" data-bs-target="#docs" type="button" role="tab" aria-controls="docs" aria-selected="false">Documents</button>
                        </li>
                        {/* <li class="nav-item" role="presentation">
                            <button class="nav-link" id="performance-tab" data-bs-toggle="tab" data-bs-target="#performance" type="button" role="tab" aria-controls="performance" aria-selected="false">Performance overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents" aria-selected="false">Documents</button>
                        </li> */}
                    </ul>
                </div>
                <div class="tab-content">
                    <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        {!addressInfoClicked ? <div className="d-flex flex-column rounded-10 p-24 border-secondary">
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className='semi-bold'>Address</span>
                                <div onClick={() => { setAddressInfoClicked(true) }} className='d-flex justify-content-between align-items-center gap-2  cursor-pointer'>
                                    <img src={edit} alt="" />
                                    <span className='decoration-none text-primary'>Edit</span>
                                </div>

                            </div>

                            <div className="d-flex flex-wrap align-items-lg-center">
                                {
                                    loading ?
                                        <>
                                            <LoadingDataSkeleton
                                                className="m-24 col-9 col-md-5 col-lg-3 d-flex flex-column"
                                                count={2}
                                            />
                                            <LoadingDataSkeleton
                                                className="m-24 col-9 col-md-5 col-lg-6 d-flex flex-column"
                                                count={1}
                                            />
                                            <LoadingDataSkeleton
                                                className="m-24 col-9 col-md-5 col-lg-3 d-flex flex-column"
                                                count={1}
                                            />

                                        </>
                                        :
                                        <>
                                            <div className="col-12 m-24 s-regular-semi-bold">
                                                Permanent address
                                            </div>

                                            <div className='m-24 col-9 col-md-5 col-lg-3 d-flex flex-column'>
                                                <span className='s-regular-small text-secondary'>
                                                    Country
                                                </span>
                                                <span>
                                                    {initialAddressInfo.perm_country}
                                                </span>
                                            </div>
                                            <div className={`m-24 col-9 col-md-5 col-lg-3 d-flex flex-column`}>
                                                <span className='s-regular-small text-secondary'>
                                                    City/State
                                                </span>
                                                <span>
                                                    {initialAddressInfo.perm_city}
                                                </span>
                                            </div>
                                            <div className={`m-24 col-9 col-md-5 col-lg-6 d-flex flex-column`}>
                                                <span className='s-regular-small text-secondary'>
                                                    Address
                                                </span>
                                                <span>
                                                    {initialAddressInfo.perm_location}
                                                </span>
                                            </div>

                                            <div className={`m-24 col-9 col-md-5 col-lg-4  d-flex flex-column`}>
                                                <span className='s-regular-small text-secondary'>
                                                    Post code
                                                </span>
                                                <span>
                                                    {initialAddressInfo.perm_post_code}
                                                </span>
                                            </div>

                                        </>



                                }



                            </div>

                            <div className="d-flex flex-wrap align-items-lg-center">
                                {
                                    loading ?
                                        <>
                                            <LoadingDataSkeleton
                                                className="m-24 col-9 col-md-5 col-lg-3 d-flex flex-column"
                                                count={2}
                                            />
                                            <LoadingDataSkeleton
                                                className="m-24 col-9 col-md-5 col-lg-6 d-flex flex-column"
                                                count={1}
                                            />
                                            <LoadingDataSkeleton
                                                className="m-24 col-9 col-md-5 col-lg-3 d-flex flex-column"
                                                count={1}
                                            />

                                        </>
                                        :
                                        <>
                                            <div className="col-12 m-24 s-regular-semi-bold">
                                                Current address
                                            </div>

                                            <div className='m-24 col-9 col-md-5 col-lg-3 d-flex flex-column'>
                                                <span className='s-regular-small text-secondary'>
                                                    Country
                                                </span>
                                                <span>
                                                    {initialAddressInfo.country}
                                                </span>
                                            </div>
                                            <div className={`m-24 col-9 col-md-5 col-lg-3 d-flex flex-column`}>
                                                <span className='s-regular-small text-secondary'>
                                                    City/State
                                                </span>
                                                <span>
                                                    {initialAddressInfo.city}
                                                </span>
                                            </div>
                                            <div className={`m-24 col-9 col-md-5 col-lg-6 d-flex flex-column`}>
                                                <span className='s-regular-small text-secondary'>
                                                    Address
                                                </span>
                                                <span>
                                                    {initialAddressInfo.location}
                                                </span>
                                            </div>

                                            <div className={`m-24 col-9 col-md-5 col-lg-4  d-flex flex-column`}>
                                                <span className='s-regular-small text-secondary'>
                                                    Post code
                                                </span>
                                                <span>
                                                    {initialAddressInfo.post_code}
                                                </span>
                                            </div>
                                        </>
                                }

                            </div>








                        </div> :

                            <Formik
                                initialValues={initialAddressInfo} enableReinitialize={true}
                                validationSchema={validationSchemaAddressInfo}
                                onSubmit={handleSubmitInfo}

                            >
                                {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => (
                                    <Form>
                                        <div className="d-flex flex-column rounded-10 p-24 border-secondary">

                                            <div className='d-flex align-items-center justify-content-between flex-wrap'>
                                                <span className='semi-bold'>Address</span>

                                                <div className="d-flex gap-24">
                                                    <button onClick={() => { setAddressInfoClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                        <img src={close} alt="" />
                                                        <span>Cancel</span>
                                                    </button>

                                                    {
                                                        loading ? <div className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">

                                                        </div> : <button type="submit" disabled={isSubmitting} className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                            {isSubmitting ?
                                                                <ClipLoader /> :
                                                                <> <img src={save} alt="" />
                                                                    <span>Save</span>
                                                                </>
                                                            }
                                                        </button>
                                                    }

                                                </div>
                                            </div>
                                            <div className="m-24 col-12">
                                                <span className=" s-regular-semi-bold">Current address</span>
                                                <div className="d-flex gap-md-24  mt-0  flex-wrap">







                                                    {loading ?
                                                        <div className=" col-lg-3 col-md-5 col-12 m-24 d-flex flex-column">
                                                            <Skeleton
                                                                count={1}
                                                                style={{
                                                                    height: '1rem',
                                                                    width: '2.8rem'
                                                                }}
                                                            />
                                                            <Skeleton
                                                                count={1}
                                                                style={{
                                                                    height: '10rem'
                                                                }}
                                                            />
                                                        </div>

                                                        : <CustomField
                                                            id="location"
                                                            as="textarea"
                                                            placeholder="Add address"
                                                            label="Address"
                                                            fieldClass="height-200"
                                                            name="location"
                                                            rows="6"
                                                            touched={touched}
                                                            errors={errors}
                                                            className=" col-lg-3 col-md-5 col-12 m-24"
                                                        />
                                                    }


                                                    <div className="d-flex flex-column col-12 col-md-5 col-lg-3 justify-content-between m-24">


                                                        {loading ?
                                                            loadingSkeleton({ count: 2 }) :
                                                            <>
                                                                <CustomField
                                                                    id="country"
                                                                    name="country"
                                                                    label="Country"
                                                                    placeholder="Add country"
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    className="col-12"
                                                                />
                                                                <CustomField
                                                                    id="city"
                                                                    name="city"
                                                                    label="City"
                                                                    placeholder="Add city"
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    className="col-12"
                                                                />
                                                            </>

                                                        }


                                                    </div>


                                                    {loading ?
                                                        <div className="m-24 col-lg-3 col-12 col-md-5">
                                                            {loadingSkeleton({ count: 1 })}
                                                        </div>

                                                        : <CustomField
                                                            id="post_code"
                                                            name="post_code"
                                                            label="Post code"
                                                            placeholder="Add post code"
                                                            touched={touched}
                                                            errors={errors}
                                                            className="m-24 col-lg-3 col-12 col-md-5"
                                                        />}


                                                </div>





                                            </div>
                                            <div className="m-24 col-12">
                                                <div className="col-12 d-flex align-items-md-center flex-md-row flex-column gap-24">
                                                    <span className=" s-regular-semi-bold">Permanent address</span>
                                                    <div className="d-flex gap-10">

                                                        <input
                                                            onChange={(e) => {
                                                                if (!e.target.checked) {
                                                                    setFieldValue("perm_location", "")
                                                                    setFieldValue("perm_city", "")
                                                                    setFieldValue("perm_country", "")
                                                                    setFieldValue("perm_post_code", "")
                                                                    return;
                                                                }
                                                                setFieldValue("perm_location", values.location)
                                                                setFieldValue("perm_city", values.city)
                                                                setFieldValue("perm_country", values.country)
                                                                setFieldValue("perm_post_code", values.post_code)
                                                                setTouched({});
                                                            }}
                                                            className="border-primary checkbox rounded-6 cursor-pointer" type="checkbox" />
                                                        <span className="text-secondary">Same as the current address</span>
                                                    </div>
                                                </div>


                                                <div className="d-flex gap-md-24  mt-0  flex-wrap">


                                                    {loading ?
                                                        <div className=" col-lg-3 col-md-5 col-12 m-24 d-flex flex-column">
                                                            <Skeleton
                                                                count={1}
                                                                style={{
                                                                    height: '1rem',
                                                                    width: '2.8rem'
                                                                }}
                                                            />
                                                            <Skeleton
                                                                count={1}
                                                                style={{
                                                                    height: '10rem'
                                                                }}
                                                            />
                                                        </div> : <CustomField
                                                            id="perm_location"
                                                            as="textarea"
                                                            placeholder="Add address"
                                                            label="Address"
                                                            fieldClass="height-200"
                                                            name="perm_location"
                                                            rows="6"
                                                            touched={touched}
                                                            errors={errors}
                                                            className=" col-lg-3 col-md-5 col-12 m-24"
                                                        />}


                                                    <div className="d-flex flex-column col-12 col-md-5 col-lg-3 justify-content-between m-24">


                                                        {loading ?
                                                            loadingSkeleton({ count: 2 }) :
                                                            <>
                                                                <CustomField
                                                                    id="perm_country"
                                                                    name="perm_country"
                                                                    label="Country"
                                                                    placeholder="Add country"
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    className="col-12"
                                                                />
                                                                <CustomField
                                                                    id="perm_city"
                                                                    name="perm_city"
                                                                    label="City"
                                                                    placeholder="Add city"
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    className="col-12"
                                                                />
                                                            </>

                                                        }
                                                    </div>




                                                    {loading ?
                                                        <div className="m-24 col-lg-3 col-12 col-md-5">
                                                            {loadingSkeleton({ count: 1 })}
                                                        </div> : <CustomField
                                                            id="perm_post_code"
                                                            name="perm_post_code"
                                                            label="Post code"
                                                            placeholder="Add post code"
                                                            touched={touched}
                                                            errors={errors}
                                                            className="m-24 col-lg-3 col-12 col-md-5"
                                                        />}
                                                </div>





                                            </div>

                                        </div>

                                    </Form>
                                )}

                            </Formik>
                        }


                    </div>
                    <div class="tab-pane" id="bankdetails" role="tabpanel" aria-labelledby="bankdetails-tab">
                        {!bankDetailsClicked ? <div className=" d-flex flex-column rounded-10 p-24 border-secondary">
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className='semi-bold'>Bank details</span>
                                <div className="d-flex gap-24">
                                    {initialBankdetailsInfo.bankDetails?.length > 0 &&
                                        <div onClick={() => { setBankDetailsClicked(true) }} className='d-flex justify-content-between align-items-center gap-2  cursor-pointer'>
                                            <img src={edit} alt="" />
                                            <span className='decoration-none text-primary'>Edit</span>
                                        </div>}
                                    <button onClick={() => { setAddBankDetailsClicked(true) }} className="cursor-pointer btn-secondary rounded-10 d-flex gap-10 border-none p-10-16">
                                        <img src={veusax} alt="" />
                                        <span className="text-add">Add account</span>
                                    </button>
                                </div>
                            </div>
                            {initialBankdetailsInfo.bankDetails?.map((el, i) => (

                                <div className="d-flex flex-wrap align-items-center border-bottom-secondary pb-5">
                                    {
                                        loading ?
                                            <div className="col-12 d-flex flex-column">
                                                <Skeleton count={1} style={{ height: "22px", width: "20%", marginBottom: "1px", borderRadius: "10px" }} />

                                                <div className="d-flex col-12">
                                                    <LoadingDataSkeleton
                                                        className='col-lg-3 m-24  col-6 d-flex flex-column'
                                                        count={4}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className='col-12 m-24  col-6 d-flex flex-column'>

                                                    <span className="semi-bold">
                                                        {el.bank}
                                                    </span>
                                                </div>
                                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                                    <span className='s-regular-small text-secondary'>
                                                        Account number
                                                    </span>
                                                    <span>
                                                        {el.account}
                                                    </span>
                                                </div>
                                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                                    <span className='s-regular-small text-secondary'>
                                                        Account holder
                                                    </span>
                                                    <span>
                                                        {el.account_holder}
                                                    </span>
                                                </div>
                                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                                    <span className='s-regular-small text-secondary'>
                                                        IFSC
                                                    </span>
                                                    <span>
                                                        {el.ifsc}
                                                    </span>
                                                </div>
                                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                                    <span className='s-regular-small text-secondary'>
                                                        Branch name
                                                    </span>
                                                    <span>
                                                        {el?.branch}
                                                    </span>
                                                </div>
                                            </>
                                    }
                                </div>

                            ))}
                            {
                                addBankDetatilsClicked &&
                                <Formik enableReinitialize={true}
                                    onSubmit={addBankDetails}
                                    initialValues={initialBankdetails}
                                    validationSchema={validationSchmeBankDetails}>
                                    {({ isSubmitting, touched, errors }) => {
                                        return <Form>
                                            <div className="d-flex flex-column rounded-10 m-24">

                                                <div className='d-flex justify-content-between flex-wrap'>
                                                    <span className='semi-bold'>Add bank details</span>

                                                    <div className="d-flex gap-24">
                                                        <button onClick={() => { setAddBankDetailsClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                            <img src={close} alt="" />
                                                            <span>Cancel</span>
                                                        </button>
                                                        <button type="submit" disabled={isSubmitting} className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                            {isSubmitting ?
                                                                <ClipLoader /> :
                                                                <> <img src={save} alt="" />
                                                                    <span>Save</span>
                                                                </>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-24 flex-wrap align-items-center">


                                                    <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                        <label className="text-secondary s-regular-small" htmlFor="bank">Bank name</label>
                                                        <Field
                                                            name="bank"
                                                            placeholder="Add bank name"
                                                            className={`${touched?.bank && errors?.bank ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                        />
                                                        <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="bank" component="div" />
                                                    </div>


                                                    <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                        <label className="text-secondary s-regular-small" htmlFor="account">Account number</label>
                                                        <Field
                                                            name="account"
                                                            placeholder="Add account number"
                                                            className={`${touched?.account && errors?.account ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                        />
                                                        <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="account" component="div" />
                                                    </div>
                                                    <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                        <label className="text-secondary s-regular-small" htmlFor="account_holder">Account holder</label>
                                                        <Field
                                                            name="account_holder"
                                                            placeholder="Add account holder name"
                                                            className={`${touched?.account_holder && errors?.account_holder ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                        />
                                                        <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="account_holder" component="div" />
                                                    </div>

                                                    <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">

                                                        <label className="text-secondary s-regular-small" htmlFor="ifsc">IFSC</label>
                                                        <Field
                                                            name="ifsc"
                                                            placeholder="Add ifsc"
                                                            className={`${touched?.ifsc && errors?.ifsc ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                        />
                                                        <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="ifsc" component="div" />

                                                    </div>
                                                    <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">

                                                        <label className="text-secondary s-regular-small" htmlFor="branch">Branch name</label>
                                                        <Field
                                                            name="branch"
                                                            placeholder="Add branch name"
                                                            className={`${touched?.branch && errors?.branch ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                        />
                                                        <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="branch" component="div" />

                                                    </div>



                                                </div>
                                            </div>

                                        </Form>
                                    }}
                                </Formik>
                            }

                        </div> :

                            <Formik
                                initialValues={initialBankdetailsInfo}
                                enableReinitialize={true}
                                validationSchema={validationSchemaBankDetailsInfo}
                                onSubmit={handleSubmitInfo}

                            >

                                {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => {

                                    const deleteBankDetails = (data, i) => {
                                        data.splice(i, 1)
                                        setFieldValue('bankDetails', data.bankDetails)
                                    }
                                    return (

                                        <Form>
                                            <FieldArray name="bankDetails">
                                                <div className="d-flex flex-column rounded-10 p-24 border-secondary gap-24">

                                                    <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                                        <span className='semi-bold'>Bank details</span>

                                                        <div className="d-flex gap-24">
                                                            <button onClick={() => { setBankDetailsClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                                <img src={close} alt="" />
                                                                <span>Cancel</span>
                                                            </button>
                                                            <button type="submit" disabled={isSubmitting} className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                                {isSubmitting ?
                                                                    <ClipLoader /> :
                                                                    <> <img src={save} alt="" />
                                                                        <span>Save</span>
                                                                    </>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-24 flex-wrap align-items-center">
                                                        {values?.bankDetails && values?.bankDetails?.map((el, i) => (
                                                            <div className="d-flex  gap-24 flex-wrap align-items-center border-bottom-secondary pb-5">
                                                                <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                                    <label className="text-secondary s-regular-small" htmlFor={`bankDetails[${i}].bank`}>Bank name</label>
                                                                    <Field
                                                                        name={`bankDetails[${i}].bank`}
                                                                        placeholder="Add bank name"
                                                                        className={`${(touched?.bankDetails && touched?.bankDetails[i]?.bank && errors?.bankDetails && errors?.bankDetails[i]?.bank) ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                                    />

                                                                    <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`bankDetails[${i}].bank`} component="div" />
                                                                </div>


                                                                <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                                    <label className="text-secondary s-regular-small" htmlFor={`bankDetails[${i}].account`}>Account number</label>
                                                                    <Field
                                                                        name={`bankDetails[${i}].account`}
                                                                        placeholder="Add account number"
                                                                        className={`${touched?.bankDetails && touched?.bankDetails[i]?.account && errors?.bankDetails && errors?.bankDetails[i]?.account ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                                    />
                                                                    <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`bankDetails[${i}].account`} component="div" />
                                                                </div>
                                                                <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">
                                                                    <label className="text-secondary s-regular-small" htmlFor={`bankDetails[${i}].account_holder`}>Account holder</label>
                                                                    <Field
                                                                        name={`bankDetails[${i}].account_holder`}
                                                                        placeholder="Add account holder"
                                                                        className={`${touched?.bankDetails && touched?.bankDetails[i]?.account_holder && errors?.bankDetails && errors?.bankDetails[i]?.account_holder ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                                    />
                                                                    <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`bankDetails[${i}].account_holder`} component="div" />
                                                                </div>
                                                                <div className="m-24 col-md-2 col-lg-2 justify-content-end d-sm-none d-md-flex ">
                                                                    <img className="cursor-pointer" onClick={() => {
                                                                        deleteBankDetails(values.bankDetails, i)
                                                                        setFieldValue('bankDetails', values.bankDetails);
                                                                    }
                                                                    } src={trash} alt="" />
                                                                </div>
                                                                <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">

                                                                    <label className="text-secondary s-regular-small" htmlFor={`bankDetails[${i}].ifsc`}>IFSC</label>
                                                                    <Field
                                                                        name={`bankDetails[${i}].ifsc`}
                                                                        placeholder="Add ifsc"
                                                                        className={`${touched?.bankDetails && touched?.bankDetails[i]?.ifsc && errors?.bankDetails && errors?.bankDetails[i]?.ifsc ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                                    />
                                                                    <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`bankDetails[${i}].ifsc`} component="div" />

                                                                </div>
                                                                <div className="col-12 col-md-5 col-lg-3 d-flex flex-column gap-6">

                                                                    <label className="text-secondary s-regular-small" htmlFor={`bankDetails[${i}].branch`}>Branch name</label>
                                                                    <Field
                                                                        name={`bankDetails[${i}].branch`}
                                                                        placeholder="Add branch name"
                                                                        className={`${touched?.bankDetails && touched?.bankDetails[i]?.branch && errors?.bankDetails && errors?.bankDetails[i]?.branch ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                                    />
                                                                    <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`bankDetails[${i}].branch`} component="div" />

                                                                </div>
                                                                <div className="m-24 d-sm-none">
                                                                    <img className="cursor-pointer" onClick={() => {
                                                                        deleteBankDetails(values.bankDetails, i)
                                                                        setFieldValue('bankDetails', values.bankDetails);
                                                                    }
                                                                    } src={trash} alt="" />
                                                                </div>
                                                            </div>
                                                        )

                                                        )}
                                                        {values?.bankDetails?.length === 0 &&
                                                            <div className="d-flex justify-content-center col-12 text-secondary">
                                                                Bank account is not added
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </FieldArray>
                                        </Form>
                                    )
                                }}


                            </Formik>
                        }
                    </div>
                    <div class="tab-pane" id="salary" role="tabpanel" aria-labelledby="salary-tab">
                        {!salaryDetailsClicked ?
                            <div className="d-flex flex-column rounded-10 p-24 border-secondary gap-24">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='semi-bold'>Salary details</span>
                                    <div onClick={() => { setSalaryDetailsClicked(true) }} className='d-flex justify-content-between align-items-center gap-2  cursor-pointer'>
                                        <img src={edit} alt="" />
                                        <span className='decoration-none text-primary'>Edit</span>
                                    </div>

                                </div>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="d-flex flex-wrap col-12">
                                        <div className='col-lg-3 m-24  col-6 d-flex flex-column'>
                                            {loading ? <>
                                                <LoadingDataSkeleton count={1} className="col-12 m-24  col-6 d-flex flex-column" />


                                            </> :
                                                <span className='s-regular-small text-secondary'>
                                                    Total
                                                </span>}
                                            <span>
                                                {initialSalaryDetails.total ? initialSalaryDetails.total?.toLocaleString('en-IN') : 0}
                                            </span>

                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap col-12">
                                        {
                                            salaryTemplate?.[0]?.salary_template.map(e => {

                                                return <div className='col-lg-3 m-24  col-6 d-flex flex-column'>
                                                    <span className='s-regular-small text-secondary'>
                                                        {e.label}
                                                    </span>
                                                    <span className="d-flex">
                                                        <img src={currency} className=" h-24" alt="" />
                                                        <div>{initialSalaryDetails?.total ? (Number(initialSalaryDetails.total) * (Number(e.percentage) / 100)).toLocaleString('en-IN') : 0}</div>
                                                    </span>

                                                </div>

                                            })
                                        }
                                    </div>

                                </div>
                            </div> :

                            <Formik
                                initialValues={initialSalaryDetails}
                                enableReinitialize={true}
                                validationSchema={validationSchemaSalaryDetails}
                                onSubmit={handleSubmitInfo}

                            >
                                {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => (
                                    <Form>

                                        <div className="d-flex flex-column rounded-10 p-24 border-secondary">
                                            <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                                <span className='semi-bold'>Salary details</span>

                                                <div className="d-flex gap-24">

                                                    {/* <button onClick={() => { values.salary?.push({ label: "", amount: "" }) }} className="cursor-pointer btn-secondary rounded-10 d-flex border-none gap-10 p-10-16">
                                                        <img src={veusax} alt="" />
                                                        <span className="text-add">Add fields</span>
                                                    </button> */}

                                                    <button onClick={() => { setSalaryDetailsClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                        <img src={close} alt="" />
                                                        <span>Cancel</span>
                                                    </button>
                                                    <button type="submit" disabled={isSubmitting} className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                        {isSubmitting ?
                                                            <ClipLoader /> :
                                                            <> <img src={save} alt="" />
                                                                <span>Save</span>
                                                            </>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <CustomField
                                                    label="Total"
                                                    id="total"
                                                    placeholder="Add total salary"
                                                    name="total"
                                                    touched={touched}
                                                    className="col-lg-3 col-md-5 col-12 m-24"
                                                    errors={errors}
                                                />
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                {
                                                    salaryTemplate?.[0]?.salary_template.map((e, i) => {
                                                        return <div key={i} className='col-lg-3 m-24  col-6 d-flex flex-column'>
                                                            <span className='s-regular-small text-secondary'>
                                                                {e.label}
                                                            </span>
                                                            <span className="d-flex">
                                                                <img src={currency} className="h-24" alt="" />

                                                                <div>{values?.total ? (Number(values.total) * (Number(e.percentage) / 100)).toLocaleString('en-IN') : 0}
                                                                </div>
                                                            </span>

                                                        </div>

                                                    })
                                                }
                                            </div>


                                        </div>
                                    </Form>
                                )}

                            </Formik>
                        }

                    </div>
                    <div class="tab-pane" id="skills" role="tabpanel" aria-labelledby="skills-tab">
                        {!SkillsClicked ?
                            <div className="d-flex flex-column rounded-10 p-24 border-secondary gap-24">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='semi-bold'>Skills</span>
                                    <div onClick={() => { setSkillsClicked(true) }} className='d-flex justify-content-between align-items-center gap-2  cursor-pointer'>
                                        <img src={edit} alt="" />
                                        <span className='decoration-none text-primary'>Edit</span>
                                    </div>

                                </div>
                                <div className="d-flex flex-wrap gap-24 align-items-center">


                                    {initialSkills?.skills?.map((e, i) => (
                                        <div className="border-secondary col-2 rounded-10 p-10 d-flex justify-content-center gap-10">
                                            {e.value}
                                        </div>

                                    ))}
                                    {initialSkills?.skills?.length === 0 &&
                                        <div className={"d-flex col-12 justify-content-center text-secondary"}>
                                            No skills added
                                        </div>
                                    }

                                </div>
                            </div> :

                            <Formik
                                initialValues={initialSkills}
                                enableReinitialize={true}
                                onSubmit={handleSubmitInfo}

                            >
                                {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => {
                                    const removeSkill = (element) => { //remove skill
                                        const removedItemArray = values.skills.filter(el => (
                                            el !== element
                                        ))
                                        setFieldValue("skills", removedItemArray);
                                    }
                                    const addASkill = () => { //Add new Skill
                                        if (!values?.skill) {
                                            return;
                                        }
                                        const object = values?.skills?.find(el => (
                                            el?.id === values?.skill?.value
                                        ))


                                        if (object && Object?.keys(object)?.length > 0) {

                                            return;
                                        } else {
                                            const newSkillArr = [...values.skills, { id: values.skill?.value, value: values.skill?.label }]
                                            setFieldValue('skills', newSkillArr);
                                        }
                                    }
                                    return (
                                        <Form>
                                            <div className="d-flex flex-column rounded-10 p-24  border-secondary">

                                                <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                                    <span className='semi-bold'>Skills</span>

                                                    <div className="d-flex gap-24">
                                                        <button onClick={() => { setSkillsClicked(false) }} type="button" className="d-flex gap-10 h-44 btn btn-outline-dark rounded-10 w-120 gap-10 p-10-16 ">
                                                            <img src={close} alt="" />
                                                            <span>Cancel</span>
                                                        </button>
                                                        <button type="submit" disabled={isSubmitting} className="d-flex justify-content-center align-items-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                                            {isSubmitting ?
                                                                <ClipLoader /> :
                                                                <> <img src={save} alt="" />
                                                                    <span>Save</span>
                                                                </>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="d-flex   flex-column align-items-center">


                                                    <div className="col-12   m-24  gap-24 d-flex align-items-end ">
                                                        {/* <div className="col-6 d-flex flex-column gap-6 col-md-3">
                                                            <label className="text-secondary s-regular-small" htmlFor="skill">Skills</label>
                                                            <Field
                                                                name="skill"
                                                                className={`${touched.skill && errors.skill ? 'border-danger' : 'input-border-primary'} rounded height-50px form-control shadow-none rounded-10`}

                                                            />

                                                        </div> */}
                                                        <CustomSelect
                                                            id="skill"
                                                            name="skill"
                                                            loadOptions={loadOptions}
                                                            values={values}
                                                            label="Skill"
                                                            component={{ DropdownIndicator: () => null }}
                                                            // menuIsOpen={skills?.length>0 ? true : false}
                                                            placeholder="Add skill"
                                                            options={skills}
                                                            touchedObj={{ ...touched, skill: true }}
                                                            touched={touched}
                                                            errors={errors}
                                                            setTouched={setTouched}
                                                            className="col-12 col-md-5 col-lg-3"
                                                            setFieldValue={setFieldValue}
                                                        />


                                                        <button onClick={addASkill} type="button" className="height-50px btn-primary h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                                                            <img src={add} alt="add" />
                                                            <span className="d-none d-md-block ">Add </span>
                                                        </button>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-start">
                                                        <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="skills" component="div" />
                                                    </div>
                                                    <div className="d-flex flex-wrap m-24 col-12 gap-10">

                                                        {
                                                            values?.skills?.map(el => (
                                                                <div className="border-secondary col-2 rounded-10 p-10 d-flex justify-content-between gap-10">
                                                                    <div>
                                                                        <span>{el.value}</span>
                                                                    </div>

                                                                    <img className="cursor-pointer"
                                                                        onClick={() => {
                                                                            removeSkill(el)
                                                                        }}
                                                                        src={trash} alt="" />
                                                                </div>
                                                            ))
                                                        }
                                                        {values?.skills?.length === 0 &&
                                                            <div className={"d-flex justify-content-center text-secondary"}>
                                                                No skills added
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    )
                                }}

                            </Formik>
                        }

                    </div>
                    <div class="tab-pane" id="docs" role="tabpanel" aria-labelledby="docs-tab">
                        {/* <Formik
                        >
                            {({ isSubmitting, setTouched, values, errors, touched, setFieldValue }) => (
                                <Form> */}
                        <div className="d-flex flex-column rounded-10 p-24 border-secondary gap-24">
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className='semi-bold'>Upload documents {uploadDoc && <ClipLoader size={18} />} </span>
                            </div>
                            <div className="d-flex row gap-24 align-items-center">

                                <div className='d-flex gap-4'>
                                    <Select
                                        id="docs"
                                        name="docs"
                                        onChange={onChangeDocTypeHandler}
                                        values={docTypeId}
                                        placeholder="Select document type"
                                        options={docTypes}
                                        className="col-12 col-md-5 col-lg-3"
                                        styles={{
                                            control: (provided, state) => {
                                                return {
                                                    ...provided, height: '50px',
                                                    borderColor: state.isFocused ? "#E3E3E3" : "#E3E3E3",
                                                    outline: "white",

                                                    boxShadow: "none",
                                                    "&:hover": {},
                                                    borderRadius: '10px',
                                                }
                                            }
                                        }
                                        }
                                    />
                                    {docTypeId &&
                                        <div title={'upload document'} onClick={() => documentRef.click()} className="border-success rounded-10 p-10 d-flex gap-10 cursor-pointer">

                                            <img src={upload} alt="upload" />
                                            <span className="d-none d-md-block">Upload</span>

                                        </div>}
                                    <input type="file"
                                        accept=".pdf, .xlxs, .docx"
                                        hidden={true}
                                        multiple
                                        ref={refParam => documentRef = refParam}
                                        onChange={(e) => {
                                            handleDoc(e);
                                        }}

                                    />
                                    {/* <div className="text-secondary s-regular-small">Atleast 256x256 PNG or JPG file</div> */}
                                </div>
                                <div className="row m-0 pt-2 pe-1 gap-1">
                                    {userDocuments && userDocuments.map((obj, index) => {

                                        let end;
                                        if (obj.document_type?.document_type?.length > 17) end = "...";
                                        else end = "";
                                        return (
                                            // <div key={index} className=" cursor-pointer border-secondary col-3 rounded-10 p-10 d-flex justify-content-center gap-10" onClick={() => downloadDoc(obj)}>
                                            //     {obj.document_type?.document_type.substring(0, 17) + end}
                                            // </div>
                                            <div className="border-secondary col-2 rounded-10 p-10 d-flex justify-content-between gap-10">
                                                <div className={'cursor-pointer'} title={'view document'} onClick={() => { downloadDoc(obj) }}>
                                                    <span>{obj.document_type?.document_type?.substring(0, 17) + end}</span>
                                                </div>

                                                <img className="cursor-pointer" title={'delete'}
                                                    onClick={() => {
                                                        openDeleteModal(obj)
                                                    }}
                                                    src={trash} alt="" />
                                            </div>
                                        );
                                    })}

                                    {userDocuments?.length === 0 &&
                                        <div className={"d-flex justify-content-center text-secondary"}>
                                            No documents added
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className='semi-bold'>Generate documents</span>
                            </div>
                            <div className="d-flex row gap-24 align-items-center">

                                <div className='d-flex gap-4'>
                                    <Select
                                        id="sysdocs"
                                        name="sysdocs"
                                        onChange={onChangeSystemDocTypeHandler}
                                        values={sysDocTypeId}
                                        placeholder="Select document type"
                                        options={systemDocTypes}
                                        className="col-12 col-md-5 col-lg-3"
                                        styles={{
                                            control: (provided, state) => {
                                                return {
                                                    ...provided, height: '50px',
                                                    borderColor: state.isFocused ? "#E3E3E3" : "#E3E3E3",
                                                    outline: "white",

                                                    boxShadow: "none",
                                                    "&:hover": {},
                                                    borderRadius: '10px',
                                                }
                                            }
                                        }
                                        }
                                    />
                                    {sysDocTypeId &&
                                        <div title={'view document'}
                                            // onClick={() => documentRef.click()}
                                            onClick={() => downloadSystemGenDocuments()}
                                            className="border-success rounded-10 p-10 d-flex gap-10 cursor-pointer"
                                        >

                                            <img src={download} alt="download" />
                                            <span className="d-none d-md-block">Download</span>

                                        </div>}
                                    {/* <input type="file"
                                        accept=".pdf, .xlxs, .docx"
                                        hidden={true}
                                        multiple
                                        ref={refParam => documentRef = refParam}
                                        onChange={(e) => {
                                            handleDoc(e);
                                        }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openConfirmModal &&
                <DeleteConfirmModal
                    setOpenConfirm={setOpenConfirmModal}
                    show={openConfirmModal}
                    closeConfirmHandler={closeConfirmHandler}
                    requestId={currentDocument}
                    itemToDelete={"document"}
                    deleteFunction={removeDocument}
                    isSubmitting={loadingDelete}
                />}


        </div >)
}

export default EmployeeProfile;