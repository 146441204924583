import axios from "axios";

export const createNewEmployee = (values) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/users`,
            data: {
                //emp-info
                employment_information_flag : "true",
                image: values.image,
                first_name: values.empInfo.name,
                last_name: values.empInfo.last_name,
                employee_id: values.empInfo.id,  //job id
                job_title: values.empInfo.job_title,
                department_id: values.empInfo.department,
                official_email: values.empInfo.employee_email,
                employee_type_id: values.empInfo.employee_type,
                profile_status: values.empInfo.profile_status, //active or inactive
                date_of_join: values.empInfo.date_of_hire,


                //personal-info
                personel_information_flag :"true",
                phone: values.personalInfo.mobile,
                personel_email: values.personalInfo.email,
                emergency_contact: values.personalInfo.emergency_contact,
                gender: values.personalInfo.gender,
                blood_group: values.personalInfo.blood_group,
                pan:values.personalInfo.pan,
                dob: values.personalInfo.date_of_birth,
               
                //address info
                address_flag : (values.addressInfo.location ? "true" : "false"),
                current_address: values.addressInfo.location,
                current_country: values.addressInfo.country,
                current_state: values.addressInfo.city,
                current_pincode: values.addressInfo.post_code,
                permanent_address: values.addressInfo.perm_location,
                permanent_country: values.addressInfo.perm_country,
                permanent_state: values.addressInfo.perm_city,
                permanent_pincode: values.addressInfo.perm_post_code,
                
                bank_details_flag:((values.bankDetails.length>0 && values.bankDetails[0]?.bank) ? "true":"false"),
                account_details: values.bankDetails.map(e => ({
                    bank_name: e?.bank,
                    account_no: e?.account,
                    ifsc: e?.ifsc,
                    branch_name: e?.branch,
                    account_holder: e?.account_holder,

                })),
                salary_details_flag:(values.salary.total? "true":"false"),
                salary: values.salary.total,

                skills_flag:(values.skills.length>0? "true":"false"),
                skills: values.skills.map(e => (e.id)),

            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}
                // short_name: "Nill",
                // gender: "Nill",