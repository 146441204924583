import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import left from '../../assets/icons/left.svg';
import notfound from '../../assets/icons/notfound.svg';
const Page404 = ({ setShowHeader, setShowFooter }) => {
    const navigate = useNavigate();

    useEffect(() => {
        setShowFooter(false)
        setShowHeader(false)
        return () => {
            setShowHeader(true)
            setShowFooter(true)
        }
    }, [])

    return <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="col-10 h-60 bg-white d-flex rounded-20">
            <div className="col-md-6 col-12 h-100perc d-flex justify-content-center align-items-center">
                <div >
                    <div className="s-xlarge">
                        404
                    </div>
                    <div>

                        <p className="s-large-semi">
                            Oops! <br />
                            Page not found
                        </p>
                    </div>
                    <p className=" s-regular-medium text-secondary">
                        This page doesn’t exist or was removed! <br />
                        We suggest you back to home
                    </p>

                    <button onClick={() => { navigate('/employees', { replace: true }) }} className="d-flex align-items-center justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 gap-10 p-10-16 ">
                        <img src={left} alt="left" />
                        <span>Back to home</span>

                    </button>

                </div>
            </div>
            <div className="col-md-6 d-none d-md-flex align-items-center">
                
                <div className="d-flex justify-content-center col-12">
                    <img className="h-80perc" src={notfound} alt="notfound" srcset="" />
                </div>

            </div>
        </div>


    </div>
}

export default Page404; 