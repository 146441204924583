import { Field,Formik,ErrorMessage } from "formik";
const CustomRadio = ({ id,name,labelAndValues, values,setFieldValue,className,defaultValue }) => {

    
    return (
        <div className={`${className} d-flex flex-column gap-6 d-flex flex-column gap-10`}>
            <label htmlFor="profile_status" className="text-secondary s-regular-small">Profile status</label>
            <div className="col-8 d-flex gap-24 height-50px">
                {labelAndValues.map(e => (
                    <label className="d-flex align-items-center gap-1">
                        <Field
                            type="radio"
                            value={e.value}
                            checked={values?.[name] === e.value}
                            className="cursor-pointer"
                            onChange={(el) => {
                                setFieldValue(id, e.value);

                            }}
                        />
                        <span className="text-primary">{e.label}</span>

                    </label>
                ))}
               
            </div>
            <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={id} component="div" />
        </div>
    )
}
export default CustomRadio;
