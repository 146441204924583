import React, { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchSkills } from '../../utility/services/fetchSkills';
import rightArrow from '../../assets/icons/rightArrow.svg';
import Search from '../../components/search';
import edit from '../../assets/icons/edit.svg';
import trash from '../../assets/icons/trash.svg';
import add from '../../assets/icons/add.svg'
import ClipLoader from 'react-spinners/ClipLoader';
import DeleteConfirmModal from '../../components/deleteConfirmModal';
import { deleteSkill } from '../../utility/services/createSkill';
import { useSnackbar } from "notistack";
import PaginationComponent from '../../components/pagination';
import { PaginationContext } from "../../context/paginationContext";
import { deleteDepartment } from '../../utility/services/departmentServices';
import { disableExternalService, enableExternalService, fetchAllExternalServices } from '../../utility/services/externalServicesApis';
import { GlobalContext } from '../../context/globalContext';
import { fetchSingleOrganization } from '../../utility/services/organizationServices';
import './externalServices.css';

const ExternalServicesList = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [externalServices, setExternalServices] = useState()
    const [orgExternalServices, setOrgExternalServices] = useState()
    const [searchKey, setSearchKey] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [pageCount, setPageCount] = useState("");
    const {
        departmentStart, setDepartmentStart,
        departmentEnd, setDepartmentEnd,
        departmentCurrentPage, setDepartmentCurrentPage,
    } = useContext(PaginationContext)
    const {
        profileData
    } = useContext(GlobalContext)
    
    const fetchExternalServices = async (values) => {
        if(values) setDepartmentCurrentPage(values);
        setLoading(true);
        try {
            const response = await fetchAllExternalServices(searchKey, departmentCurrentPage, 5);
            setExternalServices(response.datas);
            setPageCount(response.total_pages)
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }

    }
    const fetchOrganization = async () => {
        try {
            const response = await fetchSingleOrganization(profileData?.organization);
            setOrgExternalServices(response.external_services);

        } catch (error) {

        }
    };

    const switchHandler = async (id, status) => {
        setLoadingStatus(true);
        if(status === true){
            try {
                const response = await disableExternalService(id);
                setLoadingStatus(false);
                enqueueSnackbar("External service disabled successfully", { variant: 'success' });
                // fetchExternalServices();
                fetchOrganization();
            } catch (error) {
                setLoadingStatus(false);
                enqueueSnackbar(error.data.message, { variant: 'error' });
                // fetchExternalServices();
                fetchOrganization();
            }
        }
        else{
            try {
                const response = await enableExternalService(id);
                setLoadingStatus(false);
                enqueueSnackbar("External service enabled successfully", { variant: 'success' });
                // fetchExternalServices();
                fetchOrganization();
            } catch (error) {
                setLoadingStatus(false);
                enqueueSnackbar(error.data.message, { variant: 'error' });
                // fetchExternalServices();
                fetchOrganization();
            }
        }
    }

    const onPageChange = useCallback((page) => {

        setDepartmentCurrentPage(page)

    }, [])

    useEffect(() => {
        fetchExternalServices(1);
        fetchOrganization();
    }, []);

    useEffect(() => {
        profileData?.organization && fetchOrganization();
    }, [profileData]);

    useEffect(() => {
        fetchExternalServices();
    }, [searchKey, departmentCurrentPage]);

    return (
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-10">
                    <Link to='/' className="s-regular decoration-none text-primary cursor-pointer">Home</Link>
                    {/* <img src={rightArrow} alt="" />
                    <span className="s-regular text-primary">My organization</span> */}
                    <img src={rightArrow} alt="" />
                    <span className="s-regular cursor-pointer text-primary">External services</span>
                </div>
                {/* <div className="d-flex rounded bg-white">
                    <input placeholder="Search"
                        onChange={(e) => {
                            setSearchKey(e.target.value)
                        }}
                        className="p-10-16 rounded bg-pirmary outline-0 border-0" type="text" />
                    <img className="bg-white rounded p-10-16" src={search} alt="search" />
                </div> */}
                <Search setData={setSearchKey}/>
            </div>
            <div className="d-flex col-10 justify-content-between align-items-center mb-24 mt-40">

                <div className="d-flex col-12  col-lg-6 gap-24 align-items-center">
                    {/* <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" /> */}
                    <span className="extra-bold">External services {loadingStatus && <ClipLoader size={15} />}</span>
                </div>
            </div>
            <div className="col-10 row bg-white rounded-20 p-24 d-flex justify-content-between d-flex">
                {loading ? 
                    <div className='col-12 d-flex justify-content-center height-50px align-items-center'>
                        <ClipLoader />
                    </div>
                :   <div className='col-12'>
                        <div className='d-flex col-12 border-bottom-success p-12-0'>
                            <div className="col-6 s-regular-small text-secondary ">
                                Name
                            </div>
                        </div>
                        {externalServices && externalServices.map((e, index) => {
                            let status = false;
                            status = orgExternalServices?.some((item) => item.service_id ==  e._id);
                            return(
                                <div className='col-12 d-flex p-12-0 border-bottom-success' key={index}>
                                    <div className="col-11">
                                        {e.service}
                                    </div>
                                    <div className="col-1 d-block m-0 p-0 form-check form-switch" title={status===true? 'Disable' : 'Enable'}>
                                        {/* <label className="form-check-label ps-0 ps-lg-3 m-semibold-14" htmlFor="flexSwitchCheckDefault">{status===true? 'Disable' : 'Enable'}</label> */}
                                        <input className="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={status} onChange={() =>switchHandler(e._id, status)} />
                                    </div>
                                </div>
                                
                        )})
                    }
                    {externalServices?.length===0 && 
                        <div className={"d-flex justify-content-center text-secondary mt-3"}>No datas available</div>
                    
                    }

                    </div>
                }
                 {(departmentCurrentPage && !searchKey) && 
                    <PaginationComponent
                        id={"available"}
                        pageCount={pageCount}
                        start={departmentStart}
                        end={departmentEnd}
                        setStart={setDepartmentStart}
                        setEnd={setDepartmentEnd}
                        currentPage={departmentCurrentPage}
                        setCurrentPage={setDepartmentCurrentPage}
                        onPageChange={onPageChange}
                    />
                }
            </div>
        </div>
    )

}

export default ExternalServicesList;