import React from 'react';
import ModalPopup from './modalpopup';
import close from '../assets/icons/secondaryClose.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import save from '../assets/icons/save.svg';


const DeleteConfirmModal = ({ isSubmitting, setOpenConfirm, show, closeConfirmHandler, deleteFunction, requestId, itemToDelete }) => {

    return(
        <ModalPopup 
             body1={<>
                {/* {<span className='extra-bold'>{skillId? 'Edit' : 'Add'} skill</span>} */}
                {/* <div onClick={closeConfirmHandler} className='d-flex align-items-center'>
                    <img className=' cursor-pointer' src={close} alt="" />
                </div> */}


            </>}
            show={show}
            size='auto'
            onHide={closeConfirmHandler}
            centered={true}
            className="bg-main rounded-20 col-12 p-40 d-flex flex-column gap-24"
            body2={
                <div>
                    <div className='d-flex justify-content-center'>
                        <h6 className='m-semibold-14-400'>Do you want to delete the {itemToDelete}?</h6>
                    </div>
                    <br />
                    <div className='d-flex justify-content-center gap-3'>
                        <button onClick={() => { closeConfirmHandler() }} type="button" className="d-flex gap-10 h-44 btn btn-outline-main rounded-10 w-120 gap-10 p-10-16 ">
                            <img src={close} alt="" />
                            <span>Cancel</span>
                        </button>
                        <button onClick={()=> { deleteFunction(requestId)}} className="d-flex justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                            {isSubmitting ? <ClipLoader /> : <> <img src={save} alt="" />
                                 <span>Confirm</span>
                            </>
                            }
                        </button>
                    </div>
                </div>
            }
            />
    )

}

export default DeleteConfirmModal;