import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const ProtectedRoutes = ({ children }) => {
    const navigate=useNavigate();
    const token=localStorage.getItem('token');
    const hasJWT = () => {
        let flag = false;
        token ? flag = true : flag = false;
        return flag;
    }
   
    useEffect(()=>{
       if(!hasJWT()){
        navigate('/login');
       }
    },[navigate])
    
   
    return children;
}
export default ProtectedRoutes