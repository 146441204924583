import axios from "axios";
export const getAllEmployees=(page,size,department_id,key)=>{
  
    let dep=department_id?._id;
    if(department_id?._id===1){
        dep=null;
    }
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/users`,
            params:{
                page:page,
                pageSize:size,
                department_id:dep,
                keyword:key,
            },
           
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}