import axios from "axios";

export const handlePay = (values) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/payroll/run`,
            data: {
             payrolls_data:values
            }
        }).then((response) => {
            resolve(response?.data?.data);
        }).catch((error) => {
            reject(error?.response)
        })

    })
}