import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination'
import left from '../assets/icons/left.svg'
import right from '../assets/icons/right.svg'
const PaginationComponent = ({ id, pageCount, onPageChange, currentPage, start, setStart, end, setEnd }) => {

    const active = currentPage;

    useEffect(() => {
        let maxLimit = 1;
        let minLimit = 1;
        if (pageCount > 5) {
            if (currentPage > 5) {
                maxLimit = currentPage;
                minLimit = currentPage - 4;
            } else {
                maxLimit = 5;
                minLimit = 1
            }

        } else {
            maxLimit = pageCount;
        }
        setEnd(maxLimit)
        setStart(minLimit)
    }, [pageCount])
    let items = [];
    for (let page = start; page <= end; page++) {
        items.push(
            <Pagination.Item
                key={page}
                className='border-0'
                active={page === active}
                onClick={() => { onPageChange(page) }}
            >
                {page}
            </Pagination.Item>
        )
    }
    const prevHandler = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        } else onPageChange(currentPage)

        if (currentPage === start && start != 1) {
            setEnd(end - 1);
            setStart(start - 1)
        }

    }
    const nextHandler = () => {
        currentPage < pageCount
            ? onPageChange(currentPage + 1)
            : onPageChange(currentPage)
        if (currentPage == end && end != pageCount) {
            setEnd(end + 1);
            setStart(start + 1);
        }

    }
  

    return (

        <Pagination size={'sm'} className={` m-24 mb-0 d-flex justify-content-between`}>
            <div>
                {(pageCount > 1 && currentPage > 1) &&
                    <Pagination.Prev
                        className='w-101 d-flex justify-content-center'
                        onClick={prevHandler}>
                        <div className=' d-flex gap-2'>
                            <img src={left} alt="" />
                            <span className='d-none d-md-block'>Previous</span>
                        </div>
                    </Pagination.Prev>}
            </div>

            <div className='d-flex'>
                {pageCount > 1 && items}
            </div>
            <div>
                {(pageCount > 1 && pageCount > currentPage) &&

                    <Pagination.Next className='w-101 d-flex justify-content-center' onClick={nextHandler}>
                        <div className='d-flex gap-2'>

                            <span className='d-none d-md-block'>Next</span>
                            <img src={right} alt="" />
                        </div>
                    </Pagination.Next>}
            </div>

        </Pagination>
    )
}
export default PaginationComponent;