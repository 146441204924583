import { Modal } from 'react-bootstrap'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import close from '../assets/icons/secondaryClose.svg';
import profilepic from '../assets/icons/profilePic.svg';
import gallery from '../assets/icons/gallery.svg';
import veusax from '../assets/icons/veusax.svg';
import closeCircle from '../assets/icons/closecircle.svg';
import save from '../assets/icons/save.svg';
import trash from '../assets/icons/trash.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router-dom';
import ModalPopup from '../components/modalpopup';
const ModalComponent = ({ setOpenModal, obj, show, closeHandler, setCardData, title }) => {

    const [initialValues, setInitialValues] = useState({

        total_salary: obj.amountTobePaid,
        addition: (
            obj.addition ? obj.addition :
                [
                    { label: "", amount: "" },
                    { label: "", amount: "" },
                    { label: "", amount: "" },
                ]),
        deduction: (
            obj.deduction ? obj.deduction :
                [
                    { label: "", amount: "" },
                    { label: "", amount: "" },
                    { label: "", amount: "" },
                ])
    })
    const navigate = useNavigate();
    const [fetchedImg, setFetchedImg] = useState("");
    const encodeFileBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
   
    const handleProfileImage = async (e, setFieldValue) => {
        let item;
        setFetchedImg("");

        if (e?.target?.files[0]?.type?.split("/")[0] === "image") {
            item = e.target.files[0];
            const base64 = await encodeFileBase64(item);
            setFieldValue("image", base64);
        }
    }
    let profileImageRef = "";
    const schema = Yup.object().shape({
        total_salary: Yup.number().typeError("Amount to be paid should be a number").max(obj.totalSalary, `Amount to be paid should be less than ${obj.totalSalary}`).required("Amount to be paid is required"),
        addition: Yup.array().of(
            Yup.object().shape({
                amount: Yup.number().typeError("Amount should be a number").positive('Amount must be a positive number'),
            })
        ),
        deduction: Yup.array().of(
            Yup.object().shape({
                amount: Yup.number().typeError("Amount should be a number").positive('Amount must be a positive number'),
            })
        ),


        // deduction: Yup.object().shape({
        //     amount: Yup.number().typeError("Amount should be a number").positive('Amount must be a positive number'),
        //     days: Yup.number().typeError("Days should be a number").positive('Days must be a positive number')
        // })
    });
    const handleSubmit = (values, { setSubmitting }) => {

        setSubmitting(false);
        const addition = values.addition.filter(el => el.label && el.amount)
        const deduction = values.deduction.filter(el => el.label && el.amount)
        let index = -1;
        setCardData(prev => {
            const element = prev.find((e, i) => {
                if (e.id == obj.id) {
                    index = i;
                }
                return e.id === obj.id
            })

            if (Object.keys(element).length > 0) {
                prev[index] = { ...obj, amountTobePaid: values.total_salary, addition: addition, deduction: deduction }
                return prev;
            }

            return prev;

        })
        setOpenModal(false);
    }

    if (!show) {
        return
    }

    return (
        <Formik enableReinitialize={true}
            validationSchema={schema}
            initialValues={initialValues} onSubmit={handleSubmit} >
            {({ values, isSubmitting, touched, errors, setFieldValue }) => (

                <div>

                    <Form>
                        {/* <button type='submit'>
                            call me
                        </button> */}
                        <ModalPopup
                            body1={<>
                                {title && <span className='extra-bold'>Salary edit</span>}
                                <div onClick={closeHandler} className='d-flex align-items-center'>
                                    <img className=' cursor-pointer' src={close} alt="" />
                                    <span>Close</span>
                                </div>


                            </>}
                            show={show}
                            size='lg'
                            onHide={closeHandler}
                            centered={true}
                            className="bg-main rounded-20 col-12 p-40 d-flex flex-column gap-24"
                            bodyClassName1="className='col-12 d-flex justify-content-between"

                            body2={
                                <Form>
                                    <div className=' rounded-20  d-flex flex-column'>
                                        <div className="accordion d-flex flex-column gap-24 " id="salaryEdit">
                                            <div className="accordion-item d-flex flex-column  p-24 gap-24">
                                                <h2 className="accordion-header" id="headingZero">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="false" aria-controls="collapseZero">
                                                        <span className='semi-bold'>Salary information</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseZero" className="accordion-collapse collapse show" aria-labelledby="headingZero" data-bs-parent="#salaryEdit">
                                                    <div className="accordion-body">
                                                        <div className=' col-12 gap-24 d-flex align-items-center'>

                                                        </div>
                                                        <div className="col-12 d-flex gap-24 flex-column">
                                                            <div className=' col-6 d-flex flex-column gap-6 '>
                                                                <span className='s-regular-small text-secondary' htmlFor="total_salary">
                                                                    Paid amount
                                                                </span>
                                                                <div>
                                                                    {obj?.paidSalary}
                                                                </div>

                                                            </div>
                                                            <div className=' col-6 d-flex flex-column gap-6 '>
                                                                <span className='s-regular-small text-secondary' htmlFor="total_salary">
                                                                    Total salary
                                                                </span>
                                                                <div>
                                                                    {obj?.totalSalary}
                                                                </div>

                                                            </div>

                                                            <div className='col-6 d-flex flex-column gap-6 '>
                                                                <label className='s-regular-small text-secondary' htmlFor="total_salary">
                                                                    Amount to be paid
                                                                </label>

                                                                <Field
                                                                    className={`${touched?.total_salary && errors?.total_salary ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`total_salary`} />
                                                                <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="total_salary" component="div" />


                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item d-flex flex-column  p-24 gap-24">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span className='semi-bold'>Addition</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#salaryEdit">
                                                    <div className=" accordion-body">
                                                        <FieldArray name='addition'>
                                                            <div className="col-12 d-flex flex-wrap">


                                                                <div className='d-flex col-12 gap-24'>
                                                                    <div className='s-regular-main col-5  '>Label</div >
                                                                    <div className='s-regular-main col-5  '>Amount</div >

                                                                </div>

                                                                {
                                                                    values.addition?.map((el, index) => {

                                                                        return <>
                                                                            <div className="m-24 col-12 d-flex gap-24 " key={index}>
                                                                                <div className='col-5  d-flex flex-column gap-6'>
                                                                                    <label className='s-regular-small text-secondary' htmlFor={`addition[${index}].label`}>
                                                                                        Eg. Bonus,House rent etc
                                                                                    </label>
                                                                                    <Field
                                                                                        className={`${touched?.addition?.[index]?.label && errors?.addition?.[index]?.label ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`addition[${index}].label`} />

                                                                                </div>

                                                                                <div className=' col-5 d-flex justify-content-end flex-column d-flex flex-column gap-6 '>

                                                                                    <Field
                                                                                        className={`${touched?.addition?.[index]?.amount && errors?.addition?.[index]?.amount ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`addition[${index}].amount`} />


                                                                                </div>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <img src={trash} alt="trash" className='m-24 cursor-pointer' onClick={() => {
                                                                                        values.addition.splice(index, 1);
                                                                                        setFieldValue("addition", values.addition)
                                                                                    }} />
                                                                                </div>




                                                                            </div>
                                                                            <div className='col-12 d-flex gap-2 mt-6'>
                                                                                <div className='col-5'>
                                                                                </div>
                                                                                <div className="col-5 d-flex justify-content-start">
                                                                                    <ErrorMessage className=" ml-24 s-regular-xsmall error-msg s-regular-small"
                                                                                        name={`addition[${index}].amount`} component="div" />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    })

                                                                }

                                                                <div className='m-24 d-flex justify-content-end col-12'>
                                                                    <button type='button' onClick={() => {

                                                                        values.addition?.push({ label: "", amount: "" })
                                                                        setFieldValue('addtion', values.addition)
                                                                    }


                                                                    } className="cursor-pointer btn-secondary rounded-10 d-flex gap-10 p-10-16 border-none">
                                                                        <img src={veusax} alt="" />
                                                                        <span className="text-add">Add field</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </FieldArray>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item p-24">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <span className='semi-bold'>Deduction</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#salaryEdit">
                                                    <div class="accordion-body">
                                                        <FieldArray name='deduction'>
                                                            <div className="col-12 d-flex flex-wrap m-24">


                                                                <div className='d-flex col-12 gap-24'>
                                                                    <div className='s-regular-main col-5  '>Label</div >
                                                                    <div className='s-regular-main col-5  '>Amount</div >

                                                                </div>

                                                                {
                                                                    values.deduction?.map((el, index) => {

                                                                        return <> <div className="m-24 col-12 d-flex gap-24" key={index}>
                                                                            <div className='col-5 d-flex flex-column gap-6'>
                                                                                <label className='s-regular-small text-secondary' htmlFor={`deduction[${index}].label`}>
                                                                                    Eg. Bonus,House rent etc
                                                                                </label>
                                                                                <Field
                                                                                    className={`${touched?.deduction?.[index]?.label && errors?.deduction?.[index]?.label ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`deduction[${index}].label`} />

                                                                            </div>
                                                                            <div className='col-5 d-flex justify-content-end flex-column d-flex flex-column gap-6 '>

                                                                                <Field
                                                                                    className={`${touched?.deduction?.[index]?.amount && errors?.deduction?.[index]?.amount ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`deduction[${index}].amount`} />
                                                                                {/* <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`deduction[${index}].amount`} component="div" /> */}


                                                                            </div>
                                                                            <div className='d-flex align-items-center'>
                                                                                <img src={trash} alt="trash" className='m-24 cursor-pointer' onClick={() => {
                                                                                    values.deduction.splice(index, 1);
                                                                                    setFieldValue("deduction", values.deduction)
                                                                                }} />
                                                                            </div>




                                                                        </div>
                                                                            <div className='col-12 d-flex gap-4 mt-6'>
                                                                                <div className='col-5'>
                                                                                </div>
                                                                                <div className="col-5 d-flex justify-content-start">
                                                                                    <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`deduction[${index}].amount`} component="div" />

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    })

                                                                }

                                                                <div className='m-24 d-flex justify-content-end col-12'>
                                                                    <button type="button" onClick={() => {
                                                                        values.deduction?.push({ label: "", amount: "" })
                                                                        setFieldValue('deduction', values.deduction)
                                                                    }} className="border-none cursor-pointer btn-secondary rounded-10 d-flex gap-10 p-10-16">
                                                                        <img src={veusax} alt="" />
                                                                        <span className="text-add">Add field</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </FieldArray>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="m-24 d-flex gap-24 col-12 col-sm -6  justify-content-end">
                                        <button onClick={() => { closeHandler() }} type="button" className="d-flex gap-10 h-44 btn btn-outline-main rounded-10 w-120 gap-10 p-10-16 ">
                                            <img src={close} alt="" />
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="d-flex justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                            {isSubmitting ? <ClipLoader /> : <> <img src={save} alt="" />
                                                <span>Save</span>
                                            </>
                                            }
                                        </button>
                                    </div>
                                </Form>
                            }
                        />
                        {/* <Modal show={show} size='lg' onHide={closeHandler} centered>
                            <div className='bg-main rounded-20 col-12 p-40 d-flex flex-column gap-24'>


                                <Modal.Body>
                                    <div className=' rounded-20  d-flex flex-column'>
                                        <div className="accordion d-flex flex-column gap-24 " id="salaryEdit">
                                            <div className="accordion-item d-flex flex-column  p-24 gap-24">
                                                <h2 className="accordion-header" id="headingZero">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="false" aria-controls="collapseZero">
                                                        <span className='semi-bold'>Salary information</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseZero" className="accordion-collapse collapse show" aria-labelledby="headingZero" data-bs-parent="#salaryEdit">
                                                    <div className="accordion-body">
                                                        <div className=' col-12 gap-24 d-flex align-items-center'>

                                                        </div>
                                                        <div className="col-12 d-flex">


                                                            <div className='col-6 d-flex flex-column gap-6 '>
                                                                <label className='s-regular-small text-secondary' htmlFor="total_salary">
                                                                    Total salary
                                                                </label>

                                                                <Field
                                                                    className={`${touched?.total_salary && errors?.total_salary ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`total_salary`} />
                                                                <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name="total_salary" component="div" />


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item d-flex flex-column  p-24 gap-24">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span className='semi-bold'>Addition</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#salaryEdit">
                                                    <div className=" accordion-body">
                                                        <FieldArray name='addition'>
                                                            <div className="col-12 d-flex flex-wrap">


                                                                <div className='d-flex col-12 gap-24'>
                                                                    <div className='s-regular-main col-5  '>Label</div >
                                                                    <div className='s-regular-main col-5  '>Amount</div >

                                                                </div>

                                                                {
                                                                    values.addition?.map((el, index) => {

                                                                        return <div className="m-24 col-12 d-flex gap-24" key={index}>
                                                                            <div className='col-5 d-flex flex-column gap-6'>
                                                                                <label className='s-regular-small text-secondary' htmlFor={`addition[${index}].label`}>
                                                                                    Eg. Bonus,House rent etc
                                                                                </label>
                                                                                <Field
                                                                                    className={`${touched?.addition?.[index]?.label && errors?.addition?.[index]?.label ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`addition[${index}].label`} />

                                                                            </div>
                                                                            <div className='col-5 d-flex justify-content-end flex-column d-flex flex-column gap-6 '>

                                                                                <Field
                                                                                    className={`${touched?.addition?.[index]?.amount && errors?.addition?.[index]?.amount ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`addition[${index}].amount`} />
                                                                                <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`addition[${index}].amount`} component="div" />


                                                                            </div>
                                                                            <img src={trash} alt="trash" className='m-24 cursor-pointer' onClick={() => {
                                                                                values.addition.splice(index, 1);
                                                                                setFieldValue("addition", values.addition)
                                                                            }} />



                                                                        </div>
                                                                    })

                                                                }

                                                                <div className='m-24 d-flex justify-content-end col-12'>
                                                                    <button onClick={() => { values.addition?.push({ label: "", amount: "" }) }} className="cursor-pointer btn-secondary rounded-10 d-flex gap-10 p-10-16">
                                                                        <img src={veusax} alt="" />
                                                                        <span className="text-add">Add bank</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </FieldArray>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item p-24">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <span className='semi-bold'>Deduction</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#salaryEdit">
                                                    <div class="accordion-body">
                                                        <FieldArray name='deduction'>
                                                            <div className="col-12 d-flex flex-wrap m-24">


                                                                <div className='d-flex col-12 gap-24'>
                                                                    <div className='s-regular-main col-5  '>Label</div >
                                                                    <div className='s-regular-main col-5  '>Amount</div >

                                                                </div>

                                                                {
                                                                    values.deduction?.map((el, index) => {

                                                                        return <div className="m-24 col-12 d-flex gap-24" key={index}>
                                                                            <div className='col-5 d-flex flex-column gap-6'>
                                                                                <label className='s-regular-small text-secondary' htmlFor={`deduction[${index}].label`}>
                                                                                    Eg. Bonus,House rent etc
                                                                                </label>
                                                                                <Field
                                                                                    className={`${touched?.deduction?.[index]?.label && errors?.deduction?.[index]?.label ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`deduction[${index}].label`} />

                                                                            </div>
                                                                            <div className='col-5 d-flex justify-content-end flex-column d-flex flex-column gap-6 '>

                                                                                <Field
                                                                                    className={`${touched?.deduction?.[index]?.amount && errors?.deduction?.[index]?.amount ? 'border-danger' : 'input-border-primary'} col-12 rounded height-50px form-control shadow-none`} name={`deduction[${index}].amount`} />
                                                                                <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={`deduction[${index}].amount`} component="div" />


                                                                            </div>
                                                                            <img src={trash} alt="trash" className='m-24 cursor-pointer' onClick={() => {
                                                                                values.deduction.splice(index, 1);
                                                                                setFieldValue("deduction", values.deduction)
                                                                            }} />



                                                                        </div>
                                                                    })

                                                                }

                                                                <div className='m-24 d-flex justify-content-end col-12'>
                                                                    <button onClick={() => { values.deduction?.push({ label: "", amount: "" }) }} className="cursor-pointer btn-secondary rounded-10 d-flex gap-10 p-10-16">
                                                                        <img src={veusax} alt="" />
                                                                        <span className="text-add">Add bank</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </FieldArray>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="m-24 d-flex gap-24 col-12 col-sm -6  justify-content-end">
                                        <button onClick={() => { closeHandler() }} type="button" className="d-flex gap-10 h-44 btn btn-outline-main rounded-10 w-120 gap-10 p-10-16 ">
                                            <img src={close} alt="" />
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="d-flex justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">
                                            {isSubmitting ? <ClipLoader /> : <> <img src={save} alt="" />
                                                <span>Save</span>
                                            </>
                                            }
                                        </button>
                                    </div>
                                </Modal.Body>
                            </div>
                        </Modal> */}
                    </Form>

                </div>

            )
            }

        </Formik >
    )
}

export default ModalComponent;