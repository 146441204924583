import axios from "axios"

export const fetchSalaryTemplate=()=>{

    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/template`,
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {

            reject(error?.response);
        })

    })
}