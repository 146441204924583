import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from 'yup';
import rightArrow from '../../assets/icons/rightArrow.svg';
import left from '../../assets/icons/left.svg';
import gallery from '../../assets/icons/gallery.svg'
import close from '../../assets/icons/close.svg'
import save from '../../assets/icons/save.svg'
import trash from '../../assets/icons/trash.svg'
import closeCircle from '../../assets/icons/closecircle.svg'
import ClipLoader from "react-spinners/ClipLoader";
import { createNewEmployee } from "../../utility/services/createNewEmployee";
import Select from "react-select"
import add from '../../assets/icons/add.svg'
import profilepic from '../../assets/icons/profilePic.svg';
import veusax from '../../assets/icons/veusax.svg';
import currency from '../../assets/icons/currency.svg';
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import calendar from '../../assets/icons/calendar2.svg';
import { fetchDepartments } from "../../utility/services/fetchDepartment";
import CustomField from "../../components/customField";
import CustomSelect from "../../components/customSelect";
import CustomRadio from "../../components/customRadio";
import { submitEmployee } from "../../utility/services/submitEmployee";
import { fetchEmployeeTypes } from "../../utility/services/fetchEmployeeTypes";
import { useSnackbar } from "notistack";
import { fetchSkills } from "../../utility/services/fetchSkills";
import { fetchSalaryTemplate } from "../../utility/services/fetchSalaryTemplate";
import { createNewOrganization, editOrganization, fetchSingleOrganization } from "../../utility/services/organizationServices";

const CreateOrganisation = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [departments, setDepartments] = useState([])
    // const  [err,setErr]=useState({
    //     emp:false,pers:false,bank:false,add:false,
    // })
    const [userId, setUserId] = useState("");
    const [showEmpSection, setEmpSection] = useState(false);
    const [showPersonalSection, setShowPesonalSection] = useState(false);
    const [showAddressInfo, setshowAddressInfo] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [showBankDetatils, setShowBankDetail] = useState(false);
    const [err, setErr] = useState(true);
    const genders = [{ label: "Male", value: 'Male' }, { label: "Female", value: 'Female' }, { label: "Other", value: "other" }]
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [fetchedImg, setFetchedImg] = useState("");

    const decryptData = () => {
        const userTypeEnc = localStorage.getItem('usertype');
        const bytes = CryptoJS.AES.decrypt(userTypeEnc, 'admin#123');
        const data = (bytes.toString(CryptoJS.enc.Utf8));
        return JSON.parse(data) === "admin"

    };
    const dateOfHire = useRef(null);
    const [allData, setAllData] = useState({});
    const [intialValues, setInitialValues] = useState({
        image: "",
        orgInfo: {
            name: "",
            email: "",
            contact_no: "",
            street: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            website: "",
            founded_year: "",
            industry: "", //Add organization user flag to true for adding user type from backend
        },

        personalInfo: {
            first_name: "",
            last_name: "",
            phone: "",
            personel_email: "",
            emergency_contact: "",
            blood_group: "",
            dob: "",
            gender: "",
            pan: "",
            official_email: "",
        },
        addressInfo: {
            address_flag: true,
            country: "",
            city: "",
            location: "",
            post_code: "",
            perm_country: "",
            perm_city: "",
            perm_location: "",
            perm_post_code: "",
        },



    })

    const validationSchema = Yup.object().shape({
        // id: Yup.number().typeError("Job ID should be a number").required("Job ID is required"),
        orgInfo: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Email address is required"),
            contact_no: Yup.string()
                .matches(/^\+[1-9]\d{1,14}$/, "Invalid contact number")
                .required('Phone number is required'),
            street: Yup.string().required("Street is required"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            country: Yup.string().required("Country is required"),
            zip_code: Yup.string().required("Zip code is required").matches(/^[0-9]{6,6}$/, 'Invalid post code'),
            website: Yup.string().required("Website is required"),
            founded_year: Yup.string().matches(/^[0-9]{4,4}$/, 'Invalid founded year').required("Founded year is required"),
            industry: Yup.string().required("Industry is required"),
        }),
        personalInfo: Yup.object().shape({
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            phone: Yup.string()
                .matches(/^\+[1-9]\d{1,14}$/, "Invalid phone number")
                .required('Phone number is required'),
            personel_email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Official email address is required"),
            official_email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address ').required("Official email address is required"),
            emergency_contact: Yup.string()
                .matches(/^\+[1-9]\d{1,14}$/, "Invalid contact number")
                .required('Emergency contact is required'),
            blood_group: Yup.string().required("Blood group is required").matches(/^(A|B|AB|O)[+-]$/, 'Invalid blood group'),
            dob: Yup.date().typeError("Invalid date of birth").required("Date of birth is required"),
            gender: Yup.string().required("Gender is required"),
            pan: Yup.string().matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/, "Invalid PAN number")
                .required('PAN is required'),
        }),


        addressInfo: Yup.object().shape({
            country: Yup.string().required("Country is required"),
            city: Yup.string().required("City is required"),
            location: Yup.string().required("Location is required"),
            post_code: Yup.string().required("Post code is required").matches(/^[0-9]{6,6}$/, 'Invalid post code'),
            perm_country: Yup.string().required("Country is required"),
            perm_city: Yup.string().required("City is required"),
            perm_location: Yup.string().required("Location is required"),
            perm_post_code: Yup.string().required("Post code is required").matches(/^[0-9]{6,6}$/, 'Invalid post code'),
        }),
    })

    const submitHandler = async (values, { setSubmitting, setTouched, setErrors }) => {
        setSubmitting(true);
        if (id) {
            let obj = {
                ...allData, ...values
            };
            if (allData.email === values?.orgInfo?.email) {
                obj = { ...obj, email: undefined }
            }
            if (allData.personel_email === values?.personalInfo?.personel_email) {
                obj = { ...obj, personel_email: undefined }
            }
            if (allData.official_email === values?.personalInfo?.official_email) {
                obj = { ...obj, official_email: undefined }
            }
            const object = { ...obj }
            try {
                const response = await editOrganization(object, id, userId && userId);
                setSubmitting(false)
                enqueueSnackbar("Organization edited successfully", { variant: 'success' })
                navigate('/organisation')
            } catch (error) {
                setSubmitting(false)

                enqueueSnackbar(error.data.message, { variant: 'error' })
                let obj = {}
                let touchObj = {};
                if (error?.data?.organization_errors?.email_unique) {
                    setEmpSection(true);
                    obj = {
                        ...obj,
                        orgInfo: {
                            email: error?.data?.organization_errors?.email_unique,
                        }

                    }
                    touchObj = {
                        ...touchObj, orgInfo: {
                            email: true,
                        }
                    }
                }
                if (error?.data?.user_errors?.official_email || error?.data?.user_errors?.official_email_invalid || error?.data?.user_errors?.personel_email) {
                    setShowPesonalSection(true);

                    obj = {
                        ...obj,
                        personalInfo: {
                            official_email: error?.data?.user_errors?.official_email || error?.data?.user_errors?.official_email_invalid,
                            personel_email: error?.data?.user_errors?.personel_email,

                        }

                    }
                    touchObj = {
                        ...touchObj, orgInfo: {
                            official_email: (error?.data?.user_errors?.official_email || error?.data?.user_errors?.official_email_invalid) ? true : false,
                            personel_email: error?.data?.user_errors?.personel_email ? true : false
                        }
                    }
                }
                setErrors(obj);
                // setTouched(touchObj);
            }
        }
        else {
            try {
                const response = await createNewOrganization(values);
                setSubmitting(false)
                enqueueSnackbar("Organization created successfully", { variant: 'success' })
                navigate('/organisation')
            } catch (error) {
                setSubmitting(false)

                enqueueSnackbar(error.data.message, { variant: 'error' })

                let obj = {}
                let touchObj = {};
                if (error.data.organization_errors.email) {
                    setEmpSection(true);
                    obj = {
                        ...obj,
                        orgInfo: {
                            email: error.data.organization_errors.email,
                        }

                    }
                    touchObj = {
                        ...touchObj, orgInfo: {
                            email: true,
                        }
                    }
                }
                if (error.data.errors.personel_email || error.data.errors.personel_email_invalid) {
                    setShowPesonalSection(true);

                    obj = {
                        ...obj,
                        personalInfo: {
                            email: error.data.errors.personel_email || error.data.errors.personel_email_invalid,


                        }

                    }
                    touchObj = {
                        ...touchObj, empInfo: {
                            employee_email: true,
                        }
                    }
                }
                setErrors(obj);
                // setTouched(touchObj);
            }
        }
    }


    let profileImageRef = "";

    const encodeFileBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleProfileImage = async (e, setFieldValue) => {
        let item;


        if (e?.target?.files[0]?.type?.split("/")[0] === "image") {
            item = e.target.files[0];
            const base64 = await encodeFileBase64(item);
            setFieldValue("image", base64);
        }
    }

    const [formKey, setFormKey] = useState(0)

    const fetchSingleOrganizations = async () => {
        setLoadingData(true);
        try {
            const response = await fetchSingleOrganization(id);
            setLoadingData(false);
            setUserId(response?.user?._id);
            setFetchedImg(response?.user?.personel_details?.image);
            setInitialValues({
                image: response?.user?.personel_details?.image,
                orgInfo: {
                    name: response?.name,
                    email: response?.email,
                    contact_no: response?.contact_no,
                    street: response?.address?.state,
                    city: response?.address?.city,
                    state: response?.address?.state,
                    country: response?.address?.country,
                    zip_code: response?.address?.zipCode,
                    website: response?.website,
                    founded_year: response?.founded_year,
                    industry: response?.industry, //Add organization user flag to true for adding user type from backend
                },
                personalInfo: {
                    first_name: response?.user?.personel_details?.first_name,
                    last_name: response?.user?.personel_details?.last_name,
                    phone: response?.user?.personel_details?.phone,
                    personel_email: response?.user?.personel_details?.personel_email,
                    official_email: response?.user?.official_details?.official_email,
                    emergency_contact: response?.user?.personel_details?.emergency_contact,
                    blood_group: response?.user?.personel_details?.blood_group,
                    dob: response?.user?.personel_details?.dob,
                    gender: response?.user?.personel_details && response?.user?.personel_details?.gender,
                    pan: response?.user?.personel_details?.pan,
                },
                addressInfo: {
                    address_flag: true,
                    country: response?.user?.contact_details?.current_address?.country,
                    city: response?.user?.contact_details?.current_address?.state,
                    location: response?.user?.contact_details?.current_address?.address,
                    post_code: response?.user?.contact_details?.current_address?.pincode,
                    perm_country: response?.user?.contact_details?.permanent_address?.country,
                    perm_city: response?.user?.contact_details?.permanent_address?.state,
                    perm_location: response?.user?.contact_details?.permanent_address?.address,
                    perm_post_code: response?.user?.contact_details?.permanent_address?.pincode,
                },

            });
            setAllData({
                image: response?.user?.personel_details?.image,
                name: response?.name,
                email: response?.email,
                contact_no: response?.contact_no,
                street: response?.address?.state,
                city: response?.address?.city,
                state: response?.address?.state,
                country: response?.address?.country,
                zip_code: response?.address?.zipCode,
                website: response?.website,
                founded_year: response?.founded_year,
                industry: response?.industry, //Add organization user flag to true for adding user type from backend
                first_name: response?.user?.personel_details?.first_name,
                last_name: response?.user?.personel_details?.last_name,
                phone: response?.user?.personel_details?.phone,
                personel_email: response?.user?.personel_details?.personel_email,
                official_email: response?.user?.official_details?.official_email,
                emergency_contact: response?.user?.personel_details?.emergency_contact,
                blood_group: response?.user?.personel_details?.blood_group,
                dob: response?.user?.personel_details?.dob,
                gender: response?.user?.personel_details && response?.user?.personel_details?.gender,
                pan: response?.user?.personel_details?.pan,
                address_flag: true,
                country: response?.user?.contact_details?.current_address?.country,
                city: response?.user?.contact_details?.current_address?.state,
                location: response?.user?.contact_details?.current_address?.address,
                post_code: response?.user?.contact_details?.current_address?.pincode,
                perm_country: response?.user?.contact_details?.permanent_address?.country,
                perm_city: response?.user?.contact_details?.permanent_address?.state,
                perm_location: response?.user?.contact_details?.permanent_address?.address,
                perm_post_code: response?.user?.contact_details?.permanent_address?.pincode,
            })
        }
        catch (error) {
            setLoadingData(false);
        }

    }

    useEffect(() => {
        fetchSingleOrganizations();
    }, [id])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <Formik
            key={formKey}
            initialValues={intialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}

            onSubmit={submitHandler}
        >
            {({ isSubmitting, validateForm, handleChange, touched, values, setTouched, setFieldValue, errors, setErrors }) => {
                const removeSkill = (id) => { //remove skill
                    const removedItemArray = values.skills.filter(el => (
                        el.id !== id
                    ))
                    setFieldValue("skills", removedItemArray);
                }





                const addASkill = () => { //Add new Skill
                    if (Object?.keys(values?.skill)?.length == 0) {
                        return;
                    }
                    const object = values?.skills?.find(el => (
                        el.id === values?.skill?.value
                    ))


                    if (object && Object?.keys(object)?.length > 0) {

                        return;
                    } else {
                        const newSkillArr = [...values.skills, { id: values.skill?.value, value: values.skill?.label }]
                        setFieldValue('skills', newSkillArr);


                    }
                }
                setEmpSection((touched?.orgInfo && (Object?.keys(touched?.orgInfo)?.length > 0 && errors?.orgInfo && Object?.keys(errors?.orgInfo)?.length > 0)));
                setShowPesonalSection((touched?.personalInfo && (Object?.keys(touched?.personalInfo)?.length > 0 && errors?.personalInfo)));
                setshowAddressInfo((touched?.addressInfo && (Object?.keys(touched?.addressInfo)?.length > 0 && errors?.addressInfo && Object?.keys(errors?.addressInfo)?.length > 0)))
                setShowBankDetail((touched?.bankDetails && ((touched?.bankDetails)?.length > 0 && errors?.bankDetails && (errors?.bankDetails)?.length > 0)))
                // setShowBankDetail((touched?.bankDetails && ()))


                return (
                    <Form>
                        <div className="d-flex flex-column align-items-center">
                            <div className="col-10  m-24 d-flex justify-content-between flex-wrap">
                                <div className="d-flex align-items-center gap-2">
                                    {/* <Link to='Home' className=" s-regular decoration-none text-primary cursor-pointer">Home</Link> */}
                                    {/* <img src={rightArrow} alt="right" /> */}
                                    <Link to="/organisation" className="s-regular decoration-none cursor-pointer text-primary">Organization</Link>
                                    <img src={rightArrow} alt="right" />
                                    <span className="s-regular cursor-pointer text-primary">Create</span>
                                </div>


                            </div>
                            {/* <div className="" 
                            onClick={()=>{ 
                                enqueueSnackbar('This is a snackbar message', { variant: 'success' })}}>
                                show snack
                            </div> */}
                            <div className="d-flex col-10 mt-40  justify-content-between align-items-center">
                                <div className="col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                                    <div className="d-flex gap-10 col-12 col-sm-6">
                                        <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" />
                                        <span className="extra-bold">{id ? 'Edit' : 'Add'} organization {loadingData && <ClipLoader size={18} />}</span>

                                    </div>
                                    <div className="d-flex gap-24 col-12 col-sm -6  justify-content-end">
                                        <button onClick={() => {
                                            navigate('/employees')

                                        }} type="button" className="d-flex gap-10 h-44 btn btn-outline-main rounded-10 w-120 gap-10 p-10-16 ">
                                            <img src={close} alt="" />
                                            <span>Cancel</span>
                                        </button>


                                        <button type="submit" onClick={
                                            () => {
                                                setTouched({});

                                            }
                                        }

                                            className="d-flex align-items-center justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 ">

                                            {isSubmitting ? <ClipLoader /> : <> <img src={save} alt="" />
                                                <span>Save</span>
                                            </>
                                            }
                                        </button>




                                    </div>

                                </div>

                            </div>
                            <div className="col-10 rounded-20 bg-white d-flex flex-column m-24 p-24 ">
                                <div className="accordion d-flex flex-column gap-24" id="accordionCreateEmployee">
                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">
                                        {/* <Formik initialValues={initialEmployeeInfo}  validationSchema={validationSchemaEmployeeInfo}> */}
                                        <h2 onClick={() => { setTouched({ ...touched }) }} className="accordion-header" id="headingZero">
                                            <button className={`accordion-button  border-0 semi-bold collapsed `}

                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                                Organization information
                                            </button>
                                        </h2>

                                        <div id="collapseZero" className={`${showEmpSection ? 'show ' : ' '} accordion-collapse show`} aria-labelledby="headingZero" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body d-flex flex-wrap gap-24">

                                                <CustomField
                                                    id="orgInfo.name"
                                                    name="name"
                                                    placeholder="Add name"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    label="Name"
                                                    touched={touched?.orgInfo}
                                                    errors={errors.orgInfo}
                                                />
                                                <CustomField
                                                    id="orgInfo.email"
                                                    label="Email"
                                                    placeholder="Add email"
                                                    name="email"
                                                    touched={touched.orgInfo} errors={errors.orgInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    id="orgInfo.contact_no"
                                                    label="Contact number"
                                                    name="contact_no"
                                                    placeholder="+911231231234"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.orgInfo}
                                                    errors={errors.orgInfo}
                                                />

                                                <CustomField
                                                    id="orgInfo.website"
                                                    name="website"
                                                    label="Website"
                                                    placeholder="Add website"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.orgInfo}
                                                    //    fieldClass={!values?.orgInfo?.website ? "custom-input-date" : ""}
                                                    errors={errors.orgInfo}
                                                />

                                                <CustomField
                                                    id="orgInfo.founded_year"
                                                    name="founded_year"
                                                    label="Founded year"
                                                    placeholder="Enter founded year"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.orgInfo}
                                                    // fieldClass={!values?.orgInfo?.date_of_hire ? "custom-input-date" : ""}
                                                    errors={errors.orgInfo}
                                                />

                                                <CustomField
                                                    id="orgInfo.industry"
                                                    label="Industry"
                                                    name="industry"
                                                    placeholder="Add industry"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.orgInfo}
                                                    errors={errors.orgInfo}
                                                />

                                                <CustomField
                                                    id="orgInfo.street"
                                                    label="Street"
                                                    name="street"
                                                    placeholder="Add Street"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.orgInfo}
                                                    errors={errors.orgInfo}
                                                />
                                                <CustomField
                                                    id="orgInfo.city"
                                                    label="City"
                                                    name="city"
                                                    placeholder="Add city"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.orgInfo}
                                                    errors={errors.orgInfo}
                                                />


                                                <CustomField
                                                    id="orgInfo.state"
                                                    name="state"
                                                    label="State"
                                                    placeholder="Add state"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.orgInfo}
                                                    // fieldClass={!values?.orgInfo?.state ? "custom-input-date" : ""}
                                                    errors={errors.orgInfo}
                                                />

                                                <CustomField
                                                    id="orgInfo.country"
                                                    name="country"
                                                    label="Country"
                                                    placeholder="Add country"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.orgInfo}
                                                    // fieldClass={!values?.orgInfo?.country ? "custom-input-date" : ""}
                                                    errors={errors.orgInfo}
                                                />

                                                <CustomField
                                                    id="orgInfo.zip_code"
                                                    label="Zip code"
                                                    name="zip_code"
                                                    placeholder="Add zip code"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.orgInfo}
                                                    errors={errors.orgInfo}
                                                />

                                            </div>
                                        </div>
                                        {/* </Formik> */}

                                    </div>


                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button semi-bold border-0 semi-bold " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Organization admin information
                                            </button>
                                        </h2>

                                        <div id="collapseOne" className={`${showPersonalSection ? "show " : " "} accordion-collapse collapse show`} aria-labelledby="headingOne" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body d-flex flex-wrap gap-24">
                                                <div className=' col-12 gap-24 d-flex align-items-center'>

                                                    <div className=" position-relative">
                                                        {values?.image && <img 
                                                        src={closeCircle} className="cursor-pointer position-absolute left-auto right-0" alt="remove" onClick={() => {
                                                            profileImageRef.value = null;
                                                            setFieldValue('image', "");
                                                            setFetchedImg("")

                                                        }} />}
                                                        <img className='w-100px h-100px rounded-50' src={fetchedImg ? process.env.REACT_APP_BASE_URL + "/" + values.image : values.image ? values.image : profilepic} alt="profiles" />
                                                    </div>

                                                    <div className='d-flex flex-column gap-6'>
                                                        <div onClick={() => profileImageRef.click()} className=" w-50px w-169 border-success rounded-10 p-10 d-flex gap-10 cursor-pointer">

                                                            <img src={gallery} alt="gallery" />
                                                            <span className="d-none  d-md-block">Upload photo</span>

                                                        </div>
                                                        <input type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            hidden={true}
                                                            ref={refParam => profileImageRef = refParam}
                                                            onChange={(e) => {
                                                                handleProfileImage(e, setFieldValue);
                                                            }}

                                                        />
                                                        <div className="text-secondary s-regular-small">Atleast 256x256 PNG or JPG file</div>
                                                    </div>
                                                    {/* {console.log(errors.orgInfo)} */}
                                                </div>
                                                <CustomField
                                                    id="personalInfo.first_name"
                                                    label="First name"
                                                    placeholder="Add first name"
                                                    name="first_name"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    id="personalInfo.last_name"
                                                    label="Last name"
                                                    placeholder="Add last name"
                                                    name="last_name"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    id="personalInfo.personel_email"
                                                    label="Personel email"
                                                    placeholder="Add personal email"
                                                    name="personel_email"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    id="personalInfo.official_email"
                                                    label="Official email"
                                                    name="official_email"
                                                    placeholder="Add official email"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched?.personalInfo}
                                                    errors={errors.personalInfo}
                                                />

                                                <CustomField
                                                    id="personalInfo.phone"
                                                    label="Mobile"
                                                    placeholder="+911231231234"
                                                    name="phone"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />

                                                <CustomField
                                                    id="personalInfo.emergency_contact"
                                                    placeholder="+911231231234"
                                                    label="Emergency contact"
                                                    name="emergency_contact"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />
                                                <CustomSelect
                                                    id="gender"
                                                    values={values.personalInfo}
                                                    name="gender"
                                                    options={genders}
                                                    label="Gender"
                                                    touched={touched?.personalInfo}
                                                    placeholder="Select gender"
                                                    touchedObj={{ ...touched, personalInfo: { ...touched.personalInfo, gender: true } }}
                                                    errors={errors.personalInfo}
                                                    setTouched={setTouched}
                                                    className="col-12 col-md-5 col-lg-3"
                                                    setFieldValue={setFieldValue}

                                                />
                                                <CustomField
                                                    id="personalInfo.blood_group"
                                                    label="Blood group"
                                                    placeholder="Add blood group"
                                                    name="blood_group"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />
                                                <CustomField
                                                    label="Date of birth"
                                                    id="personalInfo.dob"
                                                    ref={dateOfHire}
                                                    // onKeyDown={() => { return false }}
                                                    name="dob"
                                                    // fieldClass={`${values.personalInfo.dob ? "" : " custom-input-date"} `}
                                                    // placeholder="Add blood group"
                                                    type="date"
                                                    className="col-12 col-md-5 col-lg-3"
                                                    touched={touched.personalInfo}
                                                    errors={errors.personalInfo}
                                                    max={new Date().toISOString().split('T')[0]}
                                                />


                                                <CustomField
                                                    id="personalInfo.pan"
                                                    label="PAN"
                                                    placeholder="Add PAN"
                                                    name="pan"
                                                    touched={touched.personalInfo} errors={errors.personalInfo}
                                                    className="col-12 col-md-5 col-lg-3"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item d-flex flex-column gap-24 border-secondary p-24">

                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button semi-bold border-0 semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Address
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className={`accordion-collapse collapse ${showAddressInfo && "show "} show`} aria-labelledby="headingTwo" data-bs-parent="#accordionCreateEmployee">
                                            <div className="accordion-body">
                                                <div className="m-24 col-12">
                                                    <span className=" s-regular-semi-bold">Current address</span>
                                                    <div className="d-flex gap-md-24  mt-0  flex-wrap">


                                                        <CustomField
                                                            id="addressInfo.location"
                                                            as="textarea"
                                                            placeholder="Add address"
                                                            label="Address"
                                                            fieldClass="height-200"
                                                            name="location"
                                                            rows="6"
                                                            touched={touched.addressInfo}
                                                            errors={errors?.addressInfo}
                                                            className=" col-lg-3 col-md-5 col-12 m-24"
                                                        />


                                                        <div className=" col-12 col-md-5 col-lg-3  justify-content-between m-24">

                                                            <CustomField
                                                                label="Country"
                                                                id="addressInfo.country"
                                                                placeholder="Add country"
                                                                name="country"
                                                                touched={touched.addressInfo}
                                                                errors={errors.addressInfo}
                                                            />
                                                            <CustomField
                                                                label="City/State"
                                                                placeholder="Add state"
                                                                id="addressInfo.city"
                                                                name="city"
                                                                touched={touched.addressInfo}
                                                                className="m-24"
                                                                errors={errors.addressInfo}
                                                            />
                                                        </div>

                                                        <CustomField
                                                            label="Post code"
                                                            id="addressInfo.post_code"
                                                            name="post_code"
                                                            placeholder="Add post code"
                                                            touched={touched.addressInfo}
                                                            className="col-lg-3 col-md-5 col-12 m-24"
                                                            errors={errors.addressInfo}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="m-24 col-12 ">
                                                    <div className="col-12 d-flex align-items-md-center flex-md-row flex-column gap-24">
                                                        <span className=" s-regular-semi-bold">Permanent address</span>
                                                        <div className="d-flex gap-10">

                                                            <input
                                                                onChange={(e) => {
                                                                    if (!e.target.checked) {
                                                                        setFieldValue("addressInfo.perm_location", "")
                                                                        setFieldValue("addressInfo.perm_city", "")
                                                                        setFieldValue("addressInfo.perm_country", "")
                                                                        setFieldValue("addressInfo.perm_post_code", "")
                                                                        return;
                                                                    }
                                                                    setFieldValue("addressInfo.perm_location", values.addressInfo.location)
                                                                    setFieldValue("addressInfo.perm_city", values.addressInfo.city)
                                                                    setFieldValue("addressInfo.perm_country", values.addressInfo.country)
                                                                    setFieldValue("addressInfo.perm_post_code", values.addressInfo.post_code)
                                                                    setTouched({})
                                                                }}
                                                                className="border-primary checkbox rounded-6 cursor-pointer" type="checkbox" />
                                                            <span className="text-secondary">Same as the current address</span>
                                                        </div>
                                                    </div>
                                                    <div className=" d-flex gap-md-24  mt-0 d-flex flex-column flex-md-row gap-6 flex-wrap">


                                                        <CustomField
                                                            id="addressInfo.perm_location"
                                                            as="textarea"
                                                            label="Address"
                                                            fieldClass="height-200"
                                                            placeholder="Add address"
                                                            name="perm_location"
                                                            rows="6"
                                                            touched={touched.addressInfo}
                                                            errors={errors?.addressInfo}
                                                            className=" col-lg-3 col-md-5 col-12 m-24"
                                                        />
                                                        <div className="d-flex flex-column col-12 col-md-5 col-lg-3 justify-content-between m-24">

                                                            <CustomField
                                                                label="Country"
                                                                id="addressInfo.perm_country"
                                                                name="perm_country"
                                                                placeholder="Add country"
                                                                touched={touched.addressInfo}
                                                                errors={errors.addressInfo}
                                                            />
                                                            <CustomField
                                                                label="City/State"
                                                                id="addressInfo.perm_city"
                                                                name="perm_city"
                                                                placeholder="Add state"
                                                                touched={touched.addressInfo}
                                                                className="m-24"
                                                                errors={errors.addressInfo}
                                                            />
                                                        </div>
                                                        <CustomField
                                                            label="Post code"
                                                            id="addressInfo.perm_post_code"
                                                            name="perm_post_code"
                                                            placeholder="Add post code"
                                                            touched={touched.addressInfo}
                                                            className="col-lg-3 col-md-5 col-12 m-24"
                                                            errors={errors.addressInfo}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Form>
                )
            }
            }
        </Formik>
    )
}
export default CreateOrganisation;