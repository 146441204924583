import axios from "axios"

export const fetchSingleEmployeeData=(id)=>{

    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
  
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/users/${id}`,
           
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {

            reject(error?.response);
        })

    })
}