import React, { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import rightArrow from '../../assets/icons/rightArrow.svg';
import Search from '../../components/search';
import edit from '../../assets/icons/edit.svg';
import trash from '../../assets/icons/trash.svg';
import add from '../../assets/icons/add.svg'
import ClipLoader from 'react-spinners/ClipLoader';
import DeleteConfirmModal from '../../components/deleteConfirmModal';
import { useSnackbar } from "notistack";
import PaginationComponent from '../../components/pagination';
import { PaginationContext } from "../../context/paginationContext";
import { deleteEmployeeType, fetchAllEmployeeTypes } from '../../utility/services/employeeTypeServices';
import CreateEmployeeType from './addEmploymentType';

const EmployeeTypeList = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [employeeTypes, setEmployeeTypes] = useState()
    const [searchKey, setSearchKey] = useState("");
    const [employeeTypeId, setEmployeeTypeId] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [pageCount, setPageCount] = useState("");
    const {
        employeeTypeStart, setEmployeeTypeStart,
        employeeTypeEnd, setEmployeeTypeEnd,
        employeeTypeCurrentPage, setEmployeeTypeCurrentPage,
    } = useContext(PaginationContext)
    
    const fetchEmployeeTypes = async (values) => {
        if(values) setEmployeeTypeCurrentPage(values);
        setLoading(true);
        try {
            const response = await fetchAllEmployeeTypes(searchKey, employeeTypeCurrentPage, 5);
            setEmployeeTypes(response?.datas);
            setPageCount(response?.total_pages)
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }

    }
    const closeHandler = () => {
        setOpenModal(false)
        setEmployeeTypeId("");
    }

    const closeConfirmHandler = () => {
        setOpenConfirmModal(false);
        setEmployeeTypeId("");
    }


    const openEditModal = (id) => {
        setEmployeeTypeId(id);
        setOpenModal(true);
    }

    const openDeleteModal = (id) => {
        setEmployeeTypeId(id);
        setOpenConfirmModal(true);
    }

    const deleteFunction = async (id) => {
        setLoadingDelete(true);
        try {
            const response = await deleteEmployeeType(id);
            setLoadingDelete(false);
            enqueueSnackbar("Employee type deleted successfully", { variant: 'success' });
            setOpenConfirmModal(false);
            setEmployeeTypeId("");
            fetchEmployeeTypes();
        } catch (error) {
            setLoadingDelete(false);
            enqueueSnackbar(error.data.message, { variant: 'error' });
            setOpenConfirmModal(false);
            setEmployeeTypeId("");
            fetchEmployeeTypes();
        }
    }

    const onPageChange = useCallback((page) => {

        setEmployeeTypeCurrentPage(page)

    }, [])
    useEffect(() => {
        fetchEmployeeTypes(1);
    }, []);

    // useEffect(() => {
    //    if(skills){
    //        const total = skills.length;
    //         setPageCount(Math.ceil(total / limit));
    //    }
    //   }, [skills])


    useEffect(() => {
        fetchEmployeeTypes();
    }, [searchKey, employeeTypeCurrentPage]);

    return (
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-10">
                    <Link to='/' className="s-regular decoration-none text-primary cursor-pointer">Home</Link>
                    <img src={rightArrow} alt="" />
                    <span className="s-regular cursor-pointer text-primary">Employment types</span>
                </div>
                {/* <div className="d-flex rounded bg-white">
                    <input placeholder="Search"
                        onChange={(e) => {
                            setSearchKey(e.target.value)
                        }}
                        className="p-10-16 rounded bg-pirmary outline-0 border-0" type="text" />
                    <img className="bg-white rounded p-10-16" src={search} alt="search" />
                </div> */}
                <Search setData={setSearchKey}/>
            </div>
            <div className="d-flex col-10 justify-content-between align-items-center mb-24 mt-40">

                <div className="d-flex col-12 col-lg-6 gap-24 align-items-center">
                    {/* <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" /> */}
                    <span className="extra-bold">Employment types</span>
                </div>
                <div>
                    <button
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        type="submit" className="btn-primary h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                        <img src={add} alt="" />
                        <span className="d-none d-sm-block">New employment type</span>
                    </button>
                </div>

            </div>
            <div className="col-10 row bg-white rounded-20 p-24 d-flex justify-content-between d-flex">
                {loading ? 
                    <div className='col-12 d-flex justify-content-center height-50px align-items-center'>
                        <ClipLoader />
                    </div>
                :   <div className='col-12'>
                        <div className='d-flex col-12 border-bottom-success p-12-0'>
                            <div className="col-6 s-regular-small text-secondary ">
                                Name
                            </div>
                        </div>
                        {employeeTypes && employeeTypes.map((e, index) => (
                                <div className='col-12 d-flex p-12-0 border-bottom-success' key={index}>
                                    <div className="col-6">
                                        {e.employee_type}
                                    </div>
                                    <div className="d-flex justify-content-end col-6 gap-4">
                                        <div onClick={() => {
                                            openEditModal(e._id)
                                            }} className="d-flex gap-2 align-items-center cursor-pointer" title="edit">

                                            <img src={edit} alt="edit" />
                                            <span className='text-danger s-regular  d-none d-lg-block '>Edit</span >

                                        </div>
                                        <div onClick={() => {
                                            openDeleteModal(e._id);
                                        }} className="d-flex gap-2 align-items-center cursor-pointer" title="delete">

                                            <img src={trash} alt="delete" />
                                            <span className='text-danger-secondary s-regular  d-none d-lg-block '>Delete</span >

                                        </div>
                                    </div>
                                </div>
                                
                        ))
                    }
                    {employeeTypes?.length===0 && 
                        <div className={"d-flex justify-content-center text-secondary mt-3"}>No datas available</div>
                    
                    }

                    </div>
                }
                 {employeeTypeCurrentPage && 
                    <PaginationComponent
                        id={"available"}
                        pageCount={pageCount}
                        start={employeeTypeStart}
                        end={employeeTypeEnd}
                        setStart={setEmployeeTypeStart}
                        setEnd={setEmployeeTypeEnd}
                        currentPage={employeeTypeCurrentPage}
                        setCurrentPage={setEmployeeTypeCurrentPage}
                        onPageChange={onPageChange}
                    />
                }
            </div>
            {/* <div className="col-10">
            
            </div> */}
            {openModal && 
                <CreateEmployeeType 
                    setOpenModal={setOpenModal}
                    show={openModal}
                    closeHandler={closeHandler}
                    fetchEmployeeTypes={fetchEmployeeTypes}
                    empTypeId={employeeTypeId}
                    setEmployeeTypeId={setEmployeeTypeId}
                />
            } 
            {openConfirmModal && 
                <DeleteConfirmModal 
                    setOpenConfirm={setOpenConfirmModal}
                    show={openConfirmModal}
                    closeConfirmHandler={closeConfirmHandler}
                    requestId={employeeTypeId}
                    itemToDelete={"employment type"}
                    deleteFunction={deleteFunction}
                    isSubmitting={loadingDelete}
                />
            }


        </div>
    )

}

export default EmployeeTypeList;