import axios from "axios"

export const fetchAllOrganizations=(values, page, size)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/organizations`,
            params:{
                page:page,
                pageSize:size,
                keyword:values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const fetchSingleOrganization=(id)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/organization/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const createNewOrganization = (values) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/organizations`,
            data: {
                //emp-info
                // employment_information_flag : "true",
                image: values.image,
                name: values.orgInfo.name,
                contact_no: values.orgInfo.contact_no,  //job id
                street: values.orgInfo.street,
                city: values.orgInfo.city,
                state: values.orgInfo.state,
                country: values.orgInfo.country,
                zipCode: values.orgInfo.zip_code,
                website: values.orgInfo.website,
                founded_year: values.orgInfo.founded_year, //active or inactive
                industry: values.orgInfo.industry,
                official_email: values.personalInfo.official_email,
                email: values.orgInfo.email,
                employment_information_flag: "true",
                organization_user_flag: "true",
                //personal-info
                personel_information_flag :"true",
                first_name: values.personalInfo.first_name,
                last_name: values.personalInfo.last_name,
                phone: values.personalInfo.phone,
                personel_email: values.personalInfo.personel_email,
                emergency_contact: values.personalInfo.emergency_contact,
                gender: values.personalInfo.gender,
                blood_group: values.personalInfo.blood_group,
                pan:values.personalInfo.pan,
                dob: values.personalInfo.dob,
               
                //address info
                address_flag : (values.addressInfo.location ? "true" : "false"),
                current_address: values.addressInfo.location,
                current_country: values.addressInfo.country,
                current_state: values.addressInfo.city,
                current_pincode: values.addressInfo.post_code,
                permanent_address: values.addressInfo.perm_location,
                permanent_country: values.addressInfo.perm_country,
                permanent_state: values.addressInfo.perm_city,
                permanent_pincode: values.addressInfo.perm_post_code,
            

            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const editOrganization = (values, id, userId) => {
    let org_email;
    let personal_email;
    let off_email;
    if(values?.email===undefined){
        org_email = values?.email;
    }
    else org_email = values?.orgInfo?.email;
    if(values?.personel_email === undefined) personal_email = values?.personel_email;
    else personal_email = values?.personalInfo?.personel_email;
    if(values?.official_email === undefined) off_email = values?.official_email;
    else off_email = values?.personalInfo?.official_email;
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BASE_URL}/organizations/${id}`,
            data: {
                //emp-info
                // employment_information_flag : "true",
                user_id: userId,
                image: values.image,
                name: values.orgInfo.name,
                contact_no: values.orgInfo.contact_no,  //job id
                street: values.orgInfo.street,
                city: values.orgInfo.city,
                state: values.orgInfo.state,
                country: values.orgInfo.country,
                zipCode: values.orgInfo.zip_code,
                website: values.orgInfo.website,
                founded_year: values.orgInfo.founded_year?.toString(), //active or inactive
                industry: values.orgInfo.industry,
                email: org_email,
                employment_information_flag: "true",
                organization_user_flag: "true",
                //personal-info
                personel_information_flag :"true",
                first_name: values.personalInfo.first_name,
                last_name: values.personalInfo.last_name,
                phone: values.personalInfo.phone,
                personel_email: personal_email,
                official_email: off_email,
                emergency_contact: values.personalInfo.emergency_contact,
                gender: values.personalInfo.gender,
                blood_group: values.personalInfo.blood_group,
                pan:values.personalInfo.pan,
                dob: values.personalInfo.dob,
               
                //address info
                address_flag : (values.addressInfo.location ? "true" : "false"),
                current_address: values.addressInfo.location,
                current_country: values.addressInfo.country,
                current_state: values.addressInfo.city,
                current_pincode: values.addressInfo.post_code?.toString(),
                permanent_address: values.addressInfo.perm_location,
                permanent_country: values.addressInfo.perm_country,
                permanent_state: values.addressInfo.perm_city,
                permanent_pincode: values.addressInfo.perm_post_code?.toString(),
            

            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}