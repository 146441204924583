import React from 'react';
import { useParams } from 'react-router';
import { GlobalContext } from '../../context/globalContext';

const ZohoSigninRedirect = () => {

    const params = useParams();
    const { showHeader, setShowHeader } = React.useContext(GlobalContext)

    React.useEffect(() => {
        setShowHeader(false);
        return () => {
            setShowHeader(true);
        }
    }, [])

    return( 
        <div className="h-100 d-flex justify-content-center align-items-center">Please wait while we redirect you to the page</div>
    )
}

export default ZohoSigninRedirect;