import axios from "axios";



export const Logout = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/logout`,
            
        }).then((response) => {
            resolve(response.data.data);
            localStorage.setItem("token","")
            localStorage.removeItem('usertype');
        }).catch((error) => {
            reject(error?.response);
        })

    })
}