import { useEffect, useMemo, useContext } from 'react';
import search from '../assets/icons/search.svg'
import debounce from 'lodash.debounce';
import { PaginationContext } from "../context/paginationContext";

const Search = ({ setData }) => {

  const {
    setSkillCurrentPage, setEmployeeTypeCurrentPage, setDepartmentCurrentPage
  } = useContext(PaginationContext)
  const handleChange = (e) => {
    setSkillCurrentPage(1);
    setEmployeeTypeCurrentPage(1);
    setDepartmentCurrentPage(1);
    setData(e.target.value);
  }


  const debouncedResults = useMemo((e) => {
    return debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });


  return (<div className="d-flex rounded bg-white">
    <input placeholder="Search"
      onChange={debouncedResults}
      className="p-10-16 rounded bg-pirmary outline-0 border-0" type="text" />
    <img className="bg-white rounded p-10-16" src={search} alt="search" />
  </div>)
}

export default Search;
