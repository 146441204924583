import { GlobalContext } from "../context/globalContext";
import notificationIcon from '../assets/icons/notification.svg'
import { Link, useParams } from "react-router-dom";
import profilepic from '../assets/icons/profilePic.svg'
import { useContext, useEffect, useState } from "react";
import close from '../assets/icons/close.svg'
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { getProfileData } from "../utility/services/profileData";
import { Logout } from "../utility/services/logout";
import { login } from "../utility/services/service";
  const Header = () => {
    const {userName,setUserName, profileData, setProfileData } = useContext(GlobalContext);
    const [userType,setUserType]=useState("");

   
    const decryptData =async () => {
        const userTypeEnc = localStorage.getItem('usertype');

        if(!userTypeEnc){
            return;
        }
        const bytes = CryptoJS.AES.decrypt(userTypeEnc, 'admin#123');
        if(bytes){
        const data = (bytes.toString(CryptoJS.enc.Utf8));
        
        setUserType(JSON.parse(data))
        }
        
      };
    const navigate = useNavigate();
    const location = useLocation();
    const [openBox, setOpenBox] = useState(false);
    const replaceImage = (error) => {
        error.target.src = profilepic;
    }

    const logoutHandler = async () => {

        try {
            const response = await Logout();
            setOpenBox(false);
            localStorage.setItem('token', "");
            localStorage.setItem('user',"");
            localStorage.setItem('usertype',"");
            navigate('/login',{replace:true})
        } catch (error) {
        }

    }

    const fetchProfileData = async () => {
        try {
            const response = await getProfileData();
            setProfileData(response)
            // setProfileData()
            setUserName(response.personel_details.first_name+" "+response.personel_details.last_name)
          
        } catch (error) {

        }


    }
    const token = localStorage.getItem('token');
   
    useEffect(() => {
        token && fetchProfileData();
        const userTypeEnc=localStorage.getItem('usertype');
        decryptData(userTypeEnc)
    }, [])


    return <div className="bg-secondary d-flex justify-content-center nav-bar">
        <div className="d-flex justify-content-between align-items-center height-80px col-10" >
            <span className="text-main s-bold-main">HRM Module</span>
            <div className="d-flex justify-content-between gap-3">
                {/* <Link to='/employees' className={`${(location.pathname.includes('employee')) && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Employee</Link>
                <Link to='/salary/list' className={`${location.pathname.includes('salary') && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Salary</Link> */}
                {/* <Link to='/organisation' className={`${location.pathname.includes('organisation') && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Organisation</Link> */}
              
                
                {userType==="organization" && <> 
                    <Link to='/employees' className={`${(location.pathname.includes('employee')) && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Employee</Link>
                    <Link to='/salary/list' className={`${location.pathname.includes('salary') && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Salary</Link>
                    <div id="settingsMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`cursor-pointer dropdown-toggle decoration-none text-main py-2`}>Settings</div>
                </>
                }
                {userType==='admin' && <Link to='/organisation' className={`${location.pathname.includes('organisation') && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Organization</Link>}
                {/* <Link to='attendance' className={`${location.pathname.includes('attendance') && 'border-bottom-primary-bold'} cursor-pointer decoration-none text-main py-2`}>Attendance</Link> */}
                <ul className="dropdown-menu" aria-labelledby="settingsMenuButton">
                            <li onClick={() => { navigate('/skills/list') }}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    Skills
                                </a>
                            </li>
                            <li onClick={() => { navigate('/departments/list') }}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    Departments
                                </a>
                            </li>
                            <li onClick={() => { navigate('/employment-types/list') }}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    Employment types
                                </a>
                            </li>
                            <li onClick={() => { navigate('/external-services/list') }}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    External services
                                </a>
                            </li>
                            <li onClick={() => { navigate('/templates/list') }}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    Salary templates
                                </a>
                            </li>
                        </ul>

            </div>
            <div className="d-flex gap-3">
                <div className="col-4 position-relative">
                    <div className="dropdown btn-group">
                        <button
                            className="btn btn-secondary dropdown-toggle border-none d-flex gap-24 no-arrow"
                            type="button"

                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {/* <img src={notificationIcon} alt="notification" /> */}
                            <img className="col-12 cursor-pointer rounded-50 h-40 w-40" src={profileData?.personel_details?.image ? process.env.REACT_APP_BASE_URL + "/" + profileData?.personel_details?.image : profilepic} alt="profile" onError={replaceImage} />

                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {!token && <li onClick={() => { navigate('/login') }}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    Login
                                </a>
                            </li>}
                            {token && <li onClick={logoutHandler}>
                                <a className="dropdown-item s-regular-semi" href="#" >
                                    Logout
                                </a>

                            </li>}
                        </ul>
                    </div>



                </div>

            </div>
        </div>

    </div>
}

export default Header;