import axios from "axios";

export const createSkill = (values) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/skills`,
            data: {
                skill: values.name 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const updateSkill = (values, id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BASE_URL}/skills/${id}`,
            data: {
                skill: values.name 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const deleteSkill = (id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BASE_URL}/skills/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}
          