import axios from "axios"

export const fetchAllSalaryTemplate=()=>{

    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/template`,
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {

            reject(error?.response);
        })

    })
}

export const fetchSingleSalaryTemplate=(id)=>{

    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/template?salary_template_ids=${id}`,
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {

            reject(error?.response);
        })

    })
}

export const updateSalaryTemplate = (values, id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/template`,
            data: {
                salary_template_ids: [id],
                template_data: values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const createSalaryTemplate = (values) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/template`,
            data: {
                template_data: values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}