import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
const CustomField = ({id,onKeydown,ref, placeholder,rows,onChange, fieldClass, name,as, max,label,errors,touched,type,className}) => {
  

    return (
        <div className={`${className}  d-flex flex-column gap-6`}>
            <label className="text-secondary s-regular-small" htmlFor={id}>{label}</label>
            <Field
                name={id}
                type={type} 
                // onKeydown={onKeydown}
                ref={ref}
                placeholder={placeholder}
                as={as}
                rows={rows}
                className={`${touched?.[name] && errors?.[name] ? 'border-danger ' : 'input-border-primary '}  height-50px form-control shadow-none rounded-10  ${fieldClass && fieldClass}`}
                max={max}
            />
            <ErrorMessage className="s-regular-xsmall error-msg s-regular-small" name={id} component="div" />
        </div>
    )
}

export default CustomField;