import React, { useEffect, useState } from 'react';
import { fetchAllSalaryTemplate } from '../../utility/services/salaryTemplatesServices';
import { Link, useNavigate } from 'react-router-dom';
import rightArrow from '../../assets/icons/rightArrow.svg';
import add from '../../assets/icons/add.svg'
import ClipLoader from 'react-spinners/ClipLoader';
import edit from '../../assets/icons/editdark.svg';

const SalaryTemplateList = () => {

    const navigate = useNavigate();

    const [salaryTemplates, setSalaryTemplates] = useState();
    const [templateId, setTemplateId] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchAllSalaryTemplates = async () => {
        setLoading(true);
        try{
            const response = await fetchAllSalaryTemplate();
            setLoading(false);
            setSalaryTemplates(response && response[0]?.salary_template);
            setTemplateId(response && response[0]?._id)
        }
        catch(error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAllSalaryTemplates();
    }, [])

    return(
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-10">
                    <Link className="s-regular decoration-none text-primary cursor-pointer">Home</Link>
                    <img src={rightArrow} alt="" />
                    <span className="s-regular cursor-pointer text-primary">Salary templates</span>
                </div>
                {/* <div className="d-flex rounded bg-white">
                    <input placeholder="Search"
                        onChange={(e) => {
                            setSearchKey(e.target.value)
                        }}
                        className="p-10-16 rounded bg-pirmary outline-0 border-0" type="text" />
                    <img className="bg-white rounded p-10-16" src={search} alt="search" />
                </div> */}
                {/* <Search setData={setSearchKey}/> */}
            </div>
            <div className="d-flex col-10 justify-content-between align-items-center mb-24 mt-40">

                <div className="d-flex col-12  col-lg-6 gap-24 align-items-center">
                    {/* <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" /> */}
                    <span className="extra-bold">Salary template</span>
                </div>
                <div>
                    <button
                        onClick={() => {
                           if(salaryTemplates) navigate(`/templates/edit/${templateId}`)
                           else navigate(`/templates/create`);
                        }}
                        type="submit" className="btn-primary h-44 d-flex gap-10 border-0 rounded-10 align-items-center p-10-16">
                        <img src={salaryTemplates ? edit : add} alt="" />
                        <span className="d-none d-sm-block">{salaryTemplates ? 'Edit' : 'Add'} salary template</span>
                    </button>
                </div>

            </div>
            <div className="col-10 row bg-white rounded-20 p-24 d-flex justify-content-between d-flex">
                {loading ? 
                    <div className='col-12 d-flex justify-content-center height-50px align-items-center'>
                        <ClipLoader />
                    </div>
                :   <div className='col-12'>
                        <div className='d-flex col-12 border-bottom-success p-12-0'>
                            <div className="col-6 s-regular-small text-secondary ">
                                Name
                            </div>
                            <div className="col-6 s-regular-small text-secondary d-flex justify-content-end">
                                Percentage
                            </div>
                        </div>
                        {salaryTemplates && salaryTemplates?.map((e, index) => (
                                <div className='col-12 d-flex p-12-0 border-bottom-success' key={index}>
                                    <div className="col-6">
                                        {e?.label}
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        {e?.percentage}
                                    </div>
                                    
                                </div>
                                
                        ))}
                        {!salaryTemplates &&
                            <div className={"d-flex justify-content-center text-secondary mt-3"}>No datas available</div>
                        }

                    </div>
                }
                
            </div>
            
           


        </div>
    
    )

}

export default SalaryTemplateList;