import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import CryptoJS from "crypto-js";
const OrgRoute=({children})=>{
    const navigate=useNavigate();
    const token=localStorage.getItem('token');
    const hasJWT=()=>{
        let flag=false;
        token ? flag=true : flag=false;
        return flag;
    }
    const decryptData =  () => {
        const userTypeEnc =  localStorage.getItem('usertype');
        if(!userTypeEnc){
            return;
        }
        const bytes = CryptoJS.AES.decrypt(userTypeEnc, 'admin#123');
        const data = (bytes.toString(CryptoJS.enc.Utf8));
        return JSON.parse(data)=== "organization"
    
      };
    
    useEffect(()=>{
        if(!hasJWT()){
            navigate('/login');
        }
        else if(!decryptData()){
           
            navigate('/')
        }
    },[navigate])

    return children;
}

export default OrgRoute