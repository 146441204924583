import axios from "axios";
export const getSalaryList = (year_from, month_from, year_to, month_to,id) => {
    return new Promise((resolve, reject) => {

        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/salaries/histories`,
            // url: `${process.env.REACT_APP_BASE_URL}/salaries/`,
            params: {
                salary_year_from:year_from,
                salary_month_from:month_from,
                salary_year_to:year_to,
                salary_month_to:month_to,
                user_id:id
            },

        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

