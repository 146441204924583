import profilePic from '../assets/icons/profilePic.svg'
import eye from '../assets/icons/eye.svg'
import edit from '../assets/icons/edit.svg';
import filter from '../assets/icons/filter.svg';
import PaginationComponent from '../components/pagination';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ClipLoader from 'react-spinners/ClipLoader';

const List = ({
    arr, setCardData, allChecked, setAllChecked,searchKey,
    payRollArr,
    loading,
    setArr,
    setYear,
    setMonth,
    id,
    setOpenModal,
    end,
    setEnd,
    start,
    setStart,
    pageCount,
    paginationId,
    type,
    titles,
    cardData,
    emptyError,setEmptyError,
    btns,
    filters,
    currentPage,
    setCurrentPage,
    filterIndex,
    setFilterIndex,
    heading,
    routes,
    setPayRollArr,
    link
}) => {
    const [showAllCheckBox, setShowCheckBox] = useState(true);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const [selectedYear, setSelectedYear] = useState(new Date())

    const dateRef = useRef(null);

    const navigate = useNavigate();


    const onPageChange = useCallback((page) => {

        setCurrentPage(page)

    }, [])

    // console.log(cardData[0].cardTitle,"caaaaaaardData");

    const handleAllCheck = (e) => {
        let allArr = cardData.filter(el => {
            return (el.data2 !== 'Paid')
        });
        setEmptyError("");
        allArr = allArr.filter(el => (
            el.data2 !== ''
        ))

        if (e.target.checked) {
            setArr(prev => {
                return allArr.map(el => el.id);
            })
            setPayRollArr(prev => {
                return allArr.map(el => ({
                    salary: el.data1,
                    paid_amount: el.amountTobePaid,
                    date_of_join: el.date_of_join,
                    salary_month: el.month,
                    salary_year: el.year,
                    user_id: el.id,
                    addiiotn: el?.addition,
                    deduction: el?.deduction
                }))
            })
        } else {

            setArr([])
            setPayRollArr([])
        }
    }
    const handleCheck = (e, id, el) => {
        setEmptyError("");
        setArr(prev => {
            if (arr.indexOf(id) == -1) {
                return ([...arr, id])
            } else {
                (arr.splice(arr.indexOf(id), 1))
                return ([...arr])
            }
        })


        let ind = -1;

        for (let i = 0; i < payRollArr?.length; i++) {
            if (payRollArr[i]?.user_id === el.id) {
                ind = i;
                break;
            }

        }

        let payArr = [];
        if (ind === -1) {
            payArr = [...payRollArr,
            {
                salary: el.data1,
                paid_amount: el.amountTobePaid,
                date_of_join: el.date_of_join,
                salary_month: Number(el.month)+1,
                salary_year: el.year,
                user_id: el.id,
                addiiotn: el?.addition,
                deduction: el?.deduction
            }

            ]

        } else {
            payArr = payRollArr.filter((item, i) => {
                return item.user_id !== id
            })


        }

        setPayRollArr([...payArr])
    }
    const replaceImg = (e) => {
        e.target.src = profilePic;
    }
    const Card = cardData?.map(el => {
        let paidAmount = el.amountTobePaid || 0;
        let additionTotal = el?.addition?.reduce((sum, elem) => ((sum) + Number(elem.amount)), 0) || 0;
        let deductionTotal = el?.deduction?.reduce((sum, elem) => ((sum) + Number(elem.amount)), 0) || 0;
        return (
            <div className="col-12 d-flex border-bottom-secondary flex-column">


                <div className="d-flex  py-24">

                    {/* {(id === "employee" || id === 'salary') &&  */}
                    <div

                        className={`${link && "cursor-pointer"} d-flex col-sm-5 col-md-4 ${id === 'salary' ? "col-lg-4" : "col-lg-3"} gap-10  align-items-center`}> {/* */}
                        {type && <div className=' d-flex align-items-center justify-content-start mr-14'>
                            {(el.data2 !== "Paid" && el.data2) ? <input
                                checked={arr.indexOf(el?.id) > -1}
                                onChange={(e) => {

                                    handleCheck(e, el.id, el);
                                }}
                                className='border-primary checkbox rounded-6 cursor-pointer' type={type} /> :
                                <div className='w-6'>

                                </div>
                            }
                        </div>
                        }
                        <img onClick={(e) => {
                            if (!link || e.target.type === 'checkbox') return;
                            navigate(`/${link}/${el.id}`)
                        }} className='col-2 w-40 h-40 rounded-50' src={el?.img ? process.env.REACT_APP_BASE_URL + "/" + el?.img : profilePic}
                            onError={replaceImg}
                            alt="profile" />
                        <div onClick={(e) => {
                            if (!link || e.target.type === 'checkbox') return;
                            navigate(`/${link}/${el.id}`)
                        }} className='d-flex flex-column'>
                            <span className='s-regular text-primary'> {el.cardTitle}</span> 

                            <span className='s-regular-small text-secondary'>ID:{el.cardId} </span>
                        </div>
                    </div>
                     {/* } */}
                    {(id === "employee"||id === "organisation") && <> <div className='s-regular-semi d-none d-sm-flex text-primary align-items-center col-md-3'>{el?.data1}</div>
                        <div className='s-regular-semi d-none d-md-flex  text-primary col-md-3 col-lg-2  align-items-center'>{el?.data2}</div>
                        <div className='s-regular-semi d-none d-lg-flex text-primary col-lg-2  align-items-center'>{el?.data3}</div>
                    </>
                    }
                    {id === "salary" &&
                        <>
                            <div className='s-regular-semi d-none d-sm-flex text-primary align-items-center col-md-2'>{el?.data1}</div>
                            <div className={`s-regular-semi d-none d-md-flex  text-primary col-md-3 col-lg-2 align-items-center`}>

                                <span className={`${el?.data2 === "Paid" ? " p-4-12 rounded-16 paid" : el?.data2 === "Unpaid" ? " p-4-12 rounded-16 unpaid" : el?.data2 === "Partial" ? "partial  p-4-12 rounded-16" : ""}`}>{el?.data2}</span>

                            </div>

                            <div className='s-regular-semi d-none d-lg-flex text-primary col-lg-2  align-items-center'>{el?.data3}</div>

                        </>

                    }


                    <div className="d-flex col-5 col-sm-5 col-md-2  col-lg-2 justify-content-end gap-4">
                        {btns?.indexOf("View") !== -1 && <div
                            onClick={() => { navigate(`${routes?.view}/${el.id}`) }}
                            className="d-flex gap-2 align-items-center cursor-pointer">
                            <img src={eye} alt="eye" />
                            <span className='text-success s-regular  d-none d-lg-block'>View</span >
                        </div>}
                        {btns?.indexOf("Edit") !== -1 && !(id === 'salary' && (el?.data2 === 'Paid' || el.data2 === '')) &&

                            <div
                                onClick={() => {
                                    if (routes?.edit) { navigate(`${routes?.edit}/${el.id}`) }
                                    if (id === 'salary') { routes.editFun(el.id) }
                                }} className="d-flex gap-2 align-items-center cursor-pointer">

                                <img src={edit} alt="edit" />
                                <span className='text-danger s-regular  d-none d-lg-block '>Edit</span >

                            </div>

                        }
                    </div>


                </div>
                {id === 'salary' &&
                    <>
                        <div className="ml-24 col-12 d-flex ">
                            <div className='col-3'>
                                <span>Total :</span>
                                <span>{paidAmount}</span>
                            </div>


                            <div className='col-3'>
                                <span>Addition :</span>
                                <span>{additionTotal}</span>
                            </div>
                            <div className='col-3'>
                                <span>Deduction :</span>
                                <span>{deductionTotal}</span>
                            </div>
                        </div>


                    </>
                }
            </div>
        )
    })


    useEffect(() => {
        let str = String(selectedYear);
        if (id === 'salary') {
            let year = str.split(' ')[3]
            setYear(year)
            let month = str.split(' ')[1]
            for (var i = 0; i < months.length; i++) {
                if (month === months[i]) {
                    setMonth(i + 1);
                    break;
                }
            }
        }

    }, [selectedYear])


    return (
        <div className='col-12 bg-white rounded-20 p-24'>
            <div className='align-items-center d-flex justify-content-between border-bottom-secondary pb-24'>
                {
                    heading && <span className='extra-bold'>{heading}</span>
                }
                {filters && <div className='col-12 d-flex flex-wrap justify-content-between align-items-center'>
                    {
                        filters.map((el, i) => (
                            <span className={`${i === filterIndex && 'bg-primary '}  cursor-pointer rounded rounded-10 p-10-16`} onClick={() => {
                                setFilterIndex(i);
                            }}>{el.department}</span>
                        ))
                    }
                </div>}
                {id === 'salary' && <div

                    onClick={() => { dateRef?.current?.setOpen(true) }}
                    className='border-black rounded-10 w-93 h-44 gap-10 p-10 align-items-center d-flex justify-content-between cursor-pointer'>
                    <img src={filter} alt="filter" />
                    <div>
                        <DatePicker
                            selected={selectedYear}
                            customInput={<input style={{ display: 'none' }} />}
                            ref={dateRef}
                            onChange={(date) => {

                                setSelectedYear(date)
                                const month = (date).getMonth();
                                const year = date.getFullYear();
                                setMonth(month + 1)
                                setYear(year)


                            }}
                            popperPlacement="top-end"
                            shouldCloseOnSelect={true}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showYearDropdown
                            placeholderText="YYYY"
                        />
                    </div>
                    <span className='s-regular text-primary d-flex gap-6'> <span>{months[selectedYear.getMonth()]}</span> <span>{selectedYear.getFullYear()}</span></span>
                </div>}

            </div>
            {loading ?
                <div className='col-12 d-flex justify-content-center height-50px align-items-center'>
                    <ClipLoader />
                </div> :

                <>
                    <div className="d-flex mt-2">
                        {titles?.map((el, i) => (
                            <>
                                {id === 'employee' &&
                                    <div
                                        className={
                                            `${(i === 0 ? "col-sm-5 col-md-4 col-lg-3" : i === 1 ? "d-none d-sm-block col-sm-3" : i === 2 ? "d-none d-md-block col-md-3 col-lg-2" : 'd-none d-md-block col-lg-2')
                                            }  s-regular-small text-secondary`
                                        }
                                    >
                                        {el}
                                    </div>
                                }

                                {id === 'organisation' &&
                                    <div
                                        className={
                                            `${(i === 0 ? "col-sm-5 col-md-4 col-lg-3" : i === 1 ? "d-none d-sm-block col-sm-3" : i === 2 ? "d-none d-md-block col-md-3 col-lg-2" : 'd-none d-md-block col-lg-2')
                                            }  s-regular-small text-secondary`
                                        }
                                    >
                                        {el}
                                    </div>
                                }

                                {
                                    id === "salary"
                                    &&
                                    <div className={
                                        `${i === 0 ? "col-sm-5 col-md-4 col-lg-4 d-flex gap-24" : i === 1 ? "d-none d-sm-block col-sm-2" : i === 2 ? "d-none d-md-block col-md-3 col-lg-2" : "d-none d-lg-block col-lg-2"
                                        }`
                                    }>
                                        {(id === 'salary' && i === 0 ) &&
                                            <div className=' d-flex align-items-center justify-content-start'>
                                                <input
                                                    
                                                    onChange={(e) => {
                                                      
                                                        handleAllCheck(e)
                                                    }}
                                                    className='border-primary checkbox rounded-6 cursor-pointer' type={type} />
                                            </div>}

                                        <span className='s-regular-small text-secondary'>{el}</span>
                                    </div>
                                }

                            </>


                        ))
                        }
                    </div>
                    <div className="d-flex flex-column">
                        {cardData?.length > 0 ? Card : <div className='d-flex justify-content-center m-24 text-secondary'>No data available</div> }
                        {/* {Card} */}
                    </div>

                    {(currentPage && !searchKey) && <div>
                        <PaginationComponent
                            id={paginationId}
                            pageCount={pageCount}
                            start={start}
                            end={end}
                            setStart={setStart}
                            setEnd={setEnd}
                            currentPage={currentPage}
                            onPageChange={onPageChange}


                        />
                    </div>}
                </>
            }


        </div>
    )
}

export default List;