import rightArrow from '../../assets/icons/rightArrow.svg';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import search from '../../assets/icons/search.svg';
import left from '../../assets/icons/left.svg'
import add from '../../assets/icons/add.svg'
import { useCallback, useContext, useEffect, useState } from "react";
import List from "../../containers/list";
import closeCircle from '../../assets/icons/closecircle.svg'
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader"
import save from '../../assets/icons/save.svg'
import close from '../../assets/icons/close.svg'
import edit from '../../assets/icons/editdark.svg'
import payroll from '../../assets/icons/payroll.svg'
import calendar from '../../assets/icons/calendar.svg'
import profilepic from '../../assets/icons/profilePic.svg'
import currency from '../../assets/icons/currency.svg'
import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select"
import gallery from '../../assets/icons/gallery.svg'
import Skeleton from 'react-loading-skeleton';
import { CategoryScale, Chart, LineElement, LinearScale, PointElement } from "chart.js";
import * as Yup from 'yup';
import { fetchSalaryTemplate } from '../../utility/services/fetchSalaryTemplate';
import { fetchSingleEmployeeData } from '../../utility/services/fetchSingleData';
import { getSalaryList } from '../../utility/services/fetchSalary';


const SalaryDetail = () => {
    const navigate = useNavigate();
    // const [salaryData, setSalaryData] = useState();
    const btns = ["Edit"]
    const heading = "Earnings and deductions"
    const titles = ["Month", "Salary", "Deductions", "Addition", "Total"]
    const [employeeInfoClicked, setEmployeeInfoClicked] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [fetchedImg, setFetchedImg] = useState("");
    const [selectedYear, setSelectedYear] = useState(new Date());
    const dateRef = useRef(null);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [tableLoader, setTableLoader] = useState();
    const [initialEmployeeData, setInitialEmployeeData] = useState([])
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const [slaryTypes, setSalaryTypes] = useState([])
    const handleProfileImage = async (e, setFieldValue) => {
        let item;
        setFetchedImg("");
        if (e?.target?.files[0]?.type?.split("/")[0] === "image") {
            item = e.target.files[0];
            const base64 = await encodeFileBase64(item);
            setFieldValue("image", base64);
        }
    }

    const encodeFileBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const validationSchema = Yup.object().shape({

        id: Yup.number().typeError("ID should be a number").required("ID is required"),
        job_title: Yup.string().required("Job title is required"),
        name: Yup.string().required("Name is required"),
        total_salary: Yup.number().typeError("Total salary should be a number").required("Total salary is required"),
        medical_allowance: Yup.number().typeError("Medical allowance should be a number").required("Medical allowance is required"),
        home_rent: Yup.number().typeError("Home rent should be a number").required("home rent is required"),
        conveyance: Yup.number().typeError("Conveyance should be a number").required("Conveyance is required")
    })
    let profileImageRef;

    const handleSubmit = ({ values }) => {

    }
    const [salaryDetailsData, setSalaryDetailsData] = useState([]);
    const [closeDatePicker, setClosePicker] = useState(false)
    const getUserData = async () => {
        setLoading(true)
        try {
            const response = await fetchSingleEmployeeData(id);
            
            setInitialEmployeeData({
                image: response.personel_details.image,
                id: response.official_details.employee_id,
                job_title: response.official_details.job_title,
                name: response.personel_details.first_name + " " + response.personel_details.last_name,
                total_salary: response.salary

            })
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }

    const fetchSalary = async () => {
        // setLoading(true)
        setTableLoader(true);
        try {
            const resp = await getSalaryList(String(selectedYear).split(' ')[3], null, String(selectedYear).split(' ')[3], null, id);
            setTableLoader(false);

            const responseArr = resp.datas
            const obj = responseArr?.map((e, i) => {
                const additionAmount = (e.addition.reduce((sum, cur) => sum + Number(cur?.amount), 0))
                const deductionAmount = (e.deduction.reduce((sum, cur) => sum + Number(cur?.amount), 0))
                const paidAmount = e.payment.reduce((sum, current) => (Number(sum) + Number(current.paid_amount)), 0);
                return (
                    {
                        month: months[e.salary_month - 1],
                        salary: e.salary,
                        paid: paidAmount,
                        addition: additionAmount,
                        additionArr: e.addition,
                        deductionArr: e.deduction,
                        deduction: (deductionAmount),
                        status: (paidAmount >= (e.salary + additionAmount - deductionAmount) ? "Paid" : paidAmount < (e.salary + additionAmount - deductionAmount) ? "Partial" : "Unpaid")
                    }
                )

            })

            setSalaryDetailsData([...obj]);
            setTableLoader(false);
        } catch (error) {
            setLoading(false);
            setTableLoader(false);
        }

    }
    const fetchSalaryData = async () => {
        try {
        const templateResponse = await fetchSalaryTemplate();
        setSalaryTypes(templateResponse[0].salary_template);
           
        } catch (error) {
        }
    }

    // console.log(slaryTypes,"slsaryTemplate");
   



    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        getUserData();
        fetchSalaryData();

    }, [])
    // console.log(selectedYear.split(' ')[2],"selectedYear");
    const submittingData = salaryDetailsData.map(e => ({
        salary: e.salary,
        // salary_year:String(selectedYear).split(' ')[2],
        // salary_month:e.month,
        // addition:e.addition,
        // deduction:e.deduction,
        // user_id:id,
        // joining_date:"",      
    }))
    const handlePayment = async () => {

        try {
            const response = await payroll();
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchSalary();
    }, [selectedYear])

    const LoadingDataSkeleton = ({ count, className }) => {
        let loading = [];
        for (let i = 0; i < count; i++) {
            loading.push(
                <div className={className}>
                    <Skeleton
                        count={1} style={{ height: "12px", width: "42px" }}
                    />
                    <Skeleton
                        count={1} style={{ height: "20px", width: "50%" }}
                    />

                </div>
            )
        }
        return loading;
    }

    const replaceImg = (e) => {
        e.target.src = profilepic;
    }


    return (
        <div className=" d-flex flex-column align-items-center">
            <div className="col-10  m-24  d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center gap-24">
                    {/* <Link to='/Home' className="s-regular decoration-none text-primary cursor-pointer">Home</Link> */}
                    {/* <img src={rightArrow} alt="" /> */}
                    <span
                        onClick={() => { navigate('/salary/list') }}
                        className="s-regular cursor-pointer text-primary">Salary</span>
                    <img src={rightArrow} alt="" />
                    <span className="s-regular cursor-pointer text-primary">{initialEmployeeData?.name}</span>
                </div>


            </div>
            <div className="d-flex col-10 mt-40  justify-content-between align-items-center">
                <div className="col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div className=" d-flex gap-24 col-12 col-sm-6 aign-items-center">
                        <img onClick={() => { navigate(-1) }} className="cursor-pointer" src={left} alt="left-arrow" />
                        <span className="extra-bold">Employee salary information</span>

                    </div>
                    {/* <div className=" d-flex gap-24 col-12 col-sm -6 align-items-center justify-content-end">

                        {loading ? <div className="col-2 d-flex justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 gap-10 p-10-16">

                        </div> :
                            <button onClick={handlePayment} type="submit" className="d-flex justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 gap-10 p-10-16 ">

                                <img src={payroll} alt="" />
                                <span>Payment</span>

                            </button>

                        }

                    </div> */}
                </div>

            </div>
            <div className="col-10 rounded-20 bg-white d-flex flex-column m-24 p-24 gap-24">

                <div className="d-flex flex-column rounded-10 p-24 border-secondary">

                    <div className='d-flex justify-content-between cursor-pointer'>
                        <span className='semi-bold'>Employee information</span>

                    </div>

                    <div className="d-flex  flex-wrap align-items-center">
                        {loading ?
                            <div className='d-flex m-24 col-12 gap-24 col-lg-4 align-items-center gap-2'>
                                <Skeleton count="1" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
                                <Skeleton count="1" style={{ height: "25px", width: "50px" }} />

                            </div>
                            : <div className='m-24 col-12 gap-24 col-lg-4 d-flex align-items-center'>
                                <img onError={replaceImg} className='w-100px h-100px rounded-50' src={initialEmployeeData?.image ? process.env.REACT_APP_BASE_URL + "/" + initialEmployeeData?.image : profilepic} alt="profiles" />
                                <div className='d-flex flex-column p-10'>
                                    <span className='s-regular-semi-bold'>{initialEmployeeData?.name}</span>
                                    <span className='text-secondary s-regular-medium'>ID: {initialEmployeeData?.id}</span>
                                </div>
                            </div>}

                        {loading ? <LoadingDataSkeleton count={2} className="col-lg-3 m-24  col-6 d-flex flex-column" /> :
                            <>
                                <div className="col-lg-3 m-24  col-6 d-flex flex-column">


                                    <span className='s-regular-small text-secondary'>
                                        Job title
                                    </span>
                                    <span>
                                        {initialEmployeeData?.job_title}
                                    </span>
                                </div>
                                <div className={`col-lg-3 m-24  col-6 d-flex flex-column`}>
                                    <span className='s-regular-small text-secondary'>
                                        Total salary
                                    </span>
                                    <span>
                                        {initialEmployeeData?.total_salary}
                                    </span>
                                </div>

                            </>

                        }


                    </div>

                    <div className='d-flex flex-wrap'>

                        {slaryTypes?.map(e => (
                            <>


                                <div className="col-lg-3 m-24  col-6 d-flex flex-column">
                                    <span className='s-regular-small text-secondary'>
                                        {e.label}
                                    </span>
                                    <span className='d-flex'>
                                        <img src={currency} className=" h-24" alt="" />

                                        {initialEmployeeData?.total_salary ? (Number(initialEmployeeData?.total_salary) * (Number(e.percentage) / 100)).toLocaleString('en-IN') : 0}

                                    </span>
                                </div>



                            </>

                        ))
                        }

                    </div>


                </div>



                <div className="d-flex col-12 flex-column border-secondary p-24 gap-24 rounded-10">
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <span className='extra-bold'>Earnings and deductions</span>
                        </div>
                        {loading ? <div className='col-1 btn-secondary rounded-10 d-flex text-white gap-10 p-10-16'>

                        </div> :
                            <button
                                onClick={() => {
                                    dateRef?.current?.setOpen(true);
                                }}
                                className='btn-secondary rounded-10 d-flex text-white gap-10 p-10-16 border-none justify-content-between'>
                                <div>
                                    <DatePicker
                                        selected={selectedYear}
                                        customInput={<input style={{ display: 'none' }} />}
                                        ref={dateRef}
                                        onChange={(date) => {
                                            setSelectedYear(date);
                                        }}
                                        popperPlacement="top-end"
                                        dateFormat="yyyy"
                                        showYearPicker
                                        showYearDropdown
                                        placeholderText="YYYY"
                                    />
                                </div>
                                <img src={calendar} alt="calendar" />
                                <span className='text-white'>{selectedYear?.getFullYear()}</span>
                            </button>}
                    </div>
                    <div className="col-12">
                        <div className="d-flex col-12 border-bottom-success p-12-0">
                            {/* <LoadingDataSkeleton
                                count="7"
                                className="col-3 col-md-1"
                            /> */}
                        </div>
                    </div>
                    {(tableLoader || loading) ?

                        <div className='col-12 d-flex justify-content-center'>
                            <div className='col-10'>
                            <Skeleton count={1} style={{ height: "32px", marginBottom: "1px", borderRadius: "10px" }} />
                        
                            </div>
                           </div>
                        : <div className='col-12'>
                            <div className='d-flex col-12 border-bottom-success p-12-0'>
                                <div className="col-3 col-md-1 s-regular-small text-secondary ">
                                    Month
                                </div>
                                <div className="col-3 col-md-2 s-regular-small text-secondary">
                                    Salary
                                </div>
                                <div className="col-3 col-md-2 s-regular-small text-secondary">
                                    Paid salary
                                </div>
                                <div className="col-3 col-md-2 s-regular-small text-secondary">
                                    Deduction
                                </div>
                                <div className="col-3 col-md-2 s-regular-small text-secondary">
                                    Addition
                                </div>
                                <div className="col-2 s-regular-small text-secondary d-none d-md-block ">
                                    Total
                                </div>
                                <div className="col-1 s-regular-small text-secondary d-none d-md-block">
                                    Status
                                </div>
                            </div>

                            {

                                salaryDetailsData.length > 0 ?
                                    salaryDetailsData?.map((e) => (
                                        <div className='col-12 d-flex p-12-0 border-bottom-success'>
                                            <div className="col-3 col-md-1">
                                                {e.month}
                                            </div>
                                            <div className="col-3 col-md-2">
                                                {e.salary}
                                            </div>
                                            <div className="col-3 col-md-2">
                                                {e.paid}
                                            </div>
                                            <div className="col-3 col-md-2">
                                                {e.deduction && (e.deduction)}
                                            </div>
                                            <div className="col-3 col-md-2">
                                                {e.addition && e.addition}
                                            </div>
                                            <div className="col-2 d-none d-md-block">
                                                {Number(e.addition) - Number(e.deduction) + Number(e.salary)}
                                            </div>
                                            <div className={`${e.status === 'Paid' ? 'paid p-4-12 rounded-16' : e.status === 'Unpaid' ? 'unpaid  p-4-12 rounded-16' : e.status === 'Partial' ? 'partial   p-4-12 rounded-16' : ''} d-none d-md-block `}>
                                                {e.status}
                                            </div>
                                        </div>
                                    )

                                    ) : <div className='text-secondary d-flex justify-content-center m-24'>
                                        Data not available
                                    </div>
                            }

                        </div>}



                </div>


            </div>





        </div>

    )
}

export default SalaryDetail