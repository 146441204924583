import { createContext, useState } from "react";
export const PaginationContext = createContext();



const PaginationContextProvider = ({ children }) => {
    const [employeeStart, setEmployeeStart] = useState(1);
    const [employeeEnd, setEmployeeEnd] = useState(1);
    const [employeeCurrentPage, setEmployeeCurrentPage] = useState(1);
    const [salaryStart, setSalaryStart] = useState(1);
    const [salaryEnd, setSalaryEnd] = useState(1);
    const [skillStart, setSkillStart] = useState(1);
    const [skillEnd, setSkillEnd] = useState(1);
    const [departmentStart, setDepartmentStart] = useState(1);
    const [departmentEnd, setDepartmentEnd] = useState(1);
    const [employeeTypeStart, setEmployeeTypeStart] = useState(1);
    const [employeeTypeEnd, setEmployeeTypeEnd] = useState(1);
    const [salaryCurrentPage, setSalaryCurrentPage] = useState(1);
    const [skillCurrentPage, setSkillCurrentPage] = useState(1);
    const [departmentCurrentPage, setDepartmentCurrentPage] = useState(1);
    const [employeeTypeCurrentPage, setEmployeeTypeCurrentPage] = useState(1);

    const [organizationCurrPage,setOrganisationCurrPage]=useState(1);
    const [organizationStart,setOrganisationStart]=useState(1);
    const [organizationEnd,setOrganisationEnd]=useState(1);

    return (
        <PaginationContext.Provider value={
            {
                salaryStart, setSalaryStart,
                salaryEnd, setSalaryEnd,
                salaryCurrentPage, setSalaryCurrentPage,
                employeeStart,
                setEmployeeStart,
                employeeEnd,
                setEmployeeEnd,
                employeeCurrentPage,
                setEmployeeCurrentPage,
                skillStart,
                setSkillStart,
                skillEnd,
                setSkillEnd,
                skillCurrentPage,
                setSkillCurrentPage,
                departmentStart,
                setDepartmentStart,
                departmentEnd,
                setDepartmentEnd,
                departmentCurrentPage,
                setDepartmentCurrentPage,
                employeeTypeStart,
                setEmployeeTypeStart,
                employeeTypeEnd,
                setEmployeeTypeEnd,
                employeeTypeCurrentPage,
                setEmployeeTypeCurrentPage,
                organizationStart,
                setOrganisationStart,
                organizationEnd,
                setOrganisationEnd,
                organizationCurrPage,
                setOrganisationCurrPage

            }
        }>
            {children}
        </PaginationContext.Provider>
    )

}
export default PaginationContextProvider