import React, { useState } from 'react';
import ModalPopup from '../../components/modalpopup';
import close from '../../assets/icons/secondaryClose.svg';
import { Formik, Form } from 'formik';
import { createSkill, updateSkill } from '../../utility/services/createSkill';
import { useSnackbar } from "notistack";
import CustomField from '../../components/customField';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import save from '../../assets/icons/save.svg'
import { useEffect } from 'react';
import { fetchSingleSkills } from '../../utility/services/fetchSkills';
import { createDepartment, fetchSingledepartment, updateDepartment } from '../../utility/services/departmentServices';

const CreateDepartment = ({ setOpenModal, show, closeHandler, fetchDepartments, deptId, setDepartmentId }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [skillData, setSkillData] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [intialValues, setInitialValues] = useState({
            name: ""
        });
        
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required")
    });
    const [formKey, setFormKey] = useState(0);

    const submitHandler = async (values, { setSubmitting, setTouched, setErrors }) => {


        setSubmitting(true);
        if(deptId){
            try{
                const response = await updateDepartment(values, deptId);
                setSubmitting(false)
                enqueueSnackbar("Department updated successfully", { variant: 'success' })
                setOpenModal(false);
                setDepartmentId("");
                fetchDepartments();
            }
            catch (error) {
                setSubmitting(false)
                enqueueSnackbar(error?.data?.message, { variant: 'error' })
            }
        }
        else{
            try {
                const response = await createDepartment(values);
                setSubmitting(false)
                enqueueSnackbar("Department created successfully", { variant: 'success' })
                setOpenModal(false);
                fetchDepartments();
                
            } catch (error) {
                setSubmitting(false)
                enqueueSnackbar(error?.data?.message, { variant: 'error' })
            }
        }
    }

    
    const fetchSingleDepartment = async () => {
        setLoadingData(true);
        try {
            const response = await fetchSingledepartment(deptId);
            setInitialValues({ name : response?.department});
            setLoadingData(false);
            
        } catch (error) {
            setLoadingData(false);
            
        }
        
    }
    useEffect(() => {
       
       
        deptId && fetchSingleDepartment();
    }, [deptId]);
    
    if (!show) {
        return
    }
    return(
        <div>
            
            <ModalPopup 
            
            
             body1={<>
                {<span className='extra-bold'>{deptId? 'Edit' : 'Add'} department {loadingData && <ClipLoader size={20} />}</span>}
                <div onClick={closeHandler} className='d-flex align-items-center'>
                    <img className=' cursor-pointer' src={close} alt="" />
                    {/* <span>Close</span> */}
                </div>


            </>}
            show={show}
            size='small'
            onHide={closeHandler}
            centered={true}
            className="bg-main rounded-20 col-12 p-40 d-flex flex-column gap-24"
            bodyClassName1="className='col-12 d-flex justify-content-between"
            body2={
                <>
                <Formik
                    enableReinitialize={true}
                    key={formKey}
                    initialValues={intialValues}
                    validationSchema={validationSchema}

                    onSubmit={submitHandler}
                >
            {({ isSubmitting, validateForm, handleChange, touched, values, setTouched, setFieldValue, errors, setErrors }) => (
                
                <Form>
                <CustomField
                    id="name"
                    name="name"
                    placeholder="Add name"
                    className="col-12"
                    label="Department"
                    touched={touched}
                    errors={errors}
                />
                <div className="d-flex justify-content-end">
                    <button type="submit" onClick={
                        () => {setTouched({}); }}
                        className="d-flex align-items-center justify-content-center gap-10 h-44 border-0 btn-primary rounded-10 w-120 gap-10 p-10-16 mt-3">
                        {isSubmitting ? 
                            <ClipLoader /> : 
                            <> <img src={save} alt="" />
                                <span>Save</span>
                            </>
                        }
                    </button>
                </div></Form>
                )}
                </Formik>
                </>
            }
/>
        </div>
    )
}

export default CreateDepartment;