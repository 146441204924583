
import { useContext } from "react";
import { GlobalContext } from "../../context/globalContext";
const Welcome=()=>{
    const {userName}=useContext(GlobalContext);
    return (
        <div className=" extra-bold  h-70 d-flex justify-content-center align-items-center">
            Welcome to People happiness,  {userName}
        </div>
    )
}
export default Welcome;