import axios from "axios"


export const fetchAllDepartments=(values, page, size)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/departments`,
            params:{
                page:page,
                pageSize:size,
                keyword:values
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const fetchSingledepartment=(id)=>{
    return new Promise((resolve, reject) => {
        const token=localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/departments/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error?.response);
        })

    })
}

export const createDepartment = (values) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/departments`,
            data: {
                department: values.name 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const updateDepartment = (values, id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_BASE_URL}/departments/${id}`,
            data: {
                department: values.name 
            }
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}

export const deleteDepartment = (id) => {
    return new Promise((resolve, reject) => {
     
        axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BASE_URL}/departments/${id}`
        }).then((response) => {
            resolve(response.data.data);
        }).catch((error) => {
            reject(error.response)
        })

    })
}